// src/hooks/useDashboard.ts

import { useState, useEffect } from 'react';
import { getUserDashboard } from '../api/api';
import { ArtistAnalytics, ClientCoral } from 'shared/types/platformTypes';
import { useOurImpact } from './useOurImpact';
import { useCuratedCorals } from './useCuratedCorals';

export const useDashboard = () => {
  const [activeCorals, setActiveCorals] = useState<ClientCoral[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [firstName, setFirstName] = useState<string | null>(null);
  const [connectedArtists, setConnectedArtists] = useState<ArtistAnalytics[]>([]);
  const { impactData, isLoading: isImpactDataLoading } = useOurImpact(true);
  const { corals } = useCuratedCorals('recent');

  useEffect(() => {
    const fetchUserDashboard = async () => {
      try {
        const dashboard = await getUserDashboard();
        setActiveCorals(dashboard.activeCorals);
        setFirstName(dashboard.firstName);
        setConnectedArtists(dashboard.connectedArtists);
        setIsLoading(false);
      } catch (err) {
        console.error('Error fetching user dashboard:', err);
        setIsLoading(false);
      }
    };

    fetchUserDashboard();
  }, []);

  return {
    activeCorals,
    impactData,
    isLoading: isLoading || isImpactDataLoading,
    firstName,
    curatedCorals: corals,
    connectedArtists
  };
};