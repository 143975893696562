import React, { useState, useEffect } from 'react';
import { UserIdentityVerification } from 'shared/types/platformTypes';
import { getUserIdentityVerifications, processIdentityVerification } from '../../../../api/adminApi';

const UserIdentityVerificationAdmin: React.FC = () => {
  const [verifications, setVerifications] = useState<UserIdentityVerification[]>([]);
  const [expandedVerificationId, setExpandedVerificationId] = useState<string | null>(null);
  const [notification, setNotification] = useState<{ type: 'success' | 'error', message: string } | null>(null);

  useEffect(() => {
    const fetchVerifications = async () => {
      try {
        const fetchedVerifications = await getUserIdentityVerifications();
        setVerifications(fetchedVerifications);
      } catch (error) {
        console.error('Failed to fetch verifications', error);
        setNotification({ type: 'error', message: 'Failed to fetch verifications. Please try again.' });
      }
    };

    fetchVerifications();
  }, []);

  const handleProcess = async (verificationId: string, status: 'approved' | 'rejected') => {
    try {
      await processIdentityVerification(verificationId, status);
      setVerifications(verifications.filter(v => v.verificationId !== verificationId));
      setNotification({ type: 'success', message: `Verification ${status} successfully.` });
    } catch (error) {
      console.error('Failed to process verification', error);
      setNotification({ type: 'error', message: 'Failed to process verification. Please try again.' });
    }
  };

  const toggleExpand = (verificationId: string) => {
    setExpandedVerificationId(expandedVerificationId === verificationId ? null : verificationId);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-6">
        <a 
          href="/admin/home" 
          className="text-indigo-600 hover:text-indigo-800 font-medium"
        >
          ← Back to Admin Home
        </a>
      </div>
      <h1 className="text-3xl font-bold mb-6">User Identity Verification Admin</h1>
      {notification && (
        <div className={`mb-4 p-4 rounded ${notification.type === 'success' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
          <p className="font-bold">{notification.type === 'success' ? 'Success' : 'Error'}</p>
          <p>{notification.message}</p>
        </div>
      )}
      <div className="space-y-4">
        {verifications.map((verification) => (
          <div key={verification.verificationId} className="bg-white shadow rounded-lg overflow-hidden">
            <div 
              className="flex justify-between items-center p-4 cursor-pointer hover:bg-gray-50" 
              onClick={() => toggleExpand(verification.verificationId)}
            >
              <div>
                <h2 className="text-xl font-semibold">Legal Name: {verification.legalName}</h2>
                <p className="text-sm text-gray-500">User ID: {verification.userId}</p>
              </div>
              <div className="flex items-center space-x-4">
                <span className={`px-2 py-1 rounded text-sm ${
                  verification.verificationStatus === 'pending' ? 'bg-yellow-200 text-yellow-800' :
                  verification.verificationStatus === 'approved' ? 'bg-green-200 text-green-800' :
                  'bg-red-200 text-red-800'
                }`}>
                  {verification.verificationStatus}
                </span>
                <svg 
                  className={`w-5 h-5 transition-transform ${expandedVerificationId === verification.verificationId ? 'transform rotate-180' : ''}`} 
                  fill="none" 
                  stroke="currentColor" 
                  viewBox="0 0 24 24" 
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </div>
            </div>
            {expandedVerificationId === verification.verificationId && (
              <div className="p-4 border-t">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <h3 className="font-semibold">Legal Name</h3>
                    <p>{verification.legalName}</p>
                  </div>
                  <div>
                    <h3 className="font-semibold">Government ID Number</h3>
                    <p>{verification.governmentIdNumber}</p>
                  </div>
                  <div>
                    <h3 className="font-semibold">Government ID Type</h3>
                    <p>{verification.governmentIdType}</p>
                  </div>
                  <div>
                    <h3 className="font-semibold">Date of Birth</h3>
                    <p>{verification.dateOfBirth}</p>
                  </div>
                  <div>
                    <h3 className="font-semibold">Created At</h3>
                    <p>{new Date(verification.createdAt).toLocaleString()}</p>
                  </div>
                  <div>
                    <h3 className="font-semibold">Last Updated At</h3>
                    <p>{new Date(verification.lastUpdatedAt).toLocaleString()}</p>
                  </div>
                </div>
                <div className="mt-4 flex justify-end space-x-2">
                  <button
                    onClick={() => handleProcess(verification.verificationId, 'approved')}
                    className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded flex items-center"
                  >
                    <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    Approve
                  </button>
                  <button
                    onClick={() => handleProcess(verification.verificationId, 'rejected')}
                    className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded flex items-center"
                  >
                    <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                    Reject
                  </button>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserIdentityVerificationAdmin;