import { useState, useEffect, useRef, useCallback, useContext } from 'react';
import { getArtistDetails, getArtistDetailsPublic } from '../api/api';
import { BlendedArtistListItem } from 'shared/types/platformTypes';
import { AuthContext } from '../components/Auth/AuthContext/AuthContext';
import { trackEvent, EventName } from 'shared/lib/eventTracking';

const MAX_RETRIES = 4;
const RETRY_DELAY = 5000; // 5 seconds

const useArtistFetchQueue = (
  onArtistsFetched: (artists: BlendedArtistListItem[]) => void,
  apiClient = { getArtistDetails, getArtistDetailsPublic }
) => {
    const [pendingArtistQueue, setPendingArtistQueue] = useState<Map<string, number>>(new Map());
    const isProcessingRef = useRef(false);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const { currentUser } = useContext(AuthContext);
    
    const fetchAndUpdateArtist = useCallback(async (artistName: string, retryCount: number) => {
        console.log(`Fetching details for ${artistName}, retry count: ${retryCount}`);
        try {
            const artistDetails = currentUser
                ? await apiClient.getArtistDetails(artistName) as BlendedArtistListItem[]
                : await apiClient.getArtistDetailsPublic(undefined, artistName, undefined, true) as BlendedArtistListItem[];
            
            if (artistDetails.length > 0) {
                console.log(`Successfully fetched details for ${artistName}`);
                onArtistsFetched(artistDetails);
                return true;
            } else {
                console.log(`No details found for ${artistName}`);
            }
        } catch (error) {
            console.error(`Error fetching details for ${artistName}:`, error);
        }
        
        return false;
    }, [apiClient, onArtistsFetched, currentUser]);

    const addArtistToQueue = useCallback(async (artistName: string) => {
        console.log(`Attempting to fetch artist immediately: ${artistName}`);
        const success = await fetchAndUpdateArtist(artistName, 0);
        
        if (!success) {
            console.log(`Adding artist to queue for retry: ${artistName}`);
            setPendingArtistQueue(prevQueue => {
                const newQueue = new Map(prevQueue);
                newQueue.set(artistName, 1); // Start with retry count 1
                return newQueue;
            });
        }
    }, [fetchAndUpdateArtist]);

    const processQueue = useCallback(async () => {
        if (isProcessingRef.current) {
            console.log("Queue is already being processed, skipping this cycle");
            return;
        }
        
        isProcessingRef.current = true;
        console.log("Starting to process queue");

        const currentQueue = new Map(pendingArtistQueue);
        let hasChanges = false;

        for (const [artistName, retryCount] of currentQueue) {
            console.log(`Processing ${artistName}, current retry count: ${retryCount}`);
            
            if (retryCount >= MAX_RETRIES) {
                console.log(`Failed to fetch artist details for ${artistName} after ${MAX_RETRIES} retries.`);
                onArtistsFetched([{
                    artistName,
                    artistListItemStatus: 'not found',
                    artistListItemActive: true,
                } as BlendedArtistListItem]);
                currentQueue.delete(artistName);
                hasChanges = true;

                // Track the ARTIST_NOT_FOUND event
                trackEvent(EventName.ARTIST_NOT_FOUND, {
                    userId: currentUser?.userId,
                    artistName,
                    retryCount: MAX_RETRIES,
                });

                continue;
            }

            const success = await fetchAndUpdateArtist(artistName, retryCount);
            if (success) {
                console.log(`Successfully fetched details for ${artistName}, removing from queue`);
                currentQueue.delete(artistName);
            } else {
                console.log(`Failed to fetch details for ${artistName}, incrementing retry count`);
                currentQueue.set(artistName, retryCount + 1);
            }
            hasChanges = true;
        }

        isProcessingRef.current = false;

        if (hasChanges) {
            console.log("Updating queue with changes");
            setPendingArtistQueue(currentQueue);
        } else {
            console.log("No changes made to the queue");
        }
        
        console.log("Finished processing queue");
    }, [pendingArtistQueue, fetchAndUpdateArtist, onArtistsFetched, currentUser]);

    useEffect(() => {
        const scheduleNextProcess = () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }

            const itemsToProcess = Array.from(pendingArtistQueue.values()).filter(retryCount => retryCount <= MAX_RETRIES).length;
            console.log(`Items left to process: ${itemsToProcess}`);
            
            if (itemsToProcess > 0 && !isProcessingRef.current) {
                console.log(`Scheduling next process in ${RETRY_DELAY}ms`);
                timeoutRef.current = setTimeout(() => {
                    processQueue().then(scheduleNextProcess);
                }, RETRY_DELAY);
            } else {
                console.log("No items to process or queue is currently being processed");
            }
        };

        scheduleNextProcess();

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [pendingArtistQueue, processQueue]);

    return {
        addArtistToQueue,
    };
};

export default useArtistFetchQueue;