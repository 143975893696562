import { Amplify } from 'aws-amplify';

Amplify.configure(
  {
    Auth: {
      Cognito: {
        userPoolId: process.env.REACT_APP_USER_POOL_ID, // Environment variable
        userPoolClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
        signUpVerificationMethod: 'code', // or 'link', depending on your setup
      }
    }
  }
);
