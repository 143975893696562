import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import GenericCoralModal from '../GenericCoralModal/GenericCoralModal';
import { trackEvent, EventName } from 'shared/lib/eventTracking';
import { useAuthContext } from '../../components/Auth/AuthContext/AuthContext';
import createCoralUseCuratedCoralsWhite from '../../assets/images/create-coral/create-coral-use-curated-corals-white.png';
import createCoralUseCuratedCorals from '../../assets/images/create-coral/create-coral-use-curated-corals.png';
import createCoralUseDirectWhite from '../../assets/images/create-coral/create-coral-use-direct-white.png';
import createCoralUseDirectCorals from '../../assets/images/create-coral/create-coral-use-direct.png';

interface CreateCoralModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CreateCoralModal: React.FC<CreateCoralModalProps> = ({ isOpen, onClose }) => {
  const [selectedBox, setSelectedBox] = useState<string | null>(null);
  const history = useHistory();
  const { userProfile } = useAuthContext();

  const handleBoxClick = (method: 'curated' | 'manual') => {
    setSelectedBox(method);
    trackEvent(EventName.CORAL_CREATION_METHOD, { userId: userProfile!.userId, method });
    setTimeout(() => {
      history.push(`/create-coral?method=${method}`);
    }, 250);
  };

  return (
    <GenericCoralModal isOpen={isOpen} onClose={onClose}>
      <div className="p-6">
        <h1 className="font-nunito text-2xl font-extrabold text-gray-900 leading-tight mb-4">
          Create Your Coral: Choose Your Path
        </h1>
        <p className="text-sm text-gray-600 mb-6">
          Start with curated collections or build from scratch. Both options allow your coral to evolve with your musical journey.
        </p>
        <div className="flex flex-col gap-4 mb-6">
          <button
            className={`flex flex-col items-center justify-center p-3 border border-gray-200 rounded-lg shadow w-full transition-all duration-300 ${
              selectedBox === 'curated' ? 'bg-curated-coral' : 'bg-white hover:bg-gray-50'
            }`}
            onClick={() => handleBoxClick('curated')}
          >
            <img
              src={selectedBox === 'curated' ? createCoralUseCuratedCoralsWhite : createCoralUseCuratedCorals}
              alt="Browse Curated Corals"
              className="w-16 h-16 object-contain mb-4"
            />
            <span className={`text-lg font-bold font-nunito leading-tight mb-2 ${selectedBox === 'curated' ? 'text-white' : 'text-gray-900'}`}>
              Browse Curated Corals
            </span>
            <span className={`text-sm ${selectedBox === 'curated' ? 'text-gray-100' : 'text-gray-600'}`}>
              Explore pre-made collections
            </span>
          </button>
          <button
            className={`flex flex-col items-center justify-center p-3 border border-gray-200 rounded-lg shadow w-full transition-all duration-300 ${
              selectedBox === 'manual' ? 'bg-coral-pink' : 'bg-white hover:bg-gray-50'
            }`}
            onClick={() => handleBoxClick('manual')}
          >
            <img
              src={selectedBox === 'manual' ? createCoralUseDirectWhite : createCoralUseDirectCorals}
              alt="Add Artists Directly"
              className="w-16 h-16 object-contain mb-4"
            />
            <span className={`text-lg font-bold font-nunito leading-tight mb-2 ${selectedBox === 'manual' ? 'text-white' : 'text-gray-900'}`}>
              Add Artists Directly
            </span>
            <span className={`text-sm ${selectedBox === 'manual' ? 'text-gray-100' : 'text-gray-600'}`}>
              Start building straight away
            </span>
          </button>
        </div>
      </div>
    </GenericCoralModal>
  );
};

export default CreateCoralModal;