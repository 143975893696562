import { CoralSubscriptionStatus } from "shared/types/platformTypes";

export function getCssClassForSubscriptionStatus(coralSubscriptionStatus: CoralSubscriptionStatus): string {
    switch (coralSubscriptionStatus) {
        case CoralSubscriptionStatus.ACTIVE:
            return 'bg-green-200 text-green-800';
        case CoralSubscriptionStatus.PENDING:
            return 'bg-yellow-200 text-yellow-800';
        case CoralSubscriptionStatus.PAUSED:
            return 'bg-blue-200 text-blue-800';
            case CoralSubscriptionStatus.NONE:
            return 'bg-gray-100 text-gray-600';
        default:
            return '';
    }
}

const CoralSubscriptionStatusDescriptions: { [key in CoralSubscriptionStatus]: string } = {
    [CoralSubscriptionStatus.NONE]: "Not Pledged",
    [CoralSubscriptionStatus.ACTIVE]: "Pledge Active",
    [CoralSubscriptionStatus.PENDING]: "Pledge Pending",
    [CoralSubscriptionStatus.PAUSED]: "Pledge Paused"
  };
  
  export const GetCoralSubscriptionStatusDescription = (status: CoralSubscriptionStatus): string => {
    return CoralSubscriptionStatusDescriptions[status];
  }
  