import React, { ComponentPropsWithoutRef, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Instagram } from 'lucide-react';
import coralIcon from '../../assets/images/coral-wordmark.png';
import smallCoralIcon from '../../assets/images/coral-short-wordmark.png';
import { AuthContext } from '../Auth/AuthContext/AuthContext';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface HeaderProps extends ComponentPropsWithoutRef<any> {
  navigationTextHandler: string;
}

const Header: React.FC<HeaderProps> = ({ navigationTextHandler, ...props }) => {
  const { setShowAuth, currentUser } = useContext(AuthContext);
  const history = useHistory();

  const handleClick = () => {
    if (currentUser) {
      // If user is authenticated, redirect to dashboard
      history.push('/dashboard');
    } else {
      setShowAuth({ show: true, signIn: false }); // Show authentication modal immediately
    }
  };

  return (
    <header className={`bg-white`} {...props}>
      <div className="px-4 py-4 max-w-screen-xl mx-auto flex justify-between items-center">
        <div className="flex items-center space-x-4 sm:space-x-8">
          <Link to="/" className="flex items-center space-x-2">
            <img src={coralIcon} alt="Coral" className="mr-1 h-11 hidden sm:block" />
            <div className="flex flex-col">
              <img src={smallCoralIcon} alt="Small Coral" className="block sm:hidden h-11" />
            </div>
          </Link>
        </div>
        <div className="flex items-center space-x-4 sm:space-x-8">
          <a 
            href="https://instagram.com/wearecoral" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="hover:text-coral-pink transition-colors duration-200"
          >
            <Instagram size={24} />
          </a>
          <Link to="/for-artists" className={`${navigationTextHandler} font-syne whitespace-nowrap`}>
            For Artists
          </Link>
          <button onClick={handleClick} className={`pr-2 sm:pr-0 font-syne md:inline ${navigationTextHandler}`}>
            Sign In
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;