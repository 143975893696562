// src/hooks/useOurImpact.ts

import { useState, useEffect } from 'react';
import { getwearecoralImpact } from '../api/api';

export const useOurImpact = (featured: boolean = false) => {
    const [impactData, setImpactData] = useState<{ artistGuid: string, artistName: string, totalAmount: number, currentMonthAmount: number, priorMonthAmount: number, currentMonthCoralCount: number, priorMonthCoralCount: number }[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchImpactData = async () => {
            try {
                const data = await getwearecoralImpact(featured);
                setImpactData(data);
                setIsLoading(false);
            } catch (err) {
                console.error('Error fetching wearecoral impact data:', err);
                setIsLoading(false);
            }
        };

        fetchImpactData();
    }, [featured]);

    return {
        impactData,
        isLoading,
    };
};