import { ClientArtistListItem } from 'shared/types/platformTypes';

export type ArtistStatus = 'active' | 'deceased' | 'top-artist' | 'retired' | 'pending' | 'disambiguation';

export const addArtist = (artistList: ClientArtistListItem[], newArtist: ClientArtistListItem): ClientArtistListItem[] => {
  // Check if the artist already exists in the list
  const existingIndex = artistList.findIndex(artist => artist.artistId === newArtist.artistId);

  if (existingIndex !== -1) {
      // If the artist exists, add the new one next to it
      return [
          ...artistList.slice(0, existingIndex + 1),
          newArtist,
          ...artistList.slice(existingIndex + 1)
      ];
  }
  return [newArtist, ...artistList];
};

export const updateArtist = (artistList: ClientArtistListItem[], updatedArtist: ClientArtistListItem): ClientArtistListItem[] => {
  const index = artistList.findIndex(artist => artist.artistId === updatedArtist.artistId);
  if (index !== -1) {
    const newArtistList = [...artistList];
    newArtistList[index] = updatedArtist;
    return newArtistList;
  }
  return artistList;
};

export const removeArtist = (artistList: ClientArtistListItem[], artistId: string): ClientArtistListItem[] => {
  return artistList.filter(artist => artist.artistId !== artistId);
};
