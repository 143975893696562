import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { requestMagicLink } from '../../api/api';

const RequestLogin: React.FC = () => {
    const location = useLocation();
    const [message, setMessage] = useState<string>('');

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const email = params.get('email');

        if (email) {
            requestMagicLink(email)
                .then(() => {
                    setMessage('Magic link has been sent to your email.');
                })
                .catch((error) => {
                    console.error('Error requesting magic link:', error);
                    setMessage('Failed to send magic link. Please try again.');
                });
        } else {
            setMessage('Email parameter is missing.');
        }
    }, [location.search]);

    return (
        <div>
            <h1>Request Login</h1>
            <p>{message}</p>
        </div>
    );
};

export default RequestLogin;