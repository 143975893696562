import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SelectArtist from '../../components/CreateCoralFlow/SelectArtists/SelectArtists';
import ConfigureConnections from '../../components/CreateCoralFlow/ConfigureConnections/ConfigureConnections';
import PreviewImpact from '../../components/CreateCoralFlow/PreviewImpact/PreviewImpact';
import useCoralEditor from '../../hooks/useCoralEditor';
import SecondaryCoralButton from '../../components/CoralButtons/SecondaryCoralButton'; 
import CoralButton from '../../components/CoralButtons/CoralButton';
import Spinner from '../../components/Spinner/Spinner';
import CuratedCoralParent from '../../components/CuratedCoralModal/CuratedCoralModalParent';
import { trackEvent, EventName } from 'shared/lib/eventTracking';
import { useAuthContext } from '../../components/Auth/AuthContext/AuthContext';
import { ClientArtistListItem } from 'shared/types/platformTypes';

const CreateCoralFunnel: React.FC = () => {
    
    const { userProfile } = useAuthContext();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const artistAddMethod = queryParams.get('method');

    const [currentStep, setCurrentStep] = useState(0);
    const [maxStep, setMaxStep] = useState(0);
    const [isCuratedCoralModalOpen, setIsCuratedCoralModalOpen] = useState(false);
    const { 
        coralData, 
        addArtistDirectly, 
        removeArtist, 
        addArtistFromDynamicArtistPool, 
        addPinnedArtist, 
        removePinnedArtist, 
        retrieveArtistsFromCoral, 
        removeArtistsBySourceArtistPoolGuid, 
        uniqueGenres, 
        uniqueLocations, 
        dynamicArtistPools, 
        activeFilters, 
        updatePledgeAmount, 
        saveCoral, 
        simulateCoral, 
        simulationResults, 
        updateFilter, 
        sourceArtistPoolGuids,
        isDynamicArtistPools
    } = useCoralEditor('');
    const history = useHistory();

    const openCuratedCoralModal = () => setIsCuratedCoralModalOpen(true);
    const closeCuratedCoralModal = () => setIsCuratedCoralModalOpen(false);

    const handleSaveCoral = async () => {
        const newGuid = await saveCoral();
        trackEvent(EventName.CREATE_CORAL_SAVED, {
            userId: userProfile!.userId,
            coralId: newGuid!,
            amount: coralData!.pledgeAmount,
            artistCount: simulationResults.artistAllocations.length
        });
        if (newGuid) history.push(`/coral/${newGuid}`);
    };

    const handleProceedToPayment = async () => {
        const newGuid = await saveCoral();
        if (newGuid) history.push(`/payment/${newGuid}`);
    };


    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const hasDuplicateArtists = (artists: ClientArtistListItem[]): boolean => {
        const artistNames = artists
            .filter(artist => artist.selected)
            .map(artist => {
                if (artist.artistData && artist.artistData.name) {
                    return artist.artistData.name;
                }
                return artist.artistName;
            })
            .filter((name): name is string => name !== undefined);
    
        return new Set(artistNames).size !== artistNames.length;
    };

    const validateArtists = (): string | null => {
        const selectedArtists = coralData!.artistPool.artistList.filter(artist => artist.selected);
        if (hasDuplicateArtists(selectedArtists)) {
            return "Please remove duplicate artists before proceeding. You cannot have artists with the same name in a coral";
        }
        return null;
    };

    const goNext = () => {
        if (steps[currentStep].name === 'Choose Artists') {
            const errorMessage = validateArtists();
            if (errorMessage) {
                alert(errorMessage);
                return;
            }
        }
        setCurrentStep((prevStep) => {
            const nextStep = Math.min(prevStep + 1, steps.length - 1);
            setMaxStep((prevMaxStep) => Math.max(nextStep, prevMaxStep));
            return nextStep;
        });
    };
    
    const goBack = () => {
        setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
    };
    
    const onStepClick = (step: number) => {
        if (step <= maxStep) {
          setCurrentStep(step);
        }
    };

    if (!coralData || !coralData.artistPool || !coralData.artistPool.artistList) {
        return (
            <div className="flex items-center justify-center h-screen">
                <Spinner />
            </div>
        );
    }

    const defaultStepProps = {
        addArtistDirectly,
        removeArtist,
        removeArtistsBySourceArtistPoolGuid,
        updateFilter,
        simulateCoral,
        updatePledgeAmount,
        addArtistFromDynamicArtistPool,
        retrieveTopArtists: () => {}, // Implement this function if needed
        addPinnedArtist,
        removePinnedArtist,
        handleAddToCoral: retrieveArtistsFromCoral,
        activeArtistPoolGuids: sourceArtistPoolGuids,
        openCuratedCoralModal
    };

    const steps = artistAddMethod === 'manual' ? [
        { 
            name: 'Choose Artists', 
            component: SelectArtist,
            props: defaultStepProps,
        },        
        {
            name: 'Preview Impact',
            component: PreviewImpact,
            props: defaultStepProps,
        }
    ] : [
        { 
            name: 'Configure Connections', 
            component: ConfigureConnections,
            props: defaultStepProps,
        },
        { 
            name: 'Choose Artists', 
            component: SelectArtist,
            props: defaultStepProps,
        },        
        {
            name: 'Preview Impact',
            component: PreviewImpact,
            props: defaultStepProps,
        }
    ];

    const sharedProps = {
        artists: coralData.artistPool.artistList.map(({ artistId, artistName, artistListItemStatus, artistData, source, sourceType, sourceArtistPoolGuid, selected, artistListItemActive, payoutStatus }) => ({
            artistId: artistId || '',
            artistName,
            artistListItemStatus,
            artistData,
            source,
            sourceArtistPoolGuid,
            sourceType,
            selected,
            artistListItemActive,
            payoutStatus,
        })),
        simulationResults,
        pledgeAmount: coralData.pledgeAmount,
        activeFilters,
        uniqueGenres,
        uniqueLocations,
        dynamicArtistPools,
        pinnedArtists: coralData.pinnedArtists,
        sourceArtistPoolGuids,
        isDynamicArtistPools
    };

    const CurrentStepComponent = steps[currentStep].component;

    const currentStepProps = {
        ...sharedProps,
        ...steps[currentStep].props,
    };

    const isFirstStep = currentStep === 0;
    const isLastStep = currentStep === steps.length - 1;

    return (
        <div className="flex flex-col h-screen" style={{ height: '-webkit-fill-available' }}>
            <CuratedCoralParent
                isModal={true}
                isOpen={isCuratedCoralModalOpen}
                onClose={closeCuratedCoralModal}
                handleAddToCoral={retrieveArtistsFromCoral}
                activeArtistPoolGuids={sourceArtistPoolGuids}
                removeArtistsBySourceArtistPoolGuid={removeArtistsBySourceArtistPoolGuid}
            />
            <div className="bg-white border-b border-gray-200">
                <div className="max-w-screen-lg mx-auto px-4 py-3 sm:px-6 lg:px-8">
                    <nav className="flex items-center justify-between">
                        <ol className="flex items-center space-x-4">
                            {steps.map((step, index) => (
                                <li key={step.name} className="flex items-center">
                                    {index > 0 && (
                                        <svg className="flex-shrink-0 h-5 w-5 text-gray-300" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                                        </svg>
                                    )}
                                    <a
                                        href="#"
                                        className={`ml-4 text-sm font-medium ${
                                            index === currentStep
                                                ? 'text-indigo-600'
                                                : index < currentStep
                                                ? 'text-gray-500 hover:text-gray-700'
                                                : 'text-gray-400'
                                        }`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            onStepClick(index);
                                        }}
                                    >
                                        {step.name}
                                    </a>
                                </li>
                            ))}
                        </ol>
                        <button
                            onClick={() => history.push('/dashboard')}
                            className="text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        >
                            <span className="sr-only">Close</span>
                            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </nav>
                </div>
            </div>

            {/* Main content area */}
            <div className="flex-grow flex flex-col">
                <div className="flex-grow sm:overflow-y-auto">
                <div className="max-w-screen-lg mx-auto px-4 py-6 sm:px-6 lg:px-8">
                    <CurrentStepComponent {...currentStepProps} />
                </div>
                </div>
            </div>
             
            <div className="bg-white border-t border-gray-200" style={{ paddingBottom: 'env(safe-area-inset-bottom)' }}>
                <div className="max-w-screen-lg mx-auto px-4 py-4 sm:px-6 lg:px-8">
                    <div className="flex justify-between items-center">
                        {!isFirstStep && (
                            <SecondaryCoralButton onClick={goBack}>Back</SecondaryCoralButton>
                        )}
                        {isFirstStep && <div></div>}

                        {!isLastStep && (
                            <CoralButton
                                onClick={goNext}
                            >
                                Next
                            </CoralButton>
                        )}

                        {isLastStep && (
                            <div className="flex space-x-4">
                                <SecondaryCoralButton
                                    onClick={handleSaveCoral}
                                >
                                    Save and Exit
                                </SecondaryCoralButton>
                                <CoralButton
                                    onClick={handleProceedToPayment}
                                    disabled={coralData.pledgeAmount <= 0}
                                >
                                    Pledge
                                </CoralButton>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateCoralFunnel;