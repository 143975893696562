import React from 'react';
import { ClientArtistListItem, Source } from 'shared/types/platformTypes';
import selectedStar from '../../assets/images/selected-star.png'; 
import disabledStar from '../../assets/images/disabled-star.png'; 
import disabledIcon from '../../assets/images/disabled-icon.png';
import plusIcon from '../../assets/images/create-coral/add-artist.png';
import addedIcon from '../../assets/images/create-coral/added-artist.png';
import fromUserAdd from '../../assets/images/create-coral/user-added-mini-icon.png';
import fromCuratedCoral from '../../assets/images/create-coral/curated-coral-mini-icon.png';
import { Tooltip } from 'react-tooltip';
import Spinner from '../Spinner/Spinner';
import ArtistSearchStatus from './ArtistSearchStatus';

interface ArtistTableProps {
  artists: Array<ClientArtistListItem>;
  emptyTableMessage?: string;
  onRemoveArtist?: (artistId: string) => void;
  addArtistFromDynamicArtistPool?: (artist: ClientArtistListItem) => void;
  viewType?: 'view' | 'selected' | 'suggested';
  pinnedArtists?: string[];
}

const MiniArtistTable: React.FC<ArtistTableProps> = ({
  artists,
  onRemoveArtist = () => {},
  addArtistFromDynamicArtistPool = () => {},
  viewType = 'suggested',
  emptyTableMessage = 'No artists yet',
  pinnedArtists,
}) => {

  function isSelected(artist: ClientArtistListItem, selectedArtistNames: string[]): boolean {
    return artist.selected === true || selectedArtistNames.includes(artist.artistName!);
  }

  function isPending(artist: ClientArtistListItem): boolean {
    return artist.artistListItemStatus === 'pending';
  }

  function isNotFound(artist: ClientArtistListItem): boolean {
    return artist.artistListItemStatus === 'not found';
  }

  const uniqueArtists = artists.reduce<ClientArtistListItem[]>((unique, artist) => {
    const existingArtist = unique.find(
      (a) => a.artistName!.toLowerCase() === artist.artistName!.toLowerCase()
    );

    if (existingArtist) {
      // If the existing artist is selected, keep it
      if (existingArtist.selected) {
        return unique;
      }

      // If the current artist is selected or has a source of SHARED, replace the existing artist
      if (artist.selected || artist.source === Source.SHARED) {
        const index = unique.indexOf(existingArtist);
        unique[index] = artist;
      }
    } else {
      unique.push(artist);
    }

    return unique;
  }, []);

  const sortedUniqueArtists = uniqueArtists;

  const artistsToShow = viewType === 'selected' 
    ? sortedUniqueArtists.filter(artist => artist.selected || artist.source === Source.USER)
    : viewType === 'view' 
    ? sortedUniqueArtists.filter(artist => artist.selected)
    : viewType === 'suggested'
    ? sortedUniqueArtists
    : sortedUniqueArtists;
  
  const selectedArtistNames = artists.filter(artist => artist.selected).map(artist => artist.artistName!);

  const renderIconStatus = (artist: ClientArtistListItem) => {
    if (viewType === 'view' && artist.artistId) {
        return (
            <div className="flex items-center">
                <img 
                    className="transition-transform duration-500 ease-in-out transform hover:scale-110"
                    src={pinnedArtists && pinnedArtists.includes(artist.artistId) ? selectedStar : disabledStar}
                    alt="Star icon"
                    width="24"
                    height="24"
                />
            </div>
        );
    }
    if (viewType === 'suggested') {
      return (
        <div className="flex items-center">
          {isSelected(artist, selectedArtistNames) ? (
            isPending(artist) ? (
              <button data-tooltip-id="searchingTooltip" data-tooltip-content="We're searching for this artist in our database. Try a different spelling if nothing returns.">
                <Spinner className="w-5 h-5 animate-pulse" />
                <Tooltip id="searchingTooltip" />
              </button>
            ) : isNotFound(artist) ? (
              <button>
                <img src={plusIcon} alt="Add artist" className="w-5 h-5" />
              </button>
            ) : (
              <button>
                <img src={addedIcon} alt="Remove artist" className="w-5 h-5" />
              </button>
            )
          ) : artist.artistListItemActive === true ? (
            <button>
              <img src={plusIcon} alt="Add artist" className="w-5 h-5" />
            </button>
          ) : (
            <button data-tooltip-id="disabledIconTooltip" data-tooltip-content={
              artist.artistListItemStatus === 'top-artist' 
                ? "This artist has reached high levels of commercial success and is not eligible for wearecoral."
                : artist.artistListItemStatus === "deceased" 
                  ? "wearecoral is for living artists as it's about supporting a thriving ecosystem."
                  : "This artist is not eligible for wearecoral."
            }>
              <img src={disabledIcon} alt="Artist is disabled" className="w-4 h-4" />
              <Tooltip id="disabledIconTooltip" />
            </button>
          )}
        </div>
      );
    }
  };

  const renderSourceIcon = (artist: ClientArtistListItem) => {
    const sharedArtist = artists.find(a => 
      a.artistName === artist.artistName && a.source === Source.SHARED
    );

    if (sharedArtist || artist.source === Source.SHARED) {
      return <img src={fromCuratedCoral} alt="Curated Coral icon" className="w-6 h-6" />;
    } else if (artist.source === Source.USER) {
      return <img src={fromUserAdd} alt="User added icon" className="w-6 h-6" />;
    }
    return null;
  };

  return (
    <div className="py-4">
      {viewType === 'suggested' && <ArtistSearchStatus artists={artists} />}
      <table className="min-w-full">
        {viewType === 'view' && (
          <thead className="bg-gray-50 text-xs text-gray-500">
            <tr>
              <th scope="col" className="px-6 py-3 text-right align-bottom"></th>
              <th scope="col" className="px-6 py-3 text-right align-bottom"></th>
              <th scope="col" className="px-6 py-3 text-right align-bottom"></th>
              <th scope="col" className="px-6 py-3 text-right align-bottom">Payout Status</th>
              <th scope="col" className="px-6 py-3 text-right align-bottom">Your<br />Total Payout</th>
              <th scope="col" className="px-6 py-3 text-right align-bottom">Our<br />Monthly Impact</th>
              <th scope="col" className="px-6 py-3 text-right align-bottom"></th>
            </tr>
          </thead>
        )}
        <tbody className="bg-white text-sm">
          {artistsToShow.length > 0 ? (
            artistsToShow.map((artist) => (
              <tr 
                key={`${artist.artistName}-${artist.source}`} 
                className={`border-b cursor-pointer ${
                  isSelected(artist, selectedArtistNames) 
                    ? isPending(artist)
                      ? 'text-gray-500 italic'
                      : 'text-gray-900 font-semibold'
                    : 'text-gray-500'
                }`}
                onClick={viewType === 'suggested' ? () => {
                  if (artist.artistName && artist.artistListItemActive) {
                    if (artist.selected === true || selectedArtistNames.includes(artist.artistName)) {
                      onRemoveArtist(artist.artistName);
                    } else {
                      addArtistFromDynamicArtistPool(artist);
                    }
                  }
                } : undefined}
              > 
                <td className="whitespace-nowrap text-left w-12">{renderIconStatus(artist)}</td>
                <td className="px-3 py-4 whitespace-nowrap overflow-hidden overflow-ellipsis max-w-28 sm:whitespace-normal sm:overflow-visible sm:max-w-full">
                  {artist.artistName}
                </td>
                {viewType === 'view' && (
                  <>
                    <td className="px-6 py-4 whitespace-nowrap">{artist.artistData?.country}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-right">
                      <span className={`px-2 py-1 text-xs rounded-full mr-2 ${artist.payoutStatus === 'pending' ? 'text-purple-800 bg-purple-200' : 'text-green-800 bg-green-200'}`}>
                        {artist.payoutStatus}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right">${artist.totalUserPayout}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-right">${artist.lastMonthPlatformPayout}</td>
                  </>
                )}
                {viewType !== 'view' && (
                  <td className="px-6 py-4 whitespace-nowrap">{artist.artistData?.country}</td>
                )}
                <td className="px-6 py-4 whitespace-nowrap text-right flex items-center justify-end">
                  {renderSourceIcon(artist)}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={viewType === 'view' ? 7 : 4} className="px-6 py-4 whitespace-nowrap text-center text-gray-500">
                {emptyTableMessage}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default MiniArtistTable;