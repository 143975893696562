import { FC } from "react";
import { useHistory } from 'react-router-dom';
import { PayPalButtons } from "@paypal/react-paypal-js";
import { createCoralSubscription } from "../../api/api"
import React from "react";
import { useAuthContext } from '../../components/Auth/AuthContext/AuthContext';

interface PayPalSubscribeButtonProps {
    planId: string;
    coralId: string;
    subscriptionId: string | undefined;
}

const PayPalSubscribeButton: FC<PayPalSubscribeButtonProps> = ({
    planId,
    coralId,
    subscriptionId
}) => {
    const { userProfile } = useAuthContext();
    const history = useHistory();

    if (!planId) {
        throw new Error("Plan ID is missing");
    }

    const createSubscription = async (paypalSubscriptionId: string) => {
        try {
            const subscriptionResponse = await createCoralSubscription(coralId, paypalSubscriptionId);

            // I've removed the track event for now becuase there is no artist count available

            return subscriptionResponse;

        } catch (error) {
            console.error('Failed to set coral subscription to pending:', error);
            throw error;
        }
    };

    return (
        <PayPalButtons
            style={{ layout: 'vertical' }}
            createSubscription={(data, actions) => {
                if (subscriptionId) {
                    return actions.subscription.revise(subscriptionId, {
                        'plan_id': planId
                    });
                } else {
                    return actions.subscription.create({
                        'plan_id': planId,
                        'custom_id': coralId
                    });
                }
            }}
            onApprove={(data, actions) => {
                if (actions.subscription) {
                    return actions.subscription.get().then(async function (details) {
                        if (!details.id) {
                            throw new Error("Subscription ID not found");
                        }

                        try {
                            if(userProfile){
                                const response = await createSubscription(details.id);
                                if (response) {
                                        history.push(`/coral/${response.guid}`);
                                } else {
                                    throw new Error("Coral not activated");
                                }
                            }
                            else {
                                history.push('/thankyou');
                            }
                        } catch (error) {
                            console.error("Error during subscription creation:", error);
                        }
                    });
                } else {
                    throw new Error("Subscription not found");
                }
            }}
            onError={(err: Record<string, unknown>) => {
                console.error("Error during the subscription process", err);
                // Error handling logic (e.g., show error message to user)
            }}
        />
    );
}

export default PayPalSubscribeButton;