import React, { useEffect } from 'react';
import aboutHeroImage from '../../assets/images/coral-wide-1.png';
import lightBlueWaveImage from '../../assets/images/light-blue-wave.png';
import JoinCoralButton from '../../components/CoralButtons/JoinCoralButton';
import { trackEvent, EventName } from 'shared/lib/eventTracking';

const Terms: React.FC = () => {
    useEffect(() => {
        trackEvent(EventName.TERMS_PAGE_VIEW, {});
    }, []);

    return (
        <div className="fade-in-top">
            <div className="max-w-screen-xl mx-auto">
                <div className="p-16 bg-white w-auto md:w-2/3 text-left">
                    <h1 className="font-nunito text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-coral-black font-extrabold">
                        The Fine Print.
                    </h1>
                </div>
            </div>
            
            <div className="2xl:hidden">
                <img src={aboutHeroImage} alt="Terms Hero" className="aspect-content aspect-center w-full h-auto object-cover" />
            </div>

            <div className="2xl:h-[500px] relative">
                <img src={aboutHeroImage} alt="Terms Hero" className="absolute inset-0 w-full h-full object-cover"/>
            </div>

            <div className="p-16">
                <div className="max-w-screen-xl mx-auto flex flex-col md:flex-row">
                    <div className="md:w-1/2 mb-6 md:mb-0 md:pr-8">
                        <h2 className="text-sm md:text-base font-extrabold">
                            <div className="inline bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange uppercase">
                                Commitments
                            </div>
                        </h2>
                        <h2 className="font-nunito text-2xl md:text-3xl text-coral-black font-extrabold">
                            How We&apos;ll Work Together
                        </h2>
                    </div>
                    
                    <div className="md:w-1/2">
                        <p className="text-xs sm:text-sm md:text-base text-coral-black">
                            At wearecoral, our mission is to support a thriving music ecosystem. We provide a platform that makes it easy for you to select and voluntarily contribute to the artists you love. In return for our service, we charge a small platform fee. By using wearecoral, you acknowledge that your contribution is voluntary and given to artists without any obligation or expectation of receiving anything in return.
                        </p>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black pt-4">
                            We are committed to being intentional, transparent, and fair in our operations. We believe in fostering a collaborative community and actively seeking feedback to continuously improve our platform. Our goal is to create a dynamic, evolving system that responds to the needs of the music community.
                        </p>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black pt-4">
                            By working together, we can build a strong support system for music that makes a lasting impact. We value open dialogue and encourage everyone to participate in shaping the future of wearecoral. Thank you for being a part of this journey with us.
                        </p>
                    </div>
                </div>
            </div>

            <div className="p-16">
                <div className="max-w-screen-xl mx-auto flex flex-col md:flex-row">
                    <div className="md:w-1/2 mb-6 md:mb-0 md:pr-8">
                        <h2 className="text-sm md:text-base font-extrabold">
                            <div className="inline bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
                                TERMS & CONDITIONS
                            </div>
                        </h2>
                        <h2 className="font-nunito text-2xl md:text-3xl text-coral-black font-extrabold">
                            The Legalese
                        </h2>
                    </div>
                    
                    <div className="md:w-1/2">
                        <p className="text-xs sm:text-sm md:text-base text-coral-black">
                            <b>Effective Date: 28th June 2024</b>
                        </p>

                        <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">

                            wearecoral is a platform designed to nurture music and culture through direct contributions from users like you. The platform allows you to build <em>corals</em>.

                            A coral is your personal collection of artists you back with a recurring voluntary contribution. Each month as the coral is processed, the contribution is

                            distributed to the Nominated Artists as per the terms of this agreement. Our collective contributions give artists the ability to prioritise creating music.

                        </p>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                            By accessing the wearecoral the user acknowledges the receipt and review of these terms and conditions and
                            expressly agrees to be bound in contract with wearecoral Pty Ltd by these terms and conditions.
                        </p>

                        <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6">
                            1. Contribution to Nominated Artists
                        </h3>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                            a. wearecoral Pty Ltd and the user will both act with good faith in all of their dealings with each other.<br />
                            b. All contributions to artists must be made by wearecoral.<br />
                            c. All contributions are processed in Australian dollars.<br />
                            d. All contributions are subject to wearecoral Pty Ltd&apos;s discretion to accept the contribution and wearecoral Pty Ltd
                            may refuse to accept the contribution at its absolute discretion if the contribution:<br />
                            I. Is likely to result in a higher cost of acceptance and transfer than the value of the contribution;<br />
                            II. There is reason to believe the contribution may breach the warranties and agreements in clause 13 of these
                            terms and conditions.<br />
                            III. If in the assessment of wearecoral Pty Ltd is not in the interests or objectives wearecoral Pty Ltd are seeking
                            by enacting the wearecoral.<br />
                            e. Subject to this clause 1 and clause 2 of these terms and conditions wearecoral Pty Ltd will use its best endeavours
                            to provide the contribution to the artists nominated by the user in the proportion elected by the user.<br />
                            f. The user acknowledges that not all artists elect to receive contributions via wearecoral and where a user has
                            nominated an artist or artists who do not have an existing agreement to receive contributions from wearecoral will
                            undertake the following steps:<br />
                            I. Make all reasonable endeavours to establish an agreement with the artist so that the contribution can be
                            made in accordance with the users directions;<br />
                            II. If an agreement cannot be enacted with the artist within 9 months of the payment of the contribution
                            wearecoral Pty Ltd will allocate the payment to the other artists the user has nominated that do have an agreement with
                            wearecoral, such allocation in the same proportion as nominated by the user.<br />
                            III. If there are no artists nominated by the user that have an agreement enacted with wearecoral within 9
                            months of the contribution wearecoral Pty Ltd will use its best endeavours to refund the contribution to the
                            user and failing this will allocate the payment to artists most approximating those nominated by the user
                            as determined by wearecoral Pty Ltd in its absolute discretion.<br />
                            IV. The determination by wearecoral Pty Ltd in accordance with this clause 1(f) is final and not subject to
                            change or requested amendment by the user.<br />
                            V. The user will be advised by wearecoral Pty Ltd of the determination by wearecoral Pty Ltd in accordance
                            with this clause 1(f).
                        </p>

                        <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6">
                            2. Costs of Wearecoral
                        </h3>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                            a. The user acknowledges that wearecoral Pty Ltd incurs costs to operate the wearecoral, facilitate payments to
                            artists and undertake the general governance and administration functions required of the wearecoral Pty Ltd
                            corporate entity.<br />
                            b. wearecoral Pty Ltd charges a Platform Fee for all contributions to the wearecoral platform. This Platform Fee is
                            disclosed to the user on the wearecoral platform prior to the user confirming a contribution.<br />
                            c. The Platform Fee charged by wearecoral Pty Ltd is subject to the actual costs incurred by wearecoral Pty Ltd and
                            wearecoral Pty Ltd reserves the right to change the Platform Fee at any time without prior notice.
                        </p>

                        <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6">
                            3. Delays
                        </h3>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                            a. wearecoral Pty Ltd will use its best endeavours to make all payments to artists within 45 days of receipt from the
                            user.<br />
                            b. The user acknowledgers and agrees that despite wearecoral Pty Ltd&apos;s best endeavours some payments may be
                            made to artists greater than 9 months after receipt of the funds from the user and in such circumstances the delay
                            does not provide the user with any right to cancel the contribution, require a refund or make any claim in law or
                            equity against wearecoral Pty Ltd or its officers.
                        </p>

                        <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6">
                            4. Relationship of the parties
                        </h3>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                            a. In these Terms and Conditions the Parties refer to the user and wearecoral Pty Ltd collectively.<br />
                            b. The Parties acknowledge that their relationship is such that wearecoral Pty Ltd are a service provider facilitating
                            contributions from the user to artists nominated by the user. Transactions facilitated by wearecoral do not
                            establish any other relationship and, in particular, not the relationship of principal and agent, partnership or joint
                            venture.
                        </p>

                        <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6">
                            5. Nature of contributions
                        </h3>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                            a. wearecoral Pty Ltd provides the user with the wearecoral platform to make contributions to nominated artists.
                            Contributions to artists on the wearecoral platform are expressly voluntary contributions and are not due to or the
                            result of goods or services provided by the artist to the user or to wearecoral Pty Ltd.<br />
                            {/* b. Any failure to disclose any relationship wearecoral Pty Ltd or a related entity of wearecoral Pty Ltd has with an artist
                            receiving contributions on wearecoral does not provide the user with any right to cancel the contribution, require
                            a refund or make any claim in law or equity against wearecoral Pty Ltd or its officers unless such failure is the result
                            of deliberate action by wearecoral Pty Ltd or its officers or is due to gross negligence by wearecoral Pty Ltd or its
                            officers. */}
                        </p>

                        <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6">
                            6. Statutory obligations and keeping of accounts
                        </h3>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                            a. wearecoral Pty Ltd will comply with their statutory obligations in respect of the operation of wearecoral and the
                            administration of contributions to artists.<br />
                            b. wearecoral Pty Ltd will comply with their obligations to maintain accounting records such that accounts of all
                            contributions receipted and all payments to artists can be provided as required for legal or accounting purposes.
                            In maintaining accurate accounting records wearecoral Pty Ltd will comply with all requirements for accounts
                            pursuant to the Corporation Act 2001 (Cth).
                        </p>

                        <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6">
                            7. Receipts for contributions
                        </h3>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                            a. All contributions by users are voluntary contributions and take the form of a donation to the nominated artists.<br />
                            b. wearecoral Pty Ltd will provide a receipt to the user, in a form determined by wearecoral Pty Ltd, for all
                            contributions received by wearecoral. wearecoral Pty Ltd will not accept any requests for alteration to the receipt
                            provided by wearecoral Pty Ltd.<br />
                            c. The form of the receipt is subject to change at the sole discretion of wearecoral Pty Ltd.
                        </p>

                        <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6">
                            8. Refunds and cancellations
                        </h3>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                            a. Contributions by the user are final and binding.<br />
                            b. Any request for refund or cancellation by the user after the contribution has been receipted by wearecoral will be
                            considered and determined by wearecoral Pty Ltd at the sole discretion of wearecoral Pty Ltd.
                        </p>

                        <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6">
                            9. GST and Tax
                        </h3>
                        <div className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                            <p>
                                a. Definition of GST: For the purposes of these terms and conditions, GST refers to the goods and services tax as outlined in the A New Tax System (Goods and Services Tax) Act 1999 (Cth) along with all related legislation and regulations.
                            </p>
                            <p>
                                b. Platform Fee and GST: The Platform Fee charged by wearecoral Pty Ltd is subject to GST. 
                            </p>
                            <p>
                                c. Artist Contributions and GST: wearecoral Pty Ltd considers that artist contributions made by a user are not a taxable supply for the purposes of GST, and therefore, GST is not applicable to these contributions. Users registered for GST should not claim the GST component of the artist contribution for offset against their GST receipts. Users seeking a GST invoice or receipt, or wishing to claim a GST deduction for contributions, must notify wearecoral Pty Ltd; such contributions will thereafter not be accepted. Users are advised to rely on their own enquiries regarding GST.
                            </p>
                            <p>
                                d. Deductible Gift Recipient Status: wearecoral Pty Ltd is not a deductible gift recipient. Contributions made to an artist via wearecoral are not eligible for a tax deduction by the user.
                            </p>
                            <p>
                                e. User&apos;s Responsibility: The user acknowledges having made their own enquiries regarding the application of GST and tax to their contribution. Users are responsible for their own knowledge or decision not to enquire.
                            </p>
                            <p>
                                f. Taxable Status of Contributions: wearecoral Pty Ltd makes no representation regarding the taxable status of contributions to artists. It is the artist&apos;s responsibility to seek advice on the taxable status of any payments received from wearecoral.
                            </p>
                        </div>

                        <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6">
                            10. Confidential information and privacy
                        </h3>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                            a. For the purposes of these terms and conditions Confidential Information means all information that a reasonable
                            person would consider confidential whether provided in oral, written or electronic form from one Party to the other
                            Party or otherwise learned by a Party due to provision of contribution funds from the user to wearecoral.<br />
                            b. Subject to subclause c. of this clause 10, a Party shall not at any time or for any reason divulge any Confidential
                            Information to any third party and each Party hereby indemnifies the other Party and any related entity against any
                            loss or damage they may suffer as a result of breach of the Confidential Information obligations in these terms and
                            conditions.<br />
                            c. A Party may disclose Confidential Information:<br />
                            I. as may be required by law; or<br />
                            II. to their legal or accounting advisors.<br />
                            But only to the extent that is necessary for compliance with legal obligations or to obtain proper legal or accounting
                            advice.
                        </p>

                        <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6">
                            11. Intellectual property
                        </h3>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                            a. Intellectual Property means all intellectual property rights conferred by law or equity in relation to patents,
                            copyright, trademark, designs, logos, formulas, inventions and any other component of the wearecoral Pty Ltd or
                            the operations of wearecoral that are unique to wearecoral or wearecoral Pty Ltd. Intellectual property includes all
                            marketing collateral owned by wearecoral Pty Ltd.<br />
                            b. There is no assignment of the Intellectual Property rights to the user by virtue of the user providing one or more
                            contributions via the wearecoral application or as a result of any other interaction between the user and wearecoral
                            Pty Ltd.<br />
                            c. The user does not obtain the right to download, copy, reproduce or use the Intellectual Property of wearecoral Pty
                            Ltd and any use of wearecoral Pty Ltd&apos;s Intellectual Property without the express written consent of wearecoral Pty
                            Ltd will be a breach of wearecoral Pty Ltd&apos;s Intellectual Property rights and may result in wearecoral Pty Ltd
                            initiating a legal claim to enforce its rights and seek damages for any loss suffered.
                        </p>

                        <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6">
                            12. Indemnity
                        </h3>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                            a. To the maximum extent permitted at law the user unconditionally and irrevocably indemnifies wearecoral Pty Ltd
                            against any and all third party demands, claims, actions, suits, costs and expenses that are incurred by wearecoral
                            Pty Ltd in relation to wearecoral accepting the contribution of the user or due to wearecoral providing the
                            contribution to the artist or artists nominated by the user or as otherwise determined by these terms and
                            conditions.<br />
                            b. To the maximum extent permitted at law the user unconditionally and irrevocably indemnifies wearecoral Pty Ltd
                            against any and all demands, claims, actions, suits, costs and expenses that are sought by the user from
                            wearecoral Pty Ltd in relation to wearecoral accepting the contribution of the user or due to wearecoral Pty Ltd
                            providing the contribution to the artist or artists nominated by the user or as otherwise determined by these terms
                            and conditions.<br />
                            c. These Terms and Conditions as accepted by the user acknowledge the reasonableness of the indemnities in this
                            clause 13 add the user agrees that wearecoral Pty Ltd may plead these indemnities as a bar to any claim for
                            damages by the user.<br />
                            d. The user&apos;s obligation to indemnify wearecoral Pty Ltd is a primary obligation and the user is not obliged to proceed
                            against any other person before making a demand for indemnification.<br />
                            e. The information on wearecoral is general in nature and while wearecoral Pty Ltd has made reasonable endeavours
                            to ensure the information is accurate wearecoral Pty Ltd does not warrant the accuracy, adequacy, reliability or
                            completeness of the information and:<br />
                            I. wearecoral Pty Ltd expressly disclaims liability for errors or omissions in such information.<br />
                            II. The user should rely upon their own enquiries to verify any information on wearecoral; and<br />
                            III. To the maximum extent permitted by law wearecoral Pty Ltd will not be liable for any loss or damage suffered
                            by the user due to reliance on the information on wearecoral.
                        </p>

                        <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6">
                            13. User warranties and agreements
                        </h3>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                            a) The user warrants:<br />
                            I. If the user is a natural person the user is over the age of 18 and has capacity and is not in vulnerable
                            circumstances.<br />
                            II. If the user is a corporate entity, the officer enacting the contribution has the authority to do so.<br />
                            III. That the funds donated by the user are the user&apos;s funds and are not the funds of any other person or entity or
                            are encumbered, subject to charge or otherwise funds the user is not legally entitled to donate.<br />
                            IV. The donated funds have not been illegally or fraudulently obtained and in providing the funds the user does
                            not have the intent to undertake an illegal or fraudulent transaction and will not be undertaking an illegal or
                            fraudulent transaction.<br />
                            V. The user is providing the funds as a genuine contribution and the funds are not being provided:<br />
                            (i) In return for services rendered to the user or a related party of the user by a nominated artist or
                            artists.<br />
                            (ii) Due to any representation or promise made to the user by wearecoral or the nominated artist or
                            artists.<br />
                            (iii) To illegally circumvent tax laws of the Commonwealth of Australia, any state of the
                            Commonwealth of Australia or any other sovereign nation or state.<br />
                            (iv) To illegally circumvent any other laws or regulations of the Commonwealth of Australia, any
                            state of the Commonwealth of Australia or any other sovereign nation or state.<br />
                            (v) From a source or in manner that may expose wearecoral Pty Ltd to prosecution or civil legal
                            action.<br />
                            VI. The user is not related to an artist or artists the user has nominated to receive a contribution and the user
                            has not previously at any time engaged the professional services of an artist or artists the user has
                            nominated to receive a contribution<br />
                            VII. It has had the opportunity to read these terms and conditions and accepts them.<br />
                            b) For the purposes of the provision of wearecoral and the services provided by wearecoral Pty Ltd to facilitate
                            contributions the user agrees that the user is not a Consumer as defined under Australian Consumer Law and the
                            provisions of the Australian Consumer Law do not apply to the rights and obligations between the Parties.<br />
                            c) The user agrees wearecoral Pty Ltd has the express authority to advise the relevant authorities of any information
                            or knowledge wearecoral Pty Ltd obtains through the acceptance of contributions and the payment of
                            contributions to an artist or artists nominated by the user that indicates a criminal offence has or may occurred or
                            a that fraud, illegal circumvention of tax laws or illegally circumvent any other laws or regulations has or may occur.
                        </p>

                        <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6">
                            14. Suspension and cessation of services
                        </h3>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                            a. wearecoral Pty Ltd may suspend or indefinitely cease the operation of wearecoral without notice and at wearecoral
                            Pty Ltd&apos;s absolute discretion.<br />
                            b. wearecoral Pty Ltd may suspend or indefinitely ban a user from the use of wearecoral without notice and at
                            wearecoral Pty Ltd&apos;s absolute discretion.<br />
                            c. wearecoral Pty Ltd may suspend or indefinitely ban an artist from receiving contributions from a user via the
                            wearecoral without notice and at wearecoral Pty Ltd&apos;s absolute discretion.
                        </p>

                        <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6">
                            15. General Terms
                        </h3>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                            a. These terms and conditions are not to be construed against wearecoral Pty Ltd on the basis that wearecoral Pty
                            Ltd&apos;s legal representatives were responsible for drafting the terms and conditions.<br />
                            b. These terms and conditions constitute the entire terms for the provision of contributions by a user on the
                            wearecoral.<br />
                            c. If any part of these terms and conditions is, or becomes void or unenforceable, that part is or will be severed from
                            these terms so that all parts of the terms and conditions that are not or do not become void or unenforceable
                            remain in full force and effect and are unaffected by that severance.<br />
                            d. The user agrees that at the request of wearecoral Pty Ltd they will do everything reasonably necessary to give effect
                            to these terms and conditions.<br />
                            e. These terms shall be governed by and construed in accordance with the law of the State of New South Wales and
                            the Parties agree to submit to the jurisdiction of the Courts of that state.<br />
                            f. These terms and conditions can be changed or modified by wearecoral Pty Ltd at any time and the user agrees to
                            be bound by these terms and conditions as changed or modified. Notice of any change or modification will be
                            provided by publishing the revised terms and conditions on this website and separate notification will not be given
                            to users individually.
                        </p>

                        {/* The following sections are retained from the original component */}
                        <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6">
                            16. Third-Party Metadata
                        </h3>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                            The Platform may use metadata from other music data services. wearecoral Pty Ltd does not claim any ownership rights in this metadata, and your use of the Platform is subject to the terms and conditions of these third-party services.
                        </p>

                        <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6">
                            17. Communication
                        </h3>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                            By creating an account on the Platform, you agree to receive transactional emails related to your account and the Platform&apos;s services. You may also opt-in to receive updates about the platform&apos;s development, community discussions, and opportunities to provide feedback. We value open communication with our users and welcome your engagement in shaping the future of wearecoral.
                        </p>

                        <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6">
                            18. Wearecoral Mission Alignment
                        </h3>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                            wearecoral&apos;s mission is to foster a vibrant ecosystem of communities and their artists. As such, wearecoral may take actions to ensure that contributions are distributed in a way that aligns with this mission and supports the sustainability and growth of the platform. This may include adjusting the eligibility criteria for artists to receive contributions or implementing policies to promote a diverse and thriving music community on the platform.
                        </p>
                    </div>
                </div>
            </div>

            <div className="relative w-full h-full">
                <img src={lightBlueWaveImage} alt="Light Blue Wave" className="w-full h-full object-cover" />
                <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gradient-to-t from-coral-light-blue to-transparent"></div>
            </div>

            <div className="p-16 pt-0 bg-coral-light-blue">
                <div className="max-w-screen-xl mx-auto flex flex-col items-center">
                    <div className="w-full mb-4">
                        <h2 className="font-nunito text-2xl md:text-3xl font-extrabold pb-6 text-center">
                            A vibrant, healthy, and diverse ecosystem of music, artists and communities.
                        </h2>
                    </div>
                    <JoinCoralButton />
                </div>
            </div>
        </div>
    );
};

export default Terms;