import React from 'react';
import { useEffect, useState } from 'react';
import CoralChart from '../CoralChart/CoralChart';

interface GenreData {
  name: string;
  amount: number;
}

interface CoralData {
  transactionId: string;
  totalAmount: number;
  genres: GenreData[];
  artists: { name: string; amount: number }[];
}

interface CoralVisualisationProps {
  coral: CoralData;
  chartOnly?: boolean;
  size?: number; // New optional prop for size
}

const CoralVisualisation: React.FC<CoralVisualisationProps> = ({ coral, chartOnly = false, size: propSize }) => {
  const [size, setSize] = useState(propSize || 170);

  useEffect(() => {
    if (propSize !== undefined) {
      setSize(propSize);
      return;
    }

    const updateSize = () => {
      if (window.innerWidth < 1536) {
        setSize(110);
      } else {
        setSize(170);
      }
    };

    updateSize(); // Set initial size
    window.addEventListener('resize', updateSize);

    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, [propSize]);

  const truncateText = (text: string, maxLength: number) => {
    if (text.startsWith("The ")) {
      text = text.slice(4);
    }
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength - 3) + '...';
  };

  if (chartOnly) {
    return (
      <div className="flex">
        <CoralChart
          width={size}
          height={size}
          data={coral.genres}
          showAmounts={false}
        />
      </div>
    );
  }

  return (
    <div className="bg-white shadow-sm border border-gray-100 rounded-lg p-8 max-w-sm">
      <div className="flex items-center space-x-4">
        <div className="flex-shrink-0">
          <CoralChart
            width={size}
            height={size}
            data={coral.genres}
            showAmounts={false}
          />
        </div>
        <div className="flex-grow">
          {/* <h2 className="text-lg font-semibold text-gray-800 mb-2">Community Coral</h2> */}
          <p className="text-xs sm:text-sm text-gray-600 mb-1">Monthly Contribution</p>
          <p className="text-lg sm:text-xl font-bold text-coral-pink mb-3">${coral.totalAmount.toFixed(2)}</p>
          <div className="space-y-1">
            {coral.artists.slice(0, 3).map((artist, index) => (
              <div key={index} className="flex justify-between items-center text-xs lg:text-sm">
                    <span className="text-gray-700 overflow-hidden whitespace-nowrap overflow-ellipsis">
                      {truncateText(artist.name, 16)}
                    </span>
                <span className="font-medium text-coral-orange pl-1">${artist.amount.toFixed(2)}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoralVisualisation;