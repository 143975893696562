import React from 'react';
import { useHistory } from 'react-router-dom';
import { ClientCoral } from 'shared/types/platformTypes';
import { GetCoralSubscriptionStatusDescription, getCssClassForSubscriptionStatus } from '../../utils/transformData';

interface CoralCardProps {
    coral: ClientCoral;
}

const CoralCard: React.FC<CoralCardProps> = ({ coral }) => {
    const history = useHistory();
    const artists = coral.artistPool.artistList.map(artist => artist.artistName).join(', ');
    const handleCardClick = () => {
        history.push(`/coral/${coral.guid}`);
    };

    return (
        <div 
            className="bg-white rounded-lg shadow-md overflow-hidden transition-all duration-300 ease-in-out hover:shadow-lg cursor-pointer border border-gray-200 hover:border-coral-red"
            onClick={handleCardClick}
        >
            <div className="p-4 flex flex-col h-full">
                <div className="flex justify-between items-start mb-2">
                    <h2 className="text-gray-800 font-bold text-lg leading-tight mr-2 flex-grow">
                        <span className="line-clamp-1" title={coral.coralName || artists}>
                            {coral.coralName || artists}
                        </span>
                    </h2>
                    <span className={`${getCssClassForSubscriptionStatus(coral.subscriptionStatus)} text-xs font-semibold px-2 py-1 rounded-full whitespace-nowrap flex-shrink-0`}>
                        {GetCoralSubscriptionStatusDescription(coral.subscriptionStatus)}
                    </span>
                </div>
                <div className="text-xl font-semibold text-coral-red mb-2">
                    ${coral.pledgeAmount.toLocaleString()}
                    <span className="text-sm font-normal text-gray-500 ml-1">/ month</span>
                </div>
                <div className="mt-auto">
                    <p className="text-sm text-gray-600 truncate" title={artists}>{artists}</p>
                    <div className="h-1 w-16 bg-coral-red mt-2 rounded-full"></div>
                </div>
            </div>
        </div>
    );
};

export default CoralCard;