import React from 'react';
import { Group } from '@visx/group';
import { Pack, hierarchy } from '@visx/hierarchy';
import { Text } from '@visx/text';
import { LinearGradient } from '@visx/gradient';
import './styles.css';

interface GenreData {
  name: string;
  amount: number;
}

const categoryGradients: { [key: string]: { from: string; to: string } } = {
  'Pop': { from: '#9278A9', to: '#4B5FAD' },
  'Rock': { from: '#66CCCC', to: '#6FDFDF' },
  'Hip Hop': { from: '#FFA07A', to: '#FFB88C' },
  'R&B/Soul': { from: '#20B2AA', to: '#48D1CC' },
  'Country': { from: '#FF69B4', to: '#FF8DC7' },
  'Jazz': { from: '#00CED1', to: '#40E0D0' },
  'Blues': { from: '#FDBD80', to: '#FC5373' },
  'House': { from: '#40E0D0', to: '#67E8F9' },
  'Techno': { from: '#FA8072', to: '#FFA07A' },
  'Trance': { from: '#48D1CC', to: '#7FFFD4' },
  'Psychedelic Trance': { from: '#FF6B6B', to: '#4ECDC4' },
  'Dubstep': { from: '#45B7D1', to: '#D6ECF3' },
  'Bass': { from: '#FF8C69', to: '#FFA07A' },
  'Psychedelic': { from: '#FF69B4', to: '#FF1493' },
  'Drum & Bass': { from: '#20B2AA', to: '#3CB371' },
  'Ambient/Chillout': { from: '#87CEFA', to: '#00BFFF' },
  'Folk': { from: '#DEB887', to: '#D2691E' },
  'Indie': { from: '#9370DB', to: '#8A2BE2' },
  'Classical': { from: '#FFD700', to: '#FFA500' },
  'Reggae': { from: '#32CD32', to: '#228B22' },
  'Latin': { from: '#FF4500', to: '#FF6347' },
  'World': { from: '#4169E1', to: '#1E90FF' },
  'Metal': { from: '#A9A9A9', to: '#696969' },
  'Punk': { from: '#DC143C', to: '#B22222' },
  'Funk/Disco': { from: '#FF00FF', to: '#FF69B4' },
  'Religious': { from: '#FFD700', to: '#DAA520' },
  'High Vibe': { from: '#7FFFD4', to: '#00FA9A' },
};

const defaultMargin = { top: 0, left: 0, right: 0, bottom: 0 };

export type PackProps = {
  width: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  data?: GenreData[];
  showAmounts?: boolean;
};

const createValidSvgId = (name: string): string => {
  return name.replace(/[^a-zA-Z0-9]/g, '_');
};

export default function CoralChart({ 
  width, 
  height, 
  margin = defaultMargin, 
  data,
  showAmounts = true
}: PackProps) {
  if (width < 10) return null;

  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  const filteredData = data?.filter(item => item.name !== "Unknown" && item.amount !== 0 && !isNaN(item.amount)) || [];

  const pack = { children: filteredData, name: 'root', amount: 0 };

  const root = hierarchy<GenreData>(pack)
    .sum((d) => d.amount)
    .sort((a, b) => b.value! - a.value!);

  const getGradientKey = (name: string, index: number): string => {
    if (name in categoryGradients) {
      return name;
    }
    
    const keys = Object.keys(categoryGradients);
    const matchingKey = keys.find(key => key.toLowerCase() === name.toLowerCase());
    if (matchingKey) {
      return matchingKey;
    }
    
    return keys[index % keys.length];
  };

  return (
    <svg width={width+2} height={height+2}>
      {Object.entries(categoryGradients).map(([name, gradient]) => (
        <LinearGradient
          key={`gradient-${createValidSvgId(name)}`}
          id={`gradient-${createValidSvgId(name)}`}
          from={gradient.from}
          to={gradient.to}
        />
      ))}

      <rect width={width} height={height} fill="transparent" rx={20} ry={20} />

      <Pack<GenreData>
        root={root}
        size={[innerWidth, innerHeight]}
        padding={0.5}
      >
        {(packData) => {
          const circles = packData.descendants().slice(1);
          return (
            <Group top={margin.top} left={margin.left}>
              {circles.map((circle, i) => {
                const fontSize = Math.min(11, circle.r / 3);
                const gradientKey = getGradientKey(circle.data.name, i);
                const gradientId = createValidSvgId(gradientKey);
                const words = circle.data.name.split(' ');
                return (
                  <Group key={`circle-${i}`}>
                    <circle
                      r={circle.r}
                      cx={circle.x}
                      cy={circle.y}
                      fill={`url(#gradient-${gradientId})`}
                      className="shimmer slight-movement"
                    />
                    {circle.r > 20 && (
                      <>
                        {words.map((word, index) => (
                          <Text
                            key={`${i}-${index}`}
                            x={circle.x}
                            y={circle.y}
                            dy={`${index * fontSize - (words.length - 1) * fontSize / 2}px`}
                            fontSize={fontSize}
                            fontFamily="SF Pro Display, -apple-system, BlinkMacSystemFont, sans-serif"
                            fontWeight="bold"
                            textAnchor="middle"
                            fill="white"
                          >
                            {word}
                          </Text>
                        ))}
                        {showAmounts && (
                          <Text
                            x={circle.x}
                            y={circle.y}
                            dy={`${(words.length / 2 + 0.8) * fontSize}px`}
                            fontSize={fontSize * 0.8}
                            fontFamily="SF Pro Display, -apple-system, BlinkMacSystemFont, sans-serif"
                            textAnchor="middle"
                            fill="white"
                          >
                            {`$${circle.data.amount.toFixed(2)}`}
                          </Text>
                        )}
                      </>
                    )}
                  </Group>
                );
              })}
            </Group>
          );
        }}
      </Pack>
    </svg>
  );
}