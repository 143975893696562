import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import CoralButton from './CoralButton';
import { AuthContext } from '../Auth/AuthContext/AuthContext';

interface JoinCoralButtonProps {
    size?: 'default'|'small'|'large';
    isAnimated?: boolean;
    className?: string;
    children?: React.ReactNode;
}

const JoinCoralButton: React.FC<JoinCoralButtonProps> = ({ size = 'default', isAnimated = true, className, children }) => {
    const { setShowAuth, currentUser } = useContext(AuthContext);
    const history = useHistory();

    const sizes: Record<'default'|'small'|'large', string> = {
        'default': 'px-5 py-4',
        'large': 'px-8 py-6',
        'small': 'px-3 py-2',
    }

    const handleClick = () => {
        if (currentUser) {
            // If user is authenticated, redirect to dashboard
            history.push('/dashboard');
        } else {
            setShowAuth({ show: true, signIn: false }); // Show authentication modal immediately
        }
    };

    return (
        <CoralButton
            className={`${isAnimated ? 'animate-breathe' : ''} ${className} ${sizes[size]}`}
            popOnHover={true}
            onClick={handleClick}
        >
            <span className="sm:hidden pointer-events-none">{currentUser ? 'Dashboard' : (children || 'Create Your Coral')}</span>
            <span className="hidden sm:block pointer-events-none">{currentUser ? 'Go to Dashboard' : (children || 'Create Your Coral')}</span>
        </CoralButton>
    );
};

export default JoinCoralButton;