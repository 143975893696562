// src/pages/Dashboard/Dashboard.tsx

import React, { useState, useEffect } from 'react';
import { useDashboard } from '../../hooks/useDashboard';
import CoralCard from '../../components/CoralCard/CoralCard';
import dashboardEmptyReef from '../../assets/images/coral-reef--empty.svg';
import dashboardPopulatedReef from '../../assets/images/coral-reef.svg';
import SecondaryCoralButton from '../../components/CoralButtons/SecondaryCoralButton';
import CoralButton from '../../components/CoralButtons/CoralButton';
import Spinner from '../../components/Spinner/Spinner';
import CoralCardWithThumbnail from '../../components/CoralCard/CoralCardWithThumbnail';
import ImpactCard from '../../components/ImpactCard/ImpactCard';
import { SwiperSlide } from 'swiper/react';
import DashboardCarousel from '../../components/DashboardCarousel/DashboardCarousel';
import GenericCoralModal from '../../components/GenericCoralModal/GenericCoralModal';
import CuratedCoralModal from '../../components/CuratedCoralModal/Pages/CuratedCoralModal';
// import CuratedCoralModalParent from '../../components/CuratedCoralModal/CuratedCoralModalParent';
import CreateCoralModal from '../../components/CreateCoralModal/CreateCoralModal';
import { trackEvent, EventName } from 'shared/lib/eventTracking';
import { useAuthContext } from '../../components/Auth/AuthContext/AuthContext';

const Dashboard: React.FC = () => {
  const { userProfile } = useAuthContext();
  const { activeCorals, isLoading, firstName, impactData, curatedCorals, connectedArtists } = useDashboard();
  const [isCreateCoralModalOpen, setCreateCoralModalOpen] = useState(false);
  const [isCuratedCoralModalOpen, setIsCuratedCoralModalOpen] = useState(false);
  const [selectedCuratedCoralId, setSelectedCuratedCoralId] = useState('');

  useEffect(() => {
    trackEvent(EventName.DASHBOARD_PAGE_VIEW, {
      userId: userProfile!.userId,
    });
  }, []);

  const toggleCreateCoralModal = () => {
    if (!isCreateCoralModalOpen) {
      trackEvent(EventName.CREATE_CORAL_STARTED, { userId: userProfile?.userId || '' });
    }
    setCreateCoralModalOpen(!isCreateCoralModalOpen);
  };

  const openCuratedCoralModal = (coralId: string) => {
    setSelectedCuratedCoralId(coralId);
    setIsCuratedCoralModalOpen(true);
  };

  const closeCuratedCoralModal = () => {
    setSelectedCuratedCoralId('');
    setIsCuratedCoralModalOpen(false);
  };

  // Determine if there are any active corals
  const hasCorals = activeCorals.length > 0;
  const hasConnectedArtists = connectedArtists.length > 0;
  const dashboardBackgroundImage = hasCorals || hasConnectedArtists ? dashboardPopulatedReef : dashboardEmptyReef;

  if (isLoading) {
    return (
      <div className="relative h-full bg-white">
        <div className="absolute inset-0 bg-no-repeat bg-contain bg-bottom flex items-center justify-center">
          <Spinner />
        </div>
      </div>
    );
  }

  return (
    <div className="relative flex flex-col bg-white">
      {/* Conditional background image */}
      <div
        className={`flex-grow flex flex-col bg-no-repeat bg-contain bg-bottom 3xl:bg-cover animate-fadeIn`}
        style={{ backgroundImage: `url(${dashboardBackgroundImage})` }}
      >
        <div className="overflow-y-auto pb-32 sm:pb-32 md:pb-48 lg:pb-96 xl:pb-96">
          <section className="w-[calc(100%-50px)] max-w-5xl mx-auto">
            {hasCorals || hasConnectedArtists ? (
              <>
                <div className="pt-4 px-10 flex flex-col sm:flex-row justify-between items-center mb-4 sm:mb-8 ">
                  <h1 className="font-nunito text-lg sm:text-xl md:text-2xl font-extrabold mb-4 md:mb-0">
                    Hi, {firstName} 👋
                  </h1>
                  <SecondaryCoralButton onClick={toggleCreateCoralModal}>
                    + Create New Coral
                  </SecondaryCoralButton>
                </div>

                {hasConnectedArtists && (
                  <>
                    <h2 className="font-nunito text-base sm:text-lg md:text-xl font-extrabold mb-4 px-10">
                      Your Connected Artists
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-10 pt-0">
                      {connectedArtists.map((artist, index) => (
                        <ImpactCard key={index} id={`impact-${index}`} impact={artist} />
                      ))}
                    </div>
                  </>
                )}

                {hasCorals && (
                  <>
                    <h2 className="font-nunito text-base sm:text-lg md:text-xl font-extrabold mb-4 px-10">
                      Your Corals
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-10 pt-0">
                      {activeCorals.map((coral, index) => (
                        <CoralCard key={index} coral={coral} />
                      ))}
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <div className="my-20 flex flex-col items-center justify-center pt-16">
                  <h1 className="font-nunito text-lg sm:text-xl md:text-2xl font-extrabold mb-4 text-center">
                    Welcome, {firstName} 👋
                  </h1>
                  <p className="font-nunito text-base sm:text-lg md:text-xl lg:text-2xl mb-6 text-center">
                    Start by creating your coral.
                  </p>
                  <CoralButton onClick={toggleCreateCoralModal}>Create a Coral</CoralButton>
                </div>
              </>
            )}
            <CreateCoralModal isOpen={isCreateCoralModalOpen} onClose={toggleCreateCoralModal} />
            <div className="flex flex-col justify-center items-center gap-10 mb-24 mt-10">
              <p className="font-nunito text-sm sm:text-base md:text-lg lg:text-xl text-gray-500">
                See what&lsquo;s happening in the ecosystem.
              </p>
              <DashboardCarousel title="Featured Corals">
                {curatedCorals.map((coral, index) => (
                  <SwiperSlide key={index}>
                    <CoralCardWithThumbnail
                      link="#"
                      title={coral.coralName}
                      subtitle={coral.artists.join(', ')}
                      pageTurner={() => {}}
                      guid={coral.coralGuid}
                      standalone={true}
                      openCuratedCoralModal={openCuratedCoralModal}
                    />
                  </SwiperSlide>
                ))}
              </DashboardCarousel>
              <DashboardCarousel title="Trending Artists">
                {impactData.map((impact, index) => (
                  <SwiperSlide key={index}>
                    <ImpactCard id={`impact-${index}`} impact={impact} />
                  </SwiperSlide>
                ))}
              </DashboardCarousel>
            </div>
          </section>
        </div>
      </div>
      <GenericCoralModal isOpen={isCuratedCoralModalOpen} onClose={closeCuratedCoralModal}>
        <CuratedCoralModal coralId={selectedCuratedCoralId} standalone />
      </GenericCoralModal>
    </div>
  );
};

export default Dashboard;