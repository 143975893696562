export const faqData = [
    {
        "question": "What is wearecoral?",
        "answer": "wearecoral enables a new social contract between communities and artists through 'collective micro-patronage'. Create a coral - your personal collection of artists - and your monthly contribution goes directly to them, creating space for them to practice their craft. No \"exclusive content\", no perks, just fuel for their creativity."
    },
    {
        "question": "How is this different from streaming platforms?",
        "answer": "While streaming platforms pay fractions of cents per play, wearecoral enables meaningful monthly income that goes directly to artists. Use both: enjoy music on streaming platforms, back the artists with wearecoral."
    },
    {
        "question": "How does a coral work?",
        "answer": "A coral is your personal collection of artists you back monthly with a voluntary contribution. You choose the artists and the amount. Your money goes directly to those specific artists. It's like creating your own micro-label to sustain the music you care about, by giving musicians more freedom to keep creating."
    },
    {
        "question": "Do artists need to be on wearecoral first?",
        "answer": "No! That's what makes wearecoral unique. Start backing any eligible artist right away - this is how communities create space for their artists to thrive. When funds start accumulating, we'll contact them through their official channels. They have 9 months to claim their contributions."
    },
    {
        "question": "Is this patronage?",
        "answer": "Yes - but reimagined for today. While streaming platforms pay pennies and merchandise depends on time & place, wearecoral enables communities to create sustainable space for artists to practice their craft. It's about ensuring the music you value can thrive."
    },
    {
        "question": "How much should I contribute?",
        "answer": "The amount is up to you, but we suggest starting with the cost of a drink at a local gig. Regular backing, even in small amounts, adds up to meaningful income when combined with other contributors."
    },
    {
        "question": "What do I get in return?",
        "answer": "Your contribution ensures artists can focus on what matters - music. Unlike other platforms, we don't require artists to make exclusive content or extras. They already make the music you love; you can help them make more of it."
    },
    {
        "question": "How are funds distributed?",
        "answer": "After payment processing fees and a 7.75% platform fee with tax, contributions go directly to your chosen artists. Your contribution is split only between the artists in your coral."
    }
]