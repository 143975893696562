import React, { ReactNode, MouseEventHandler, useState } from 'react';
import Spinner from '../Spinner/Spinner';

interface CoralButtonProps {
    children: ReactNode;
    onClick: MouseEventHandler<HTMLButtonElement>;
    className?: string;
    disabled?: boolean;
    popOnHover?: boolean;
}

const CoralButton: React.FC<CoralButtonProps> = ({ children, onClick, className = '', disabled = false, popOnHover = false}) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleClick: MouseEventHandler<HTMLButtonElement> = async (event) => {
        if (!disabled && !isLoading) {
            setIsLoading(true);
            try {
                await onClick(event);
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <button
            onClick={handleClick}
            className={`
                text-sm font-bold uppercase px-4 py-2.5 rounded-full shadow-lg transition-all duration-500 ease-in-out
                ${className} 
                ${disabled || isLoading ? 'bg-gray-200 text-gray-400 cursor-not-allowed' : 'bg-gradient-to-r from-coral-pink to-coral-orange text-white'}
                ${popOnHover ? 'hover:shadow-2xl transform hover:scale-105' : ''}
                flex items-center justify-center
            `}
            disabled={disabled || isLoading}
        >
            {isLoading ? (
                <Spinner />
            ) : (
                <span className="pointer-events-none">{children}</span>
            )}
        </button>
    );
};

export default CoralButton;