import { RefObject, useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useOutsideAlerter(ref: RefObject<any>, action: () => void) {
  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (ref.current && !ref.current.contains(event.target)) action();
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [ref, action]);
}