import React, { useState } from 'react';
import { UserIdentityVerification } from 'shared/types/platformTypes';
import CoralButton from '../../components/CoralButtons/CoralButton';
import { createUserIdentityVerification } from '../../api/api';
import { useHistory } from 'react-router-dom';
import { trackEvent, EventName } from 'shared/lib/eventTracking';
import { useAuthContext } from '../../components/Auth/AuthContext/AuthContext';

const UserIdentityVerificationForm: React.FC = () => {
    const { userProfile } = useAuthContext();
    const [identityVerification, setIdentityVerification] = useState<UserIdentityVerification & { legalName: string }>({
        userId: '',
        governmentIdNumber: '',
        governmentIdType: '',
        verificationStatus: 'pending',
        verificationId: '',
        createdAt: '',
        lastUpdatedAt: '',
        dateOfBirth: '',
        legalName: '', 
    });
    const [verificationResult, setVerificationResult] = useState<{verificationId: string; status: string;} | null>(null);
    const history = useHistory();

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = event.target;
        setIdentityVerification(prevIdentityVerification => ({
            ...prevIdentityVerification,
            [name]: value,
        }));
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        try {
            const result = await createUserIdentityVerification(identityVerification);
            trackEvent(EventName.IDENTIFICATION_SUBMITTED, {
                userId: userProfile!.userId,
            });
            setVerificationResult(result);
            setIdentityVerification({
                userId: '',
                governmentIdNumber: '',
                governmentIdType: '',
                verificationStatus: 'pending',
                verificationId: '',
                createdAt: '',
                lastUpdatedAt: '',
                dateOfBirth: '',
                legalName: '',
            });
        } catch (error) {
            console.error('Error creating identity verification', error);
        }
    };

    const handleReturnToDashboard = () => {
        history.push('/dashboard');
    };

    React.useEffect(() => {
        trackEvent(EventName.IDENTIFICATION_PAGE_VIEW, {
            userId: userProfile!.userId,
        });
    }, []);

    return (
        <div className="flex flex-col justify-center items-center min-h-screen px-4">
            <div className="w-full max-w-xl bg-white p-8 rounded-lg shadow-lg">
                <h2 className="font-nunito text-xl md:text-2xl font-extrabold mb-4">
                    Identity Verification
                </h2>
                {!verificationResult ? (
                    <>
                        <p className="mb-6">
                            To ensure the security of your account and prevent fraudulent claims, we require identity verification. Please provide the following information:
                        </p>
                        <form onSubmit={handleSubmit} className="space-y-6">
                            <div>
                                <label htmlFor="legalName" className="block text-sm font-medium text-gray-700 mb-2">Legal Name</label>
                                <input
                                    id="legalName"
                                    name="legalName"
                                    type="text"
                                    required
                                    className="w-full p-2 text-base border border-gray-300 rounded-md shadow-sm"
                                    placeholder="Enter your legal name"
                                    value={identityVerification.legalName}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label htmlFor="governmentIdNumber" className="block text-sm font-medium text-gray-700 mb-2">Government ID Number</label>
                                <input
                                    id="governmentIdNumber"
                                    name="governmentIdNumber"
                                    type="text"
                                    required
                                    className="w-full p-2 text-base border border-gray-300 rounded-md shadow-sm"
                                    placeholder="Enter government ID number"
                                    value={identityVerification.governmentIdNumber}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label htmlFor="governmentIdType" className="block text-sm font-medium text-gray-700 mb-2">Government ID Type</label>
                                <select
                                    id="governmentIdType"
                                    name="governmentIdType"
                                    required
                                    className="w-full p-2 text-base border border-gray-300 rounded-md shadow-sm"
                                    value={identityVerification.governmentIdType}
                                    onChange={handleInputChange}
                                >
                                    <option value="">Select ID type</option>
                                    <option value="passport">Passport</option>
                                    <option value="drivingLicense">Driving License</option>
                                    <option value="nationalId">National ID</option>
                                </select>
                            </div>
                            <div>
                                <label htmlFor="dateOfBirth" className="block text-sm font-medium text-gray-700 mb-2">Date of Birth</label>
                                <input
                                    id="dateOfBirth"
                                    name="dateOfBirth"
                                    type="date"
                                    required
                                    className="w-full p-2 text-base border border-gray-300 rounded-md shadow-sm"
                                    value={identityVerification.dateOfBirth}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <p className="text-sm text-gray-600 mb-2">
                                    Please note: You may be contacted via email to provide evidence of your government ID and a portrait photo for verification purposes.
                                </p>
                            </div>
                            <div className="flex justify-between">
                                <CoralButton className="font-semibold text-xs sm:text-sm" onClick={handleReturnToDashboard}>Return to Dashboard</CoralButton>
                                <CoralButton className="font-semibold text-xs sm:text-sm" onClick={handleSubmit}>Submit</CoralButton>
                            </div>
                        </form>
                    </>
                ) : (
                    <>
                        <p className="mb-6">
                            Thank you for submitting your identity verification details. We appreciate your cooperation in keeping our platform secure. We&apos;ll be in touch via email for the next steps.
                        </p>
                        <div className="mb-6">
                            <p>Verification ID: {verificationResult.verificationId}</p>
                            <p>Status: {verificationResult.status}</p>
                        </div>
                        <CoralButton className="font-semibold text-xs sm:text-sm" onClick={handleReturnToDashboard}>Return to Dashboard</CoralButton>
                    </>
                )}
            </div>
        </div>
    );
};

export default UserIdentityVerificationForm;