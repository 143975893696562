import React from 'react';
import { AccountSettings } from '@aws-amplify/ui-react';

const Security: React.FC = () => {
    const handleSuccess = () => {
        alert('Password is successfully changed!');
    }

    return (
        <div className="bg-white shadow overflow-hidden sm:rounded-lg p-4">
            <div className="py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-nunito font-extrabold text-coral-black">Security</h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">Change Password</p>
            </div>
            <div className="border-t border-gray-200">
                <AccountSettings.ChangePassword onSuccess={handleSuccess} />
            </div>
        </div>
    );
};

export default Security;