import { fetchAuthSession, JWT } from '@aws-amplify/auth';

interface CognitoTokens {
    idToken: JWT;
    accessToken: JWT;
}

export async function getCognitoTokens(): Promise<CognitoTokens | undefined> {
    try {
        const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
        if (!(accessToken && idToken)) {
            throw new Error('Could not retrieve accessToken or idToken');
        } 

        return {
            idToken: idToken,
            accessToken: accessToken,
        };
    } catch (err) {
        console.log(err);
        return undefined;
    }
}

export function generateRandomString(length: number) {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
}