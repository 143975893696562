"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CoralSubscriptionStatus = exports.SourceType = exports.Source = exports.PublicProfileType = exports.CompanyNumberType = exports.CoralType = exports.ArtistPoolType = void 0;
;
// Artist Pool Types
/**
* ArtistPoolType represents the type of an artist pool.
* It can be either 'fixed', 'dynamic', 'blended', or 'shared'.
*/
var ArtistPoolType;
(function (ArtistPoolType) {
    ArtistPoolType["FIXED"] = "fixed";
    ArtistPoolType["DYNAMIC"] = "dynamic";
    ArtistPoolType["BLENDED"] = "blended";
    ArtistPoolType["SHARED"] = "shared";
})(ArtistPoolType = exports.ArtistPoolType || (exports.ArtistPoolType = {}));
// Coral Types
/**
* CoralType represents the type of a coral.

* Corals can be of different types:
* - Shared: An existing base Coral that can added to a user's library
* - User: A Coral that is created manually by a user adding artists
*/
var CoralType;
(function (CoralType) {
    CoralType["SHARED"] = "shared";
    CoralType["USER"] = "userManual";
    CoralType["PLATFORM"] = "platform";
})(CoralType = exports.CoralType || (exports.CoralType = {}));
/**
* ArtistApplication represents an artist's application to the platform.
* It contains all the necessary information for the application process.
*/
// Define enums for company number types and public profile types
var CompanyNumberType;
(function (CompanyNumberType) {
    CompanyNumberType["ABN"] = "ABN";
    CompanyNumberType["ACN"] = "ACN";
    CompanyNumberType["EIN"] = "EIN";
    CompanyNumberType["CRN"] = "CRN";
    CompanyNumberType["NZBN"] = "NZBN";
    CompanyNumberType["BN"] = "BN";
    CompanyNumberType["OTHER"] = "OTHER";
    // Add more types as needed
})(CompanyNumberType = exports.CompanyNumberType || (exports.CompanyNumberType = {}));
var PublicProfileType;
(function (PublicProfileType) {
    PublicProfileType["INSTAGRAM"] = "instagram";
    PublicProfileType["TIKTOK"] = "tiktok";
    PublicProfileType["FACEBOOK"] = "facebook";
    PublicProfileType["TWITTER"] = "twitter";
    PublicProfileType["YOUTUBE"] = "youtube";
    PublicProfileType["WEBSITE"] = "website";
    // Add more types as needed
})(PublicProfileType = exports.PublicProfileType || (exports.PublicProfileType = {}));
// Enums
/**
* Source represents the source of the artist pool.
* It can be 'Spotify' or any other source you want to add in the future.
*/
var Source;
(function (Source) {
    Source["UNDEFINED"] = "Undefined";
    Source["USER"] = "User";
    Source["SHARED"] = "Shared";
    // Add other sources here
})(Source = exports.Source || (exports.Source = {}));
/**
* SourceType represents the type of the source of the artist pool.
* It can be 'FavouriteArtists', 'ArtistsOnRotation', or 'ArtistsFollowed'.
*/
var SourceType;
(function (SourceType) {
    SourceType["UNDEFINED"] = "Undefined";
    SourceType["USER_ADDED"] = "UserAdded";
    SourceType["USER_CONNECTED_CURATED_CORAL"] = "UserConnectedCuratedCoral";
    // Add other source types here}
})(SourceType = exports.SourceType || (exports.SourceType = {}));
/**
* CoralSubscriptionStatus represents the status of a coral.
* It can be 'Pending', 'Active', 'Paused', or 'Archived'.
*/
var CoralSubscriptionStatus;
(function (CoralSubscriptionStatus) {
    CoralSubscriptionStatus[CoralSubscriptionStatus["NONE"] = 1] = "NONE";
    CoralSubscriptionStatus[CoralSubscriptionStatus["PENDING"] = 2] = "PENDING";
    CoralSubscriptionStatus[CoralSubscriptionStatus["ACTIVE"] = 3] = "ACTIVE";
    CoralSubscriptionStatus[CoralSubscriptionStatus["PAUSED"] = 4] = "PAUSED";
})(CoralSubscriptionStatus = exports.CoralSubscriptionStatus || (exports.CoralSubscriptionStatus = {}));
