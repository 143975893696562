import React, { useEffect, useState } from 'react';
import { CoralSimulationResult } from 'shared/types/platformTypes';
import selectedStar from '../../assets/images/selected-star.png';
import unSelectedStar from '../../assets/images/unselected-star.png';
import { Tooltip } from 'react-tooltip';
import PublicCoralArtistTableOverlayed from './PublicCoralArtistTableOverlayed';
import CoralButton from '../CoralButtons/CoralButton';

interface PublicCoralArtistTableProps {
  coralName: string;
  coralSimulationResults: CoralSimulationResult[];
  simulateCoral: () => void;
  updatePledgeAmount: (amount: number) => void;
  pledgeAmount: number;
  addPinnedArtist: (artistId: string) => void;
  removePinnedArtist: (artistId: string) => void;
  pinnedArtists: string[];
  limit?: number;
}

const PublicCoralArtistTable: React.FC<PublicCoralArtistTableProps> = ({
  coralName,
  coralSimulationResults,
  simulateCoral,
  updatePledgeAmount,
  pledgeAmount,
  addPinnedArtist,
  removePinnedArtist,
  pinnedArtists,
  limit = 5
}) => {
  const [inputValue, setInputValue] = useState('');
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);

  const unmodifiedCoralSimulationResults = coralSimulationResults;
  const modifiedCoralSimulationResults = unmodifiedCoralSimulationResults.filter(result => result.artist_id && result.artist_id.trim() !== '' && result.artist_id !== 'unknown');
  const totalSimulationResults = modifiedCoralSimulationResults.length;
  coralSimulationResults = limit ? modifiedCoralSimulationResults.slice(0, limit) : modifiedCoralSimulationResults;

  useEffect(() => {
    setInputValue(pledgeAmount === 0 ? '' : pledgeAmount.toString());
  }, [pledgeAmount]);

  useEffect(() => {
    if (pledgeAmount > 0) {
      simulateCoral();
    }
  }, [pledgeAmount, pinnedArtists]);

  const handleUpdatePledgeAmount = () => {
    const decimalRegex = /^([1-9]\d*(\.\d{1,2})?)?$/;

    if (inputValue === '') {
      console.log('Textbox is empty');
    } else if (decimalRegex.test(inputValue)) {
      updatePledgeAmount(Number(inputValue));
      console.log('Pledge amount updated');
    } else {
      console.log('Invalid pledge amount');
    }
  };

  return (
    <>
      {isOverlayOpen && (
        <PublicCoralArtistTableOverlayed
          setIsOpen={setIsOverlayOpen}
          coralName={coralName}
          coralSimulationResults={modifiedCoralSimulationResults}
          simulateCoral={simulateCoral}
          updatePledgeAmount={updatePledgeAmount}
          pledgeAmount={pledgeAmount}
          addPinnedArtist={addPinnedArtist}
          removePinnedArtist={removePinnedArtist}
          pinnedArtists={pinnedArtists}
        />
      )}
      <section className="h-fit max-h-[800px] max-w-[540px] w-full border-[1px] border-solid border-[rgba(167,180,222,0.5)] rounded-md bg-transparent-light-blue p-6 flex flex-col">
        <section className="flex flex-col">
          <div className="mb-2">
            <h2 className="font-nunito font-black text-2xl">{coralName}</h2>
          </div>
          <p className="text-primary-100 mb-4">
            Adjust the monthly pledge below to explore the dynamics of corals.
            Star your favorite artists to customise your impact. &lsquo;Our Impact&rsquo; represents 5k corals like yours.
          </p>
        </section>
        <section className="flex-grow overflow-auto mb-3">
          <table className="w-full mb-3">
            <thead className="text-xs">
              <tr>
                <th className="px-0 py-3 text-right align-bottom"></th>
                <th className="px-0 py-3 text-left align-bottom">Artist</th>
                <th className="px-0 sm:px-6 py-3 text-right align-bottom">Your<br />Impact</th>
                <th className="px-1 py-3 text-right align-bottom bg-gradient-to-r from-accent-400 via-accent-200 to-accent-800 text-transparent bg-clip-text">
                  <span data-tooltip-id="ourImpactTooltip" data-tooltip-content="Our collective impact if there were just 5000 corals like yours.">
                    Our<br />Impact
                  </span>
                  <Tooltip id="ourImpactTooltip"/>
                </th>
              </tr>
            </thead>
            <tbody className="text-sm">
              {coralSimulationResults.map((allocation, index) => (
                <tr key={index} className="border-b">
                  <td className="px-0 py-4 whitespace-nowrap">
                    {allocation.included && (
                      <img
                        className="transition-transform duration-500 ease-in-out transform hover:scale-110 cursor-pointer"
                        src={pinnedArtists.includes(allocation.artist_id) ? selectedStar : unSelectedStar}
                        alt="Star"
                        width="20"
                        height="20"
                        onClick={() => {
                          if (pinnedArtists.includes(allocation.artist_id)) {
                            removePinnedArtist(allocation.artist_id);
                          } else if (pinnedArtists.length < 2) {
                            addPinnedArtist(allocation.artist_id);
                          }
                        }}
                      />
                    )}
                  </td>
                  <td className="px-3 py-4 whitespace-nowrap overflow-hidden overflow-ellipsis max-w-28 sm:whitespace-normal sm:overflow-visible sm:max-w-full text-lg font-bold font-nunito text-primary-100">
                    {allocation.artist_name}
                  </td>
                  <td className="px-0 sm:px-6 py-4 whitespace-nowrap text-right text-primary-200">${allocation.allocated_amount.toFixed(2)}</td>
                  <td className="px-1 py-4 whitespace-nowrap text-right bg-gradient-to-r from-accent-400 via-accent-200 to-accent-800 text-transparent bg-clip-text">${allocation.impact_5000_users.toFixed(0)}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {limit && (totalSimulationResults - limit > 0) && (
            <button onClick={() => setIsOverlayOpen(true)}>
              <small className="text-xs">And {totalSimulationResults - limit} more...</small>
            </button>
          )}
        </section>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            handleUpdatePledgeAmount();
          }}
          className="flex flex-col items-end content-end gap-4"
        >
          <section className="flex items-center content-between gap-3">
            <label className="flex items-center">
              <span className="mr-2">$</span>
              <input
                type="text"
                inputMode="numeric"
                name="pledge-amount"
                id="pledge-amount"
                value={inputValue}
                onChange={(event) => setInputValue(event.target.value)}
                className="max-w-[4rem] border-2 border-primary-100 bg-white text-primary-800 rounded px-5 py-3 text-base focus:border-accent-300 focus:[outline:none!important] transition-all ease-in-out duration-200"
                placeholder="AUD"
              />
            </label>
            <CoralButton onClick={handleUpdatePledgeAmount} className="px-5 py-3">
              PREVIEW
            </CoralButton>
          </section>
          <small className="text-primary-200 text-xs">Preview your impact with a monthly pledge you set.</small>
        </form>
      </section>
    </>
  );
};

export default PublicCoralArtistTable;