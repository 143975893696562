import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { ArtistSearchResult, ClientArtistListItem, Source } from 'shared/types/platformTypes';
import useCoralEditor from '../../hooks/useCoralEditor';
import { useAuthContext } from '../Auth/AuthContext/AuthContext';
import { trackEvent, EventName } from 'shared/lib/eventTracking';
import ArtistSearch from '../../components/ArtistSearch/ArtistSearch';
import CoralButton from '../../components/CoralButtons/CoralButton';
import Spinner from '../../components/Spinner/Spinner';
import { Tooltip } from 'react-tooltip';
import { ChevronDown, ChevronUp, Edit2, ArrowUp } from 'lucide-react';
import selectedStar from '../../assets/images/selected-star.png';
import unSelectedStar from '../../assets/images/unselected-star.png';
import disabledIcon from '../../assets/images/disabled-icon.png';
import plusIcon from '../../assets/images/create-coral/add-artist.png';
import addedIcon from '../../assets/images/create-coral/added-artist.png';
import CoralVisualisation from '../../components/CoralVisualisation/CoralVisualisation';
import { useHistory } from 'react-router-dom';

interface AllInOneCoralCreatorProps {
  artistId?: string;
  transparent?: boolean;
}

const AllInOneCoralCreator: React.FC<AllInOneCoralCreatorProps> = ({ artistId, transparent = false }) => {
  const { userProfile } = useAuthContext();
  const {
    coralData,
    addArtistDirectly,
    removeArtist,
    updatePledgeAmount,
    saveCoral,
    simulateCoral,
    simulationResults,
    addPinnedArtist,
    removePinnedArtist,
  } = useCoralEditor('', false, artistId);

  const [pledgeValue, setPledgeValue] = useState('');
  const [isEditingPledge, setIsEditingPledge] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');
  const [isAddingArtist, setIsAddingArtist] = useState(false);
  const [isFeesExpanded, setIsFeesExpanded] = useState(false);

  const history = useHistory();

  const toggleFees = () => {
    setIsFeesExpanded(!isFeesExpanded);
    trackEvent(
      isFeesExpanded ? EventName.CORAL_FEES_COLLAPSED : EventName.CORAL_FEES_EXPANDED,
      { userId: userProfile?.userId }
    );
  };

  const totalFees = useMemo(() => {
    return simulationResults.platformFee + simulationResults.transactionFee;
  }, [simulationResults.platformFee, simulationResults.transactionFee]);

  const selectedArtists = coralData?.artistPool?.artistList.filter(artist =>
    artist.selected || artist.source === Source.USER
  ) || [];

  

  useEffect(() => {
    if (coralData) {
      setPledgeValue(coralData.pledgeAmount.toString());
    }
  }, [coralData]);

  useEffect(() => {
    if (coralData && coralData.pledgeAmount > 0) {
      simulateCoral();
    }
  }, [coralData?.pledgeAmount, simulateCoral]);

  const handleUpdatePledgeAmount = useCallback(async () => {
    const decimalRegex = /^([1-9]\d*(\.\d{1,2})?)?$/;

    if (decimalRegex.test(pledgeValue)) {
      const amount = Number(pledgeValue);
      if (amount < 1 || amount > 70) {
        setValidationMessage('Amount must be between $1 and $70');
      } else {
        setValidationMessage('');
        setIsEditingPledge(false);

        const oldAmount = coralData?.pledgeAmount || 0;
        await updatePledgeAmount(amount);

        trackEvent(EventName.CORAL_PLEDGE_AMOUNT_CHANGED, {
          userId: userProfile?.userId,
          oldAmount,
          newAmount: amount,
        });
      }
    } else {
      setValidationMessage('Invalid pledge amount');
    }
  }, [pledgeValue, updatePledgeAmount, userProfile, coralData]);

  const [isResponsiveLayout, setIsResponsiveLayout] = useState(false);

  const shouldUseResponsiveLayout = useCallback(() => {
    if (selectedArtists.length >= 3) return true;
    return selectedArtists.some(artist => artist.artistName && artist.artistName.length > 11);
  }, [selectedArtists]);

  useEffect(() => {
    setIsResponsiveLayout(shouldUseResponsiveLayout());
  }, [shouldUseResponsiveLayout]);

  const handleAddArtist = useCallback(async (artistName: string, artistGuid?: string) => {
    if (artistName.trim()) {
      try {
        await addArtistDirectly(artistName.trim(), artistGuid);
        setIsAddingArtist(false);
        trackEvent(EventName.CORAL_ARTIST_ADDED, {
          userId: userProfile?.userId,
          artistName: artistName.trim(),
          artistId: artistGuid,
          totalArtists: selectedArtists.length + 1,
        });
      } catch (error) {
        console.error('Error adding artist:', error);
      }
    }
  }, [addArtistDirectly, userProfile, selectedArtists.length]);

  const handleSelectArtist = useCallback((artist: ArtistSearchResult) => {
    handleAddArtist(artist.name, artist.guid);
  }, [handleAddArtist]);

  const handleAddToEcosystem = useCallback(async () => {
    try {
      const savedCoralGuid = await saveCoral();
      trackEvent(EventName.CORAL_GOTO_PAYMENT, {
        userId: userProfile?.userId,
        coralId: savedCoralGuid!,
        pledgeAmount: coralData!.pledgeAmount,
        totalArtists: selectedArtists.length,
      });
      history.push(`/payment/${savedCoralGuid}?amt=${coralData?.pledgeAmount}`);
    } catch (error) {
      console.error('Error saving coral:', error);
    }
  }, [saveCoral, userProfile, coralData, selectedArtists.length, history]);

  const renderArtistStatus = useCallback((artist: ClientArtistListItem) => {
    if (artist.artistListItemStatus === 'pending') {
      return (
        <button data-tooltip-id="searchingTooltip" data-tooltip-content="We're searching for this artist in our database. Try a different spelling if nothing returns.">
          <Spinner className="w-5 h-5 animate-pulse" />
          <Tooltip id="searchingTooltip" />
        </button>
      );
    } else if (artist.artistListItemStatus === 'not found') {
      return <img src={plusIcon} alt="Add artist" className="w-5 h-5" />;
    } else if (artist.artistListItemActive === false) {
      return (
        <button data-tooltip-id="disabledIconTooltip" data-tooltip-content={
          artist.artistListItemStatus === 'top-artist'
            ? "This artist has reached high levels of commercial success and is not eligible for wearecoral."
            : artist.artistListItemStatus === "deceased"
              ? "wearecoral is for living artists as it's about supporting a thriving ecosystem."
              : "This artist is not eligible for wearecoral."
        }>
          <img src={disabledIcon} alt="Artist is disabled" className="w-4 h-4" />
          <Tooltip id="disabledIconTooltip" />
        </button>
      );
    } else {
      return <img src={addedIcon} alt="Artist added" className="w-5 h-5" />;
    }
  }, []);

  const handleRemoveArtist = useCallback((artistName: string) => {
    if (window.confirm("Are you sure you want to remove this artist?")) {
      removeArtist(artistName);
      trackEvent(EventName.CORAL_ARTIST_REMOVED, {
        userId: userProfile?.userId,
        artistName,
        totalArtists: selectedArtists.length - 1,
      });
    }
  }, [removeArtist, userProfile, selectedArtists.length]);

  const handleTogglePinArtist = useCallback((artistId: string) => {
    const artist = selectedArtists.find(a => a.artistId === artistId);
    if (coralData && coralData?.pinnedArtists.includes(artistId)) {
      removePinnedArtist(artistId);
      if (artist) {
        trackEvent(EventName.CORAL_ARTIST_UNPINNED, {
          userId: userProfile?.userId,
          artistName: artist.artistName!,
          artistId,
        });
      }
    } else if (coralData && coralData.pinnedArtists && coralData?.pinnedArtists.length < 2) {
      addPinnedArtist(artistId);
      if (artist) {
        trackEvent(EventName.CORAL_ARTIST_PINNED, {
          userId: userProfile?.userId,
          artistName: artist.artistName!,
          artistId,
        });
      }
    }
  }, [coralData, addPinnedArtist, removePinnedArtist, userProfile, selectedArtists]);

  // Prepare data for CoralVisualisation
  const coralVisualisationData = useMemo(() => {
    if (!coralData || !simulationResults || coralData.pledgeAmount === 0 || (coralData.artistPool.artistList ?? []).length === 0) return null;

    const genreData = coralData.artistPool?.artistList.reduce((acc, artist) => {
      const genre = artist.artistData?.primaryGenre; // Use primaryGenre instead
      if (genre) {
        const existingGenre = acc.find(g => g.name === genre);
        if (existingGenre) {
          existingGenre.amount += simulationResults.artistAllocations.find(a => a.artist_id === artist.artistId)?.allocated_amount || 0;
        } else {
          acc.push({
            name: genre,
            amount: simulationResults.artistAllocations.find(a => a.artist_id === artist.artistId)?.allocated_amount || 0
          });
        }
      }
      return acc;
    }, [] as { name: string, amount: number }[]);

    if (!genreData || genreData.length === 0) return null;

    return {
      transactionId: coralData.guid,
      totalAmount: coralData.pledgeAmount,
      genres: genreData || [],
      artists: simulationResults.artistAllocations
        .filter(a => a.allocated_amount > 0)
        .map(a => ({
          name: a.artist_name,
          amount: a.allocated_amount
        }))
    };
  }, [coralData, simulationResults]);

  // Update the component's main container className based on the transparent prop
  const containerClassName = transparent
  ? "max-w-2xl min-w-xl mx-auto bg-white bg-opacity-10 backdrop-blur-md p-4 py-6 rounded-lg shadow-lg text-white"
  : "max-w-2xl min-w-xl mx-auto p-6 bg-white rounded-lg shadow-lg text-black";

  // Helper function to get text color based on transparency
  const getTextColor = (defaultColor: string, transparentColor: string) => {
    return transparent ? transparentColor : defaultColor;
  };

  return (
    <div className={containerClassName}>
      <div className={`flex flex-col ${!isResponsiveLayout && coralVisualisationData ? 'lg:flex-row' : ''}`}>
        {/* Left Section: Artists in Your Coral */}
        <div className={`w-full ${!isResponsiveLayout && coralVisualisationData ? 'lg:w-1/2 lg:pr-4' : ''} mb-8`}>
          <h3 className="font-nunito text-xl font-bold mb-2 bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">Build Your Coral</h3>
          <p className={`font-nunito text-base mb-4 ${getTextColor('text-gray-400', 'text-gray-300')}`}>Your personal collection of artists you back</p>
          <h4 className={`font-nunito text-sm font-bold uppercase ${getTextColor('text-gray-400', 'text-gray-300')}`}>Artists</h4>
          <div className="mb-4">
            {selectedArtists.map((artist, index) => (
              <div key={index} className={`flex items-start justify-between py-2 border-b ${getTextColor('border-gray-200', 'border-gray-600')}`}>
                <div className="flex items-start flex-grow mr-2 min-w-0">
                  <img
                    src={coralData?.pinnedArtists.includes(artist.artistId!) ? selectedStar : unSelectedStar}
                    alt="Pin artist"
                    className="w-6 h-6 cursor-pointer mr-4 flex-shrink-0"
                    onClick={() => handleTogglePinArtist(artist.artistId!)}
                  />
                  <span
                    className={`font-medium cursor-pointer break-words ${getTextColor('text-gray-900', 'text-white')}`}
                    onClick={() => handleRemoveArtist(artist.artistName!)}
                  >
                    {artist.artistName}
                  </span>
                </div>
                <div className="flex items-center flex-shrink-0 w-24 justify-end">
                  <span className={`mr-2 text-sm whitespace-nowrap ${getTextColor('text-gray-600', 'text-gray-300')}`}>
                    ${simulationResults.artistAllocations.find(a => a.artist_id === artist.artistId)?.allocated_amount.toFixed(2) || '0.00'}
                  </span>
                  <div className="w-5 h-5 flex-shrink-0">
                    {renderArtistStatus(artist)}
                  </div>
                </div>
              </div>
            ))}
          </div>
          {isAddingArtist ? (
            <ArtistSearch
              onSelect={handleSelectArtist}
              onSubmit={handleAddArtist}
              placeholder="Enter artist name"
            />
          ) : (
            <button
              onClick={() => setIsAddingArtist(true)}
              className="text-coral-orange hover:text-coral-pink-dark transition-colors duration-300"
            >
              + Add more artists
            </button>
          )}
        </div>

        {/* Right Section: Visualize Your Coral */}
        {!isResponsiveLayout && coralVisualisationData && (
          <div className="w-full lg:w-1/2 lg:pl-4">
            <div className="flex justify-center items-center w-full pt-5">
              <CoralVisualisation coral={coralVisualisationData} chartOnly={true} size={180} />
            </div>
          </div>
        )}
      </div>

      {/* Visualization for responsive layout */}
      {isResponsiveLayout && coralVisualisationData && (
        <div className="w-full mb-8">
          <div className="flex justify-center items-center w-full">
            <CoralVisualisation coral={coralVisualisationData} chartOnly={true} size={180} />
          </div>
        </div>
      )}

      {/* Bottom Section: Fees & CTA */}
      <div className={`pt-8 border-t ${getTextColor('border-gray-200', 'border-gray-600')}`}>
        {/* Top Section: Monthly Contribution */}
        <div className="mb-1">
          <div className="flex items-center justify-between">
            <h2 className={`font-nunito text-base font-bold ${getTextColor('text-gray-900', 'text-white')}`}>
              Monthly Contribution <span className={getTextColor('text-gray-600', 'text-gray-300')}>(AUD)</span>
            </h2>
            <div className="flex items-center ml-4">
              <span className={getTextColor('text-gray-600', 'text-gray-300')}>$</span>
              {isEditingPledge ? (
                <input
                  type="text"
                  inputMode="numeric"
                  value={pledgeValue}
                  onChange={(e) => setPledgeValue(e.target.value)}
                  onBlur={handleUpdatePledgeAmount}
                  onKeyPress={(e) => e.key === 'Enter' && handleUpdatePledgeAmount()}
                  className={`w-16 p-1 border rounded-md focus:ring-blue-500 focus:border-blue-500 ${
                    transparent ? 'bg-white bg-opacity-20 text-white border-gray-500' : 'border-gray-300'
                  }`}
                  autoFocus
                  placeholder=""
                />
              ) : (
                <div
                  className={`flex items-center cursor-pointer rounded px-2 py-1 transition-colors duration-200 ${
                    coralData?.pledgeAmount === 0 ? 'text-coral-pink' : getTextColor('text-gray-600', 'text-white')
                  }`}
                  onClick={() => {
                    if (coralData?.pledgeAmount === 0) {
                      setPledgeValue('');
                    } else {
                      setPledgeValue(coralData?.pledgeAmount.toFixed(2) || '');
                    }
                    setIsEditingPledge(true);
                  }}
                >
                  <span className="text-base mr-2">
                    {coralData?.pledgeAmount === 0 ? (
                      <i className="bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange text-sm hidden sm:block">Set amount</i>
                    ) : (
                      coralData?.pledgeAmount.toFixed(2)
                    )}
                  </span>
                  <Edit2 className={coralData?.pledgeAmount === 0 ? 'text-coral-pink animate-pulse' : ''} />
                </div>
              )}
            </div>
          </div>
          {coralData?.pledgeAmount === 0 && (
            <p className={`font-nunito mt-2 text-center text-sm ${getTextColor('text-gray-300', 'text-gray-300')}`}>
              We suggest contributing the price of a single drink at a local gig
            </p>
          )}
          {validationMessage && (
            <p className="text-red-500 mt-2 text-sm">{validationMessage}</p>
          )}
        </div>

        {/* Fees Section */}
        {coralData && coralData.pledgeAmount > 0 && (
          <div className="mb-6">
            <div
              className="flex items-center justify-between cursor-pointer"
              onClick={toggleFees}
            >
              <h3 className={`text-sm font-medium ${getTextColor('text-gray-400', 'text-gray-300')}`}>Fees</h3>
              <div className="flex items-center">
                <span className={`text-sm mr-4 ${getTextColor('text-gray-600', 'text-gray-300')}`}>${totalFees.toFixed(2)}</span>
                {isFeesExpanded ? (
                  <ChevronUp size={20} className={getTextColor('text-gray-600', 'text-gray-300')} />
                ) : (
                  <ChevronDown size={20} className={getTextColor('text-gray-600', 'text-gray-300')} />
                )}
              </div>
            </div>
            {isFeesExpanded && (
              <div className={`mt-2 space-y-2 ${getTextColor('text-gray-600', 'text-gray-300')}`}>
                <div className="flex items-center justify-between text-sm">
                  <span>Platform fee (incl GST)</span>
                  <span>${simulationResults.platformFee.toFixed(2)}</span>
                </div>
                <div className="flex items-center justify-between text-sm">
                  <span>Payment processing fee</span>
                  <span>${simulationResults.transactionFee.toFixed(2)}</span>
                </div>
              </div>
            )}
          </div>
        )}
        <div className="mt-4">
        <CoralButton
          onClick={handleAddToEcosystem}
          className={`w-full px-6 py-3 text-lg font-semibold relative overflow-hidden ${
            selectedArtists.length === 0 || coralData?.pledgeAmount === 0 ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          disabled={selectedArtists.length === 0 || coralData?.pledgeAmount === 0}
        >
          {selectedArtists.length === 0 ? (
            <span className="flex items-center justify-center">
              <span className="mr-2">Add an artist to start</span>
              <ArrowUp className="w-4 h-4 animate-bounce" />
            </span>
          ) : coralData?.pledgeAmount === 0 ? (
            <span className="flex items-center justify-center">
              <span className="mr-2">Set an amount to continue</span>
              <ArrowUp className="w-4 h-4 animate-bounce" />
            </span>
          ) : (
            'Add Your Coral to the Ecosystem'
          )}
        </CoralButton>
        </div>
      </div>
    </div>
  );
};

export default AllInOneCoralCreator;