import React, { useState, useEffect, useCallback } from 'react';
import { Users } from 'lucide-react';
import { trackEvent, EventName } from 'shared/lib/eventTracking';
import debounce from 'lodash/debounce';

const CollectiveImpactCalculator = () => {
  const [contributionInput, setContributionInput] = useState('5');
  const [contribution, setContribution] = useState(5);
  const artistCount = 8;
  const participantCount = 5000;
  const [totalImpact, setTotalImpact] = useState(0);

  useEffect(() => {
    const impact = contribution * participantCount;
    setTotalImpact(impact);
  }, [contribution]);

  const perArtistImpact = totalImpact / artistCount;

  const debouncedTrackEvent = useCallback(
    debounce((value: number) => {
      if (value > 0) {
        trackEvent(EventName.COLLECTIVE_CALCULATOR_INTERACTION, { contribution: value });
      }
    }, 1250),
    []
  );

  const handleContributionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setContributionInput(value);
    
    const numericValue = value === '' ? 0 : Math.max(0, parseFloat(value));
    setContribution(numericValue);

    debouncedTrackEvent(numericValue);
  };

  return (
    <div className="bg-opacity-10 bg-white p-6 sm:p-10 rounded-lg text-white w-full">
      <div className="mb-2 sm:mb-6">
        <label className="text-xl sm:text-2xl mb-4 block font-semibold font-syne">Your monthly contribution</label>
        <div className="relative">
          <span className="absolute left-4 top-1/2 transform -translate-y-1/2 text-2xl sm:text-3xl text-indigo-900">$</span>
          <input
            type="text"
            value={contributionInput}
            onChange={handleContributionChange}
            className="w-full pl-10 pr-4 py-4 text-indigo-900 rounded-lg text-2xl sm:text-3xl font-bold transition-shadow duration-300 focus:ring-4 focus:ring-coral-pink focus:outline-none"
          />
        </div>
        <p className="text-base sm:text-lg font-normal mt-6 text-gray-300">
          If you add {artistCount} artists to your coral that&apos;s ${contribution > 0 ? (contribution / artistCount).toFixed(2) : '0.00'} per artist.
        </p>
      </div>
      
      <div className="flex items-center space-x-4 mb-6">
        <Users className="text-coral-pink w-8 h-8 sm:w-10 sm:h-10" />
        <span className="text-base sm:text-lg font-normal text-gray-300">But if only {participantCount.toLocaleString()} people contribute like you, that&apos;s...</span>
      </div>
      <div className="mb-2 transition-all duration-300 ease-in-out transform hover:scale-102">
        <span className="text-lg sm:text-3xl font-bold text-coral-pink">${perArtistImpact.toFixed(2)}</span>
        <span className="text-base sm:text-3xl ml-2 font-syne">per artist, each month</span>
      </div>
      <p className="text-sm sm:text-lg text-gray-300"><em>Notice how quickly our impact scales even with small amounts.</em></p>
    </div>
  );
};

export default CollectiveImpactCalculator;