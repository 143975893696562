import React, { useState, useEffect, useCallback } from 'react';
import CoralChart from '../CoralChart/CoralChart';
import { Loader } from 'lucide-react';
import { trackEvent, EventName } from 'shared/lib/eventTracking';
import GenericCoralModal from '../GenericCoralModal/GenericCoralModal';

interface GenreData {
  name: string;
  amount: number;
}

const CoralEcosystem: React.FC = () => {
  const [data, setData] = useState<GenreData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      const stage = process.env.REACT_APP_ENVIRONMENT || 'dev';
      const url = `https://wearecoral-public-data-${stage}.s3.ap-southeast-2.amazonaws.com/wearecoral-contribution-report.csv`;
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const text = await response.text();
      const rows = text.split('\n').slice(1); // Skip header
      const genreMap = new Map<string, number>();

      // Calculate the date 30 days ago
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

      rows.forEach(row => {
        const [dateStr, , amount, , , category] = row.split(',');
        const rowDate = new Date(dateStr.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3'));
        
        if (rowDate >= thirtyDaysAgo && category && (category !== 'Fees' && category !== 'Unknown')) {
          const currentAmount = genreMap.get(category) || 0;
          genreMap.set(category, currentAmount + Number(amount));
        }
      });

      const genreData = Array.from(genreMap, ([name, amount]) => ({ name, amount }));
      setData(genreData);
      setIsLoading(false);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError('Failed to load ecosystem data');
      setIsLoading(false);
    }
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
    trackEvent(EventName.VIEW_ALL_CONTRIBUTIONS_INTERACTION, {});
  };

  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <Loader className="w-8 h-8 text-coral-pink animate-spin" />
      </div>
    );
  }

  if (error) {
    return <div className="text-coral-pink font-semibold text-center">{error}</div>;
  }

  return (
    <div className="bg-white bg-opacity-100 shadow-lg rounded-xl p-6 m-3 max-w-4xl mx-auto">
      <div className="flex flex-col items-center">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 items-center w-full">
          <div className="text-center sm:text-left">
            <h2 className="font-syne text-2xl sm:text-2xl lg:text-3xl xl:text-4xl font-semibold text-gray-800 mb-2 sm:mb-4">The Coral Ecosystem</h2>
            <p className="text-base sm:text-lg text-gray-600">
              Each coral contributes to create a thriving, diverse music ecosystem.
            </p>
          </div>
          <div className="w-full aspect-square flex items-center justify-center">
            <CoralChart
              width={Math.min(280, window.innerWidth - 64)}
              height={Math.min(200, window.innerWidth - 64)}
              data={data}
              showAmounts={false}
            />
          </div>
        </div>
        <div className="mt-4 text-center">
          <p className="text-xs text-gray-400">
            Live visualisation of the coral ecosystem.
          </p>
          <button 
            onClick={openModal}
            className="mt-2 text-xs text-coral-pink hover:underline focus:outline-none"
          >
            Download all our data
          </button>
        </div>
      </div>
      <GenericCoralModal isOpen={isModalOpen} onClose={closeModal}>
        <div className="block md:hidden">
            <iframe 
            src="https://cdn.forms-content-1.sg-form.com/a7a097eb-4252-11ef-9b81-4a03a5e66761"
            title="Contributions Form (Mobile)"
            className="w-full h-full min-h-[550px]"
            />
        </div>
        <div className="hidden md:block">
            <iframe 
            src="https://cdn.forms-content-1.sg-form.com/ef7831be-41b0-11ef-86c0-3e26e4945dab"
            title="Contributions Form"
            className="w-full h-full min-h-[550px]"
            />
        </div>
      </GenericCoralModal>
    </div>
  );
};

export default CoralEcosystem;