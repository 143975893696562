import React, { useState, useEffect } from 'react';
import { Copy, Check } from 'lucide-react';
import { ArtistToBePaid, generateArtistVerification, getArtistsToBePaid } from '../../../../api/adminApi';

const TabSelector: React.FC<{
  activeTab: 'verified' | 'unverified';
  onTabChange: (tab: 'verified' | 'unverified') => void;
  verifiedCount: number;
  unverifiedCount: number;
}> = ({ activeTab, onTabChange, verifiedCount, unverifiedCount }) => (
  <div className="flex space-x-4 mb-6 border-b">
    <button
      onClick={() => onTabChange('unverified')}
      className={`py-2 px-4 ${
        activeTab === 'unverified'
          ? 'border-b-2 border-blue-500 text-blue-600'
          : 'text-gray-500 hover:text-gray-700'
      }`}
    >
      Awaiting Verification ({unverifiedCount})
    </button>
    <button
      onClick={() => onTabChange('verified')}
      className={`py-2 px-4 ${
        activeTab === 'verified'
          ? 'border-b-2 border-blue-500 text-blue-600'
          : 'text-gray-500 hover:text-gray-700'
      }`}
    >
      Verified Artists ({verifiedCount})
    </button>
  </div>
);

const ArtistPayments: React.FC = () => {
  const [activeTab, setActiveTab] = useState<'verified' | 'unverified'>('unverified');
  const [artists, setArtists] = useState<ArtistToBePaid[]>([]);
  const [notification, setNotification] = useState<{ type: 'success' | 'error', message: string } | null>(null);
  const [verificationDetails, setVerificationDetails] = useState<{ [key: string]: { code: string, url: string, expiresAt: string } }>({});
  const [copiedStates, setCopiedStates] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    const fetchArtists = async () => {
      try {
        const fetchedArtists = await getArtistsToBePaid();
        setArtists(fetchedArtists.sort((a, b) => b.totalAmount - a.totalAmount));
      } catch (error) {
        console.error('Failed to fetch artists to be paid', error);
        setNotification({ type: 'error', message: 'Failed to fetch artists. Please try again.' });
      }
    };

    fetchArtists();
  }, []);

  const handleGenerateVerification = async (artistId: string) => {
    try {
      const result = await generateArtistVerification(artistId);
      setVerificationDetails({
        ...verificationDetails,
        [artistId]: { code: result.verificationCode, url: result.verificationUrl, expiresAt: result.expiresAt }
      });
      setNotification({ type: 'success', message: result.message });
    } catch (error) {
      console.error('Failed to generate verification', error);
      setNotification({ type: 'error', message: 'Failed to generate verification. Please try again.' });
    }
  };

  const copyToClipboard = async (artistId: string, url: string) => {
    try {
      // Construct the full URL with the new format
      const fullUrl = `${process.env.REACT_APP_HOSTNAME!.replace(/^https:\/\/www\./, 'https://')}/a/${artists.find(a => a.artistId === artistId)?.artistUrl}/collect?verifyUrl=${url}`;
      
      await navigator.clipboard.writeText(fullUrl);
      setCopiedStates(prev => ({ ...prev, [artistId]: true }));
      setTimeout(() => {
        setCopiedStates(prev => ({ ...prev, [artistId]: false }));
      }, 2000);
    } catch (error) {
      setNotification({ type: 'error', message: 'Failed to copy URL to clipboard' });
    }
  };

  const renderVerificationDetails = (artist: ArtistToBePaid) => {
    if (!artist.ownerDetails) return null;
    
    return (
      <div className="mt-4 p-4 bg-green-50 rounded border border-green-200">
        <h3 className="font-semibold mb-2 text-green-700">Verified Artist Details</h3>
        <div className="grid grid-cols-1 gap-2">
          <div>
            <p className="text-sm text-gray-600">Verification Email:</p>
            <p className="font-medium bg-white p-2 rounded">
              {artist.ownerDetails.email}
            </p>
          </div>
          {artist.ownerDetails.paymentPreferences?.paypal && (
            <div>
              <p className="text-sm text-gray-600">PayPal:</p>
              <p className="font-medium bg-white p-2 rounded">
                {artist.ownerDetails.paymentPreferences.paypal}
              </p>
            </div>
          )}
          {artist.ownerDetails.paymentPreferences?.paymentMethod === 'paypal' && (
            <div>
                <p className="text-sm text-gray-600">PayPal Account:</p>
                <p className="font-medium bg-white p-2 rounded">
                    {artist.ownerDetails.paymentPreferences.paymentDetails?.paymentAccount}
                </p>
            </div>
          )}
          {artist.ownerDetails.paymentPreferences?.paymentMethod === 'wise' && (
            <div>
                <p className="text-sm text-gray-600">Wise Account:</p>
                <p className="font-medium bg-white p-2 rounded">
                    {artist.ownerDetails.paymentPreferences.paymentDetails?.paymentAccount}
                </p>
            </div>
          )}
          {artist.ownerDetails.paymentPreferences?.paymentMethod === 'bankAccount' && (
            <div>
                <p className="text-sm text-gray-600">Bank Account:</p>
                <p className="font-medium bg-white p-2 rounded">
                    {artist.ownerDetails.paymentPreferences.paymentDetails?.bankName} - 
                    {artist.ownerDetails.paymentPreferences.paymentDetails?.accountNumber}
                </p>
            </div>
          )}
          <div className="grid grid-cols-2 gap-4">
            <div>
              <p className="text-sm text-gray-600">Verification Method:</p>
              <p className="font-medium bg-white p-2 rounded">
                {artist.ownerDetails.verificationMethod}
              </p>
            </div>
            <div>
              <p className="text-sm text-gray-600">Verified On:</p>
              <p className="font-medium bg-white p-2 rounded">
                {new Date(artist.ownerDetails.verificationTimestamp).toLocaleDateString()}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // Split artists into verified and unverified
  const verifiedArtists = artists.filter(artist => artist.verifiedOwner);
  const unverifiedArtists = artists.filter(artist => !artist.verifiedOwner);

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-6">
        <a 
          href="/admin/home" 
          className="text-indigo-600 hover:text-indigo-800 font-medium"
        >
          ← Back to Admin Home
        </a>
      </div>
      <h1 className="text-3xl font-bold mb-6">Artists To Be Paid</h1>
      
      {notification && (
        <div className={`mb-4 p-4 rounded ${notification.type === 'success' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
          <p>{notification.message}</p>
        </div>
      )}

      <TabSelector
        activeTab={activeTab}
        onTabChange={setActiveTab}
        verifiedCount={verifiedArtists.length}
        unverifiedCount={unverifiedArtists.length}
      />

      <div className="space-y-4">
        {(activeTab === 'verified' ? verifiedArtists : unverifiedArtists).map((artist) => (
          <div key={artist.artistId} className="bg-white shadow rounded-lg overflow-hidden">
            <div className="p-4">
              <div className="flex justify-between items-center mb-4">
                <div>
                  <h2 className="text-xl font-semibold">{artist.name}</h2>
                  <p className="text-lg text-green-600 font-medium">
                    ${artist.totalAmount.toFixed(2)}
                  </p>
                </div>
                {!artist.verifiedOwner && (
                  <button
                    onClick={() => handleGenerateVerification(artist.artistId)}
                    className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
                  >
                    Generate Verification
                  </button>
                )}
              </div>
              
              {artist.verifiedOwner ? (
                renderVerificationDetails(artist)
              ) : (
                verificationDetails[artist.artistId] && (
                  <div className="mt-4 p-4 bg-gray-50 rounded">
                    <h3 className="font-semibold mb-2">Verification Details</h3>
                    <div className="grid grid-cols-1 gap-2">
                      <div>
                        <p className="text-sm text-gray-600">Verification Code:</p>
                        <p className="font-mono bg-gray-100 p-2 rounded">
                          {verificationDetails[artist.artistId].code}
                        </p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-600">Verification URL:</p>
                        <div className="flex items-center">
                          <input
                            type="text"
                            readOnly
                            value={`${process.env.REACT_APP_HOSTNAME!.replace(/^https:\/\/www\./, 'https://')}/a/${artist.artistUrl}/collect?verifyUrl=${verificationDetails[artist.artistId].url}`}
                            className="flex-grow px-3 py-2 bg-gray-100 rounded-l font-mono text-sm"
                          />
                          <button
                            onClick={() => copyToClipboard(artist.artistId, verificationDetails[artist.artistId].url)}
                            className="px-3 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-r"
                          >
                            {copiedStates[artist.artistId] ? <Check size={18} /> : <Copy size={18} />}
                          </button>
                        </div>
                        <p className="mt-1 text-xs text-gray-500">
                          Share this URL with the artist to complete their verification
                        </p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-600">Expires At:</p>
                        <p className="font-mono bg-gray-100 p-2 rounded">
                          {new Date(verificationDetails[artist.artistId].expiresAt).toLocaleString()}
                        </p>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        ))}
        
        {(activeTab === 'verified' ? verifiedArtists : unverifiedArtists).length === 0 && (
          <div className="text-center py-8 text-gray-500">
            {activeTab === 'verified' 
              ? 'No verified artists with pending payments found.'
              : 'No artists awaiting verification found.'}
          </div>
        )}
      </div>
    </div>
  );
};

export default ArtistPayments;
