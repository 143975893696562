import React, { ReactNode, MouseEventHandler, useState } from 'react';
import Spinner from '../Spinner/Spinner';

interface CoralButtonProps {
    children: ReactNode;
    onClick: MouseEventHandler<HTMLButtonElement>;
    className?: string;
    disabled?: boolean;
    popOnHover?: boolean;
}

const SecondaryCoralButton: React.FC<CoralButtonProps> = ({ children, onClick, className = '', disabled = false, popOnHover = false }) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleClick: MouseEventHandler<HTMLButtonElement> = async (event) => {
        if (!disabled && !isLoading) {
            setIsLoading(true);
            try {
                await onClick(event);
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <div className={`inline-block p-0.5 rounded-full ${disabled || isLoading ? 'bg-gray-200' : 'bg-gradient-to-r from-coral-pink to-coral-orange'} ${popOnHover ? 'hover:shadow-lg transform hover:scale-105 transition-all duration-500 ease-in-out' : ''}`}>
            <button
                onClick={handleClick}
                className={`
                    text-sm font-bold uppercase px-4 py-2.5 rounded-full transition duration-300 relative inline-block overflow-hidden
                    ${className} 
                    ${disabled || isLoading ? 'text-gray-400 bg-gray-200 cursor-not-allowed' : 'text-coral-deep-blue bg-white'}
                `}
                disabled={disabled || isLoading}
            >
                <div className="flex items-center justify-center">
                    <span className="pointer-events-none mr-2">{children}</span>
                    {isLoading && <Spinner />}
                </div>
            </button>
        </div>
    );
};

export default SecondaryCoralButton;