import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const RouteChangeTracker = () => {
    const location = useLocation();

    useEffect(() => {
        // Send a pageview to GA4 when the route changes
        // ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
    }, [location]);

    return null; // This component does not render anything
};

export default RouteChangeTracker;
