import React, { useRef, useEffect } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { Download } from 'lucide-react';
import { trackEvent, EventName } from 'shared/lib/eventTracking';

interface QRCodeComponentProps {
  url: string;
  size?: number;
  onGenerate?: (qrCodeDataUrl: string) => void;
}

const QRCodeComponent: React.FC<QRCodeComponentProps> = ({ url, size = 200, onGenerate }) => {
  const qrRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    if (qrRef.current && onGenerate) {
      const svg = qrRef.current;
      const svgData = new XMLSerializer().serializeToString(svg);
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.onload = () => {
        canvas.width = size;
        canvas.height = size;
        ctx?.drawImage(img, 0, 0, size, size);
        const pngFile = canvas.toDataURL("image/png");
        onGenerate(pngFile);
      };
      img.src = "data:image/svg+xml;base64," + btoa(svgData);
    }
  }, [url, size, onGenerate]);

  const downloadQRCode = () => {
    if (qrRef.current) {
      const svg = qrRef.current;
      const svgData = new XMLSerializer().serializeToString(svg);
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.onload = () => {
        canvas.width = size;
        canvas.height = size;
        ctx?.drawImage(img, 0, 0, size, size);
        const pngFile = canvas.toDataURL("image/png");
        const downloadLink = document.createElement("a");
        downloadLink.download = `QR-Code.png`;
        downloadLink.href = pngFile;
        downloadLink.click();
        
        trackEvent(EventName.ARTIST_PROFILE_QR_CODE_DOWNLOADED, {
          artistUrl: url,
        });
      };
      img.src = "data:image/svg+xml;base64," + btoa(svgData);
    }
  };

  return (
    <div className="text-center">
      <QRCodeSVG
        ref={qrRef}
        value={url}
        size={size}
        bgColor={"#ffffff"}
        fgColor={"#000000"}
        level={"L"}
        includeMargin={false}
        className="mx-auto mb-4"
      />
      <button
        onClick={downloadQRCode}
        className="px-4 py-2 bg-gradient-to-r from-coral-pink to-coral-orange text-white rounded-md hover:from-coral-orange hover:to-coral-pink transition-all duration-300 ease-in-out flex items-center justify-center mx-auto"
      >
        <Download size={20} className="mr-2" />
        Download QR Code
      </button>
    </div>
  );
};

export default QRCodeComponent;