// src/index.tsx

import React from 'react';

import './amplifyConfig';
import Routes from './routes';
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <Routes />
  </React.StrictMode>
);
