import React, { useEffect } from 'react';
import aboutHeroImage from '../../assets/images/coral-wide-1.png';
import lightBlueWaveImage from '../../assets/images/light-blue-wave.png';
import JoinCoralButton from '../../components/CoralButtons/JoinCoralButton';
import { trackEvent, EventName } from 'shared/lib/eventTracking';

const Principles: React.FC = () => {
    useEffect(() => {
        trackEvent(EventName.PRINCIPLES_PAGE_VIEW, {});
    }, []);

    return (
        <div className="fade-in-top">
            <div className="max-w-screen-xl mx-auto">
                {/* Page title */}
                <div className="p-16 bg-white w-auto md:w-2/3 text-left">
                    <h1 className="font-nunito text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-coral-black font-extrabold">
                        The Fine Print.
                    </h1>
                </div>
            </div>
            
            {/* Hero image section */}
            <div className="2xl:hidden">
                <img src={aboutHeroImage} alt="Terms Hero" className="aspect-content aspect-center w-full h-auto object-cover" />
            </div>

            <div className="2xl:h-[500px] relative">
                <img src={aboutHeroImage} alt="Terms Hero" className="absolute inset-0 w-full h-full object-cover"/>
            </div>

            {/* Terms and Conditions section */}
            <div className="p-16">
                <div className="max-w-screen-xl mx-auto flex flex-col md:flex-row">
                    {/* Left column for the header */}
                    <div className="md:w-1/2 mb-6 md:mb-0 md:pr-8">
                        <h2 className="text-sm md:text-base font-extrabold">
                            <div className="inline bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
                                PRINCIPLES
                            </div>
                        </h2>
                        <h2 className="font-nunito text-2xl md:text-3xl text-coral-black font-extrabold">
                            What&apos;s Guiding Us
                        </h2>
                    </div>
                    
                    {/* Right column for placeholder text */}
                    <div className="md:w-1/2">
                        <h3 className="text-base sm:text-lg md:text-xl text-coral-black font-bold">
                            Intentional
                        </h3>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black">
                            We&apos;ve designed wearecoral to support the mission and vision of the organisation. Ongoing alignment requires regular discussion and reflection. 
                        </p>

                        <h3 className="text-base sm:text-lg md:text-xl text-coral-black font-bold pt-6">
                            Living System
                        </h3>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black">
                        Like our natural counterpart, wearecoral is designed to be dynamic, continuously evolving in response to signals and feedback. Like a living ecosystem, we intend to calibrate, adjust and modify the platform in response to signal and feedback to ensure we&apos;re fostering a vibrant, thriving music ecosystem in line with our mission.
                        </p>
                        
                        <h3 className="text-base sm:text-lg md:text-xl text-coral-black font-bold pt-6">
                            Collective
                        </h3>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black">
                        We believe in community and collaboration. Our view is that we can create an impactful and enduring support system for music by acting and receiving together.
                        </p>

                        <h3 className="text-base sm:text-lg md:text-xl text-coral-black font-bold pt-6">
                            Fair
                        </h3>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black">
                        Facilitating communities and collective decision making is a complex process. We are committed to fairness, equity and inclusion in any decision-making.
                        </p>

                        <h3 className="text-base sm:text-lg md:text-xl text-coral-black font-bold pt-6">
                            Dialogue
                        </h3>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black">
                        Acting together requires us to be attuned to each other. To listen, make sense and act in concert. We&apos;ll make intentional spaces for those who want to be involved in the conversation.
                        </p>                        

                        <h3 className="text-base sm:text-lg md:text-xl text-coral-black font-bold pt-6">
                            Transparency
                        </h3>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black">
                        As a platform that provides tools and features that helps communities collaborate, it&apos;s critical that wearecoral is trusted as a worthy partner, capable of facilitating fair and enabling infrastructure for the musical ecosystem. To do so we&apos;ll provide transparency across our operations and member contributions.
                        </p>
                    </div>
                </div>
            </div>  

            {/* Keep the blue wave image */}
            <div className="relative w-full h-full">
                <img src={lightBlueWaveImage} alt="Light Blue Wave" className="w-full h-full object-cover" />
                <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gradient-to-t from-coral-light-blue to-transparent"></div>
            </div>

            <div className="p-16 pt-0 bg-coral-light-blue">
                <div className="max-w-screen-xl mx-auto flex flex-col items-center">
                        <div className="w-full mb-4">
                            <h2 className="font-nunito text-2xl md:text-3xl font-extrabold pb-6 text-center">
                                A vibrant, healthy, and diverse ecosystem of music, artists and communities.
                            </h2>
                        </div>
                        <JoinCoralButton />
                </div>
            </div>
        </div>
    );
};

export default Principles;
