// src/components/FAQItem.tsx
import React, { useState } from 'react';
import faqOpenIcon from '../../assets/images/faqOpen.png';
import faqCloseIcon from '../../assets/images/faqClose.png';
import { trackEvent, EventName } from 'shared/lib/eventTracking';

type FAQItemProps = {
  question: string;
  answer: string;
};

const FAQItem: React.FC<FAQItemProps> = ({ question, answer }) => {
  const [isActive, setIsActive] = useState(false);

  const toggleIsActive = () => {
    setIsActive(!isActive);
    // Track the event when the FAQ item is expanded
    if (!isActive) {
      trackEvent(EventName.FAQ_ITEM_VIEWED, { itemQuestion: question });
    }
  };

  return (
    <div className="border-b border-gray-200 py-4">
      <button
        className="flex justify-between items-center w-full text-left"
        onClick={toggleIsActive}
      >
        <span className={`text-base sm:text-base  ${isActive ? 'text-white' : 'text-white'}`}>
          {question}
        </span>
        <img 
          src={isActive ? faqCloseIcon : faqOpenIcon} 
          alt="toggle FAQ" 
          className="w-6 h-6" // adjust these values as needed
        />
      </button>
      {isActive && (
        <div 
          className="mt-2 text-homepage-secondary-font text-sm sm:text-base "
          dangerouslySetInnerHTML={{ __html: answer }}
        />
      )}
    </div>
  );
};

export default FAQItem;