import React, { useState, useEffect } from 'react';
import { ArtistProcessingQueueRecord } from 'shared/types/platformTypes';
import { getMissingArtists, retryMissingArtist } from '../../../../api/adminApi';

const MissingArtistsAdmin: React.FC = () => {
  const [missingArtists, setMissingArtists] = useState<ArtistProcessingQueueRecord[]>([]);
  const [notification, setNotification] = useState<{ type: 'success' | 'error', message: string } | null>(null);

  useEffect(() => {
    const fetchMissingArtists = async () => {
      try {
        const artists = await getMissingArtists();
        setMissingArtists(artists);
      } catch (error) {
        console.error('Failed to fetch missing artists', error);
        setNotification({ type: 'error', message: 'Failed to fetch missing artists. Please try again.' });
      }
    };

    fetchMissingArtists();
  }, []);

  const handleRetryArtist = async (artistName?: string, spotifyUrl?: string) => {
    if (!artistName && !spotifyUrl) {
      console.error('Both artistName and spotifyUrl are undefined');
      return;
    }

    try {
      await retryMissingArtist(artistName, spotifyUrl);
      setMissingArtists(missingArtists.filter(artist => artist.artistName !== artistName));
      setNotification({ type: 'success', message: `Successfully retried processing for ${artistName || spotifyUrl}` });
    } catch (error) {
      console.error('Failed to retry artist', error);
      setNotification({ type: 'error', message: `Failed to retry processing for ${artistName || spotifyUrl}. Please try again.` });
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-6">
        <a 
          href="/admin/home" 
          className="text-indigo-600 hover:text-indigo-800 font-medium"
        >
          ← Back to Admin Home
        </a>
      </div>
      <h1 className="text-3xl font-bold mb-6">Missing Artists Admin</h1>
      {notification && (
        <div className={`mb-4 p-4 rounded ${notification.type === 'success' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
          <p className="font-bold">{notification.type === 'success' ? 'Success' : 'Error'}</p>
          <p>{notification.message}</p>
        </div>
      )}
      <p className="mb-4 text-gray-600">
        List of artists that failed to be processed. You can retry processing them individually.
      </p>
      <div className="space-y-4">
        {missingArtists.map((artist) => (
          <div key={artist.guid} className="bg-white shadow rounded-lg overflow-hidden">
            <div className="p-4">
              <h2 className="text-xl font-semibold">{artist.artistName || 'Unknown Artist'}</h2>
              <p className="text-sm text-gray-500">{artist.spotifyUrl || 'No Spotify URL'}</p>
              <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <h3 className="font-semibold">User ID</h3>
                  <p>{artist.userId}</p>
                </div>
                <div>
                  <h3 className="font-semibold">Created At</h3>
                  <p>{new Date(artist.createdAt).toLocaleString()}</p>
                </div>
              </div>
              <div className="mt-4 flex justify-end">
                <button
                  onClick={() => handleRetryArtist(artist.artistName, artist.spotifyUrl)}
                  className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded flex items-center"
                >
                  <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                  </svg>
                  Retry Processing
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MissingArtistsAdmin;