import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AuthContext } from '../Auth/AuthContext/AuthContext';
import sidebarCoral from '../../assets/images/sidebar/sidebar-coral.png';
import sidebarHome from '../../assets/images/sidebar/sidebar-home.png';
import sidebarImpact from '../../assets/images/sidebar/sidebar-impact.png';
// import sidebarHelp from '../../assets/images/sidebar/sidebar-help.png';
import sidebarSettings from '../../assets/images/sidebar/sidebar-settings.png';

const Sidebar: React.FC<{ className?: string }> = ({ className }) => {
    const history = useHistory();
    const { logout } = useContext(AuthContext);

    async function handleSignOut() {
        try {
            await logout();
            history.push('/');
        } catch (error) {
            console.error('Error signing out: ', error);
        }
    }
    
    return (
        <div className={`bg-[#2D3846] flex flex-row sm:flex-col items-center justify-center sm:py-10 sm:px-4 sm:w-20 ${className}`}>
            <Link to="/dashboard">
                <img src={sidebarCoral} alt="wearecoral logo" className="sm:mb-10 w-10 h-10 mr-4 sm:mr-0" />
            </Link>
            <Link to="/dashboard" className="text-white hover:bg-[#3D4758] transition duration-200 p-2 sm:mb-3">
                <img src={sidebarHome} alt="Home" className="w-6 h-6 sm:mx-auto" />
            </Link>
            <Link to="/impact" className="text-white hover:bg-[#3D4758] transition duration-200 p-2 sm:mb-3">
                <img src={sidebarImpact} alt="Impact" className="w-6 h-6 sm:mx-auto" />
            </Link>
            <div className='ml-auto sm:mt-auto flex flex-row sm:flex-col justify-center items-center space-x-4 sm:space-x-0 sm:space-y-4'>
                {/* <Link to="/help-support" className="text-white hover:bg-[#3D4758] transition duration-200">
                    <img src={sidebarHelp} alt="Help & Support" className="w-6 h-6" />
                </Link> */}
                <Link to="/settings" className="text-white hover:bg-[#3D4758] transition duration-200">
                    <img src={sidebarSettings} alt="Settings" className="w-6 h-6" />
                </Link>
                <button onClick={handleSignOut} className="text-xs text-custom-gray">Logout</button>
            </div>
        </div>
    );
};

export default Sidebar;