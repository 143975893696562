import React, { useState, useEffect, lazy, Suspense } from 'react';
import { ArtistApplication, CompanyNumberType, PublicProfileType } from 'shared/types/platformTypes';
import CoralButton from '../../components/CoralButtons/CoralButton';
import { getUserArtistApplications, createArtistApplication, getUserArtistConnections } from '../../api/api';
import { useHistory } from 'react-router-dom';
import { trackEvent, EventName } from 'shared/lib/eventTracking';
import { useAuthContext } from '../../components/Auth/AuthContext/AuthContext';
import Spinner from '../../components/Spinner/Spinner';

// Lazy load the country selector
const LazyCountrySelector = lazy(() => import('../../components/LazyCountrySelector/LazyCountrySelector'));

// Define the CountryOption type
interface CountryOption {
    value: string;
    label: string;
    flag: string;
  }

const ArtistDetailedVerification: React.FC = () => {
    const { userProfile } = useAuthContext();
    const [applications, setApplications] = useState<{applicationId: string; status: string; artistName: string;}[]>([]);
    const [currentApplication, setCurrentApplication] = useState<ArtistApplication>({
        applicationId: '',
        email: '',
        userId: '',
        artistName: '',
        artistGuid: '',
        legalStructure: '',
        legalEntityName: '',
        companyNumber: '',
        companyNumberType: CompanyNumberType.ABN,
        registeredCountry: '',
        streamingPurchaseLink: '',
        applicationStatus: 'PENDING',
        paymentPreferences: {
            paymentMethod: 'paypal',
            paymentDetails: {},
        },
        publicProfiles: [],
        phoneNumber: '', // Added phone number field
    });
    const [artistConnections, setArtistConnections] = useState<{ artistName: string, artistGuid: string }[]>([]);
    const [country, setCountry] = useState<{ value: string; label: string; flag: string; } | null>(null);
    const history = useHistory();

    useEffect(() => {
        const fetchApplications = async () => {
            try {
                const userApplications = await getUserArtistApplications();
                setApplications(userApplications);
            } catch (error) {
                console.error('Error fetching artist applications', error);
            }
        };

        const fetchUserAndArtistConnections = async () => {
            try {
                const artistConnections = await getUserArtistConnections();
                setArtistConnections(artistConnections || []);
            } catch (error) {
                console.error('Error fetching user and artist connections:', error);
            }
        };

        fetchApplications();
        fetchUserAndArtistConnections();
    }, []);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = event.target;
        setCurrentApplication(prevApplication => ({
            ...prevApplication,
            [name]: value,
        }));
    };

    const handleArtistConnectionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedArtistName = event.target.value;
        const selectedArtist = artistConnections.find(artist => artist.artistName === selectedArtistName);
        if (selectedArtist) {
            setCurrentApplication(prevApplication => ({
                ...prevApplication,
                artistName: selectedArtist.artistName,
                artistGuid: selectedArtist.artistGuid, // Set the artistGuid
            }));
        }
    };

    const handlePaymentAccountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setCurrentApplication(prevApplication => ({
            ...prevApplication,
            paymentPreferences: {
                ...prevApplication.paymentPreferences,
                paymentDetails: {
                    ...(prevApplication.paymentPreferences?.paymentDetails || {}),
                    [name]: value,
                },
            },
        }));
    };

    const handlePaymentMethodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setCurrentApplication(prevApplication => ({
            ...prevApplication,
            paymentPreferences: {
                ...(prevApplication.paymentPreferences || {}),
                [name]: value
            }
        }));
    };

    const handlePublicProfileChange = (index: number, field: 'type' | 'url', value: string) => {
        setCurrentApplication(prevApplication => {
            const updatedProfiles = [...(prevApplication.publicProfiles || [])];
            if (!updatedProfiles[index]) {
                updatedProfiles[index] = { type: PublicProfileType.INSTAGRAM, url: '' };
            }
            updatedProfiles[index] = { ...updatedProfiles[index], [field]: value };
            return { ...prevApplication, publicProfiles: updatedProfiles };
        });
    };

    const addPublicProfile = () => {
        setCurrentApplication(prevApplication => ({
            ...prevApplication,
            publicProfiles: [...(prevApplication.publicProfiles || []), { type: PublicProfileType.INSTAGRAM, url: '' }],
        }));
    };

    const removePublicProfile = (index: number) => {
        setCurrentApplication(prevApplication => ({
            ...prevApplication,
            publicProfiles: prevApplication.publicProfiles?.filter((_, i) => i !== index) || [],
        }));
    };

    const handleCountryChange = (selectedOption: CountryOption | null) => {
        setCountry(selectedOption);
        setCurrentApplication(prevApplication => ({
            ...prevApplication,
            registeredCountry: selectedOption ? selectedOption.label : '',
        }));
    };

    function isKeyOfArtistApplication(key: string, object: ArtistApplication): key is keyof ArtistApplication {
        return key in object;
    }

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        const requiredFields = ['artistName', 'legalStructure', 'legalEntityName', 'companyNumber', 'companyNumberType', 'registeredCountry', 'streamingPurchaseLink'];
        const missingFields = requiredFields.filter(field => {
            if (isKeyOfArtistApplication(field, currentApplication)) {
                return !currentApplication[field];
            }
            return false;
        });

        if (missingFields.length > 0) {
            missingFields.forEach(field => {
                const inputElement = document.getElementById(field);
                if (inputElement && inputElement.parentNode) {
                    inputElement.classList.add('border-red-500');
                    const existingErrorMessage = inputElement.parentNode.querySelector('.error-message');
                    if (!existingErrorMessage) {
                        const errorMessage = document.createElement('p');
                        errorMessage.className = 'text-red-500 text-sm mt-1 error-message';
                        errorMessage.textContent = 'This field is required';
                        inputElement.parentNode.insertBefore(errorMessage, inputElement.nextSibling);
                    }
                }
            });
            return;
        }

        try {
            const newApplication = await createArtistApplication(currentApplication);
            trackEvent(EventName.ARTIST_VERIFICATION_SUBMITTED, {
                userId: userProfile!.userId,
                artistName: currentApplication.artistName,
            });
            setApplications(prevApplications => [...prevApplications, newApplication]);
            setCurrentApplication({
                applicationId: '',
                email: '',
                userId: '',
                artistName: '',
                artistGuid: '',
                phoneNumber: '',
                legalStructure: '',
                legalEntityName: '',
                companyNumber: '',
                companyNumberType: CompanyNumberType.ABN,
                registeredCountry: '',
                streamingPurchaseLink: '',
                paymentPreferences: {
                    paymentMethod: 'paypal',
                    paymentDetails: {},
                },
                applicationStatus: 'PENDING',
                publicProfiles: [],
            });
        } catch (error) {
            console.error('Error creating artist application', error);
        }
        window.scrollTo(0, 0);
    };

    React.useEffect(() => {
        trackEvent(EventName.ARTIST_VERIFICATION_PAGE_VIEW, {
            userId: userProfile!.userId,
        });
    }, []);
    

    return (
        <div className="flex flex-col justify-center items-center min-h-screen px-4 py-8 bg-gray-100">
            <div className="w-full max-w-3xl bg-white p-8 rounded-lg shadow-lg">
                <h1 className="font-nunito text-3xl font-extrabold mb-6 text-center text-gray-800">
                    wearecoral Artist Verification
                </h1>

                <div className="mb-6 bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 rounded">
                    <h2 className="font-bold mb-2">Important Information</h2>
                    <p>
                        The wearecoral team may need to reach out for additional information during the application process. Please ensure all details are accurate and up-to-date. If you have any questions, please contact us at <a href="mailto:hello@wearecoral.org" className="text-blue-600 hover:underline">hello@wearecoral.org</a>.
                    </p>
                </div>

                <div className="mb-8 bg-white border border-gray-200 rounded-lg shadow-sm">
                    <div className="p-4 border-b border-gray-200">
                        <h2 className="text-lg font-semibold">Submitted Applications</h2>
                        <p className="text-sm text-gray-600">Your previous artist verification applications</p>
                    </div>
                    <div className="p-4">
                        {applications.length > 0 ? (
                            applications.map(application => (
                                <div key={application.applicationId} className="bg-gray-50 p-4 mb-4 rounded shadow">
                                    <h3 className="text-lg font-semibold mb-2">Application {application.applicationId.substring(application.applicationId.indexOf('-') + 1)}</h3>
                                    <p className="text-sm mb-1"><span className="font-medium">Artist Name:</span> {application.artistName}</p>
                                    <p className="text-sm"><span className="font-medium">Status:</span> {application.status}</p>
                                </div>
                            ))
                        ) : (
                            <p>No applications have been submitted.</p>
                        )}
                    </div>
                </div>

                <CoralButton
                    onClick={() => history.push('/dashboard')}
                    className="w-full mb-8"
                >
                    Return to Dashboard
                </CoralButton>

                <h2 className="font-nunito text-2xl font-bold mb-6 text-center text-gray-800">
                    New Artist Verification Application
                </h2>

                <form onSubmit={handleSubmit} className="space-y-6">
                    <div className="bg-white border border-gray-200 rounded-lg shadow-sm">
                        <div className="p-4 border-b border-gray-200">
                            <h3 className="text-lg font-semibold">Artist Information</h3>
                            <p className="text-sm text-gray-600">Select the artist you want to verify</p>
                        </div>
                        <div className="p-4 space-y-4">
                            <div>
                                <label htmlFor="artistConnection" className="block text-sm font-medium text-gray-700 mb-1">Artist Connection</label>
                                <select
                                    id="artistConnection"
                                    name="artistConnection"
                                    required
                                    className="w-full p-2 text-base border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                    value={currentApplication.artistName || ''}
                                    onChange={handleArtistConnectionChange}
                                >
                                    <option value="">Select an artist</option>
                                    {artistConnections.map(artist => (
                                        <option key={artist.artistName} value={artist.artistName}>{artist.artistName}</option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700 mb-1">Phone Number (with country code)</label>
                                <input
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    type="tel"
                                    required
                                    className="w-full p-2 text-base border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                    placeholder="e.g. +1 123 456 7890"
                                    value={currentApplication.phoneNumber || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="bg-white border border-gray-200 rounded-lg shadow-sm">
                        <div className="p-4 border-b border-gray-200">
                            <h3 className="text-lg font-semibold">Legal Information</h3>
                            <p className="text-sm text-gray-600">Provide details about your legal entity</p>
                        </div>
                        <div className="p-4 space-y-4">
                            <div>
                                <label htmlFor="legalStructure" className="block text-sm font-medium text-gray-700 mb-1">Legal Structure</label>
                                <select
                                    id="legalStructure"
                                    name="legalStructure"
                                    required
                                    className="w-full p-2 text-base border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                    value={currentApplication.legalStructure || ''}
                                    onChange={handleInputChange}
                                >
                                    <option value="">Select legal structure</option>
                                    <option value="Sole Proprietor">Sole Proprietor/Trader</option>
                                    <option value="Partnership">Partnership</option>
                                    <option value="Company">Company (e.g. Pty Ltd, LLC, Corporation)</option>
                                </select>
                            </div>
                            <div>
                                <label htmlFor="legalEntityName" className="block text-sm font-medium text-gray-700 mb-1">Full Legal Artist Entity Name</label>
                                <input
                                    id="legalEntityName"
                                    name="legalEntityName"
                                    type="text"
                                    required
                                    className="w-full p-2 text-base border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                    placeholder="Enter legal entity name (person or company)"
                                    value={currentApplication.legalEntityName || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label htmlFor="companyNumber" className="block text-sm font-medium text-gray-700 mb-1">Company Number</label>
                                <input
                                    id="companyNumber"
                                    name="companyNumber"
                                    type="text"
                                    required
                                    className="w-full p-2 text-base border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                    placeholder="Enter company number"
                                    value={currentApplication.companyNumber || ''}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div>
                                <label htmlFor="companyNumberType" className="block text-sm font-medium text-gray-700 mb-1">Company Number Type</label>
                                <select
                                    id="companyNumberType"
                                    name="companyNumberType"
                                    required
                                    className="w-full p-2 text-base border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                    value={currentApplication.companyNumberType}
                                    onChange={handleInputChange}
                                >
                                    {Object.values(CompanyNumberType).map((type) => (
                                        <option key={type} value={type}>{type}</option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label htmlFor="registeredCountry" className="block text-sm font-medium text-gray-700 mb-1">Registered Country</label>
                                <Suspense fallback={<Spinner />}>
                                    <LazyCountrySelector
                                        id="registeredCountry"
                                        name="registeredCountry"
                                        value={country}
                                        onChange={handleCountryChange}
                                        placeholder="Select a country..."
                                    />
                                </Suspense>
                            </div>
                        </div>
                    </div>

                    <div className="bg-white border border-gray-200 rounded-lg shadow-sm">
                        <div className="p-4 border-b border-gray-200">
                            <h3 className="text-lg font-semibold">Artist Verification and Authenticity</h3>
                            <p className="text-sm text-gray-600">Help us verify your identity and connection to the artist</p>
                        </div>
                        <div className="p-4 space-y-6">
                            <div className="bg-blue-50 border-l-4 border-blue-500 p-4">
                                <p className="text-sm text-blue-700">
                                    To ensure the integrity of our platform and protect our users, we need to collect detailed information to verify the authenticity of your account. This process helps us confirm your legitimate connection to the artist and safeguard the contributions made by other users.
                                </p>
                            </div>

                            <div>
                                <label htmlFor="streamingPurchaseLink" className="block text-sm font-medium text-gray-700 mb-1">Official Artist Link</label>
                                <p className="text-sm text-gray-500 mb-2">
                                    Provide a link to the artist&apos;s official page on a major music platform (e.g., Spotify, Apple Music, Bandcamp). This helps us establish the artist&apos;s professional nature, and acts as anchor for further verification.
                                </p>
                                <input
                                    id="streamingPurchaseLink"
                                    name="streamingPurchaseLink"
                                    type="url"
                                    required
                                    className="w-full p-2 text-base border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                    placeholder="e.g., https://open.spotify.com/artist/..."
                                    value={currentApplication.streamingPurchaseLink || ''}
                                    onChange={handleInputChange}
                                />
                            </div>

                            <div>
                                <h4 className="text-lg font-semibold mb-2">Verification Process</h4>
                                <p className="text-sm text-gray-600 mb-4">
                                    To confirm your official connection to the artist, wearecoral may use one or more of the following verification methods. Please be prepared to cooperate with our team during this process:
                                </p>
                                <ul className="list-disc list-inside text-sm text-gray-600 mb-4">
                                    <li>Request a direct message from the artist&apos;s official verified social media account</li>
                                    <li>Verify through an official statement from a recognized music rights organization (e.g., APRA AMCOS, BMI, ASCAP) that includes both your name and the artist&apos;s name</li>
                                    <li>Review a recent contract or official document proving your role as the artist&apos;s manager or authorized representative</li>
                                    <li>Conduct a video call with you and/or the artist to confirm identity and relationship</li>
                                    <li>Cross-reference information with publicly available data and official artist websites</li>
                                    <li>Contact the artist&apos;s record label or management company for confirmation</li>
                                </ul>
                                <p className="text-sm text-gray-600">
                                    Our team will guide you through the specific verification steps required for your application after submission.
                                </p>
                            </div>

                            <div>
                                <h4 className="text-lg font-semibold mb-2">Public Artist Profiles</h4>
                                <p className="text-sm text-gray-600 mb-4">
                                    Please provide links to as many of the artist&apos;s official public profiles as possible. It&apos;s especially important to include the official website and Instagram account. These profiles help us verify the authenticity of the artist, and cross-reference the information you&apos;ve provided.
                                </p>
                                {currentApplication.publicProfiles?.map((profile, index) => (
                                    <div key={index} className="mb-4 p-4 bg-gray-50 rounded-md">
                                        <select
                                            className="w-full p-2 text-base border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 mb-2"
                                            value={profile.type}
                                            onChange={(e) => handlePublicProfileChange(index, 'type', e.target.value)}
                                        >
                                            {Object.values(PublicProfileType).map((type) => (
                                                <option key={type} value={type}>{type}</option>
                                            ))}
                                        </select>
                                        <input
                                            type="url"
                                            className="w-full p-2 text-base border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 mb-2"
                                            placeholder="Enter profile URL"
                                            value={profile.url}
                                            onChange={(e) => handlePublicProfileChange(index, 'url', e.target.value)}
                                        />
                                        <button 
                                            type="button" 
                                            onClick={() => removePublicProfile(index)} 
                                            className="text-red-500 hover:text-red-700 transition duration-150 ease-in-out"
                                        >
                                            Remove
                                        </button>
                                    </div>
                                ))}
                                <button 
                                    type="button" 
                                    onClick={addPublicProfile} 
                                    className="mt-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                >
                                    Add Public Profile
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="bg-white border border-gray-200 rounded-lg shadow-sm">
                        <div className="p-4 border-b border-gray-200">
                            <h3 className="text-lg font-semibold">Payment Preferences</h3>
                            <p className="text-sm text-gray-600">Choose your preferred payment method</p>
                        </div>
                        <div className="p-4 space-y-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-2">Payment Method</label>
                                <p className='text-xs font-medium text-gray-500 mb-2'>We recommend PayPal if you expect to receive mostly USD contributions, as it minimises currency conversion fees.</p>
                                <div className="space-y-2 space-x-2">
                                    <label className="inline-flex items-center">
                                        <input
                                            type="radio"
                                            name="paymentMethod"
                                            value="paypal"
                                            checked={currentApplication.paymentPreferences?.paymentMethod === 'paypal'}
                                            onChange={handlePaymentMethodChange}
                                            className="form-radio h-4 w-4 text-blue-600"
                                        />
                                        <span className="ml-2">PayPal</span>
                                    </label>
                                    <label className="inline-flex items-center">
                                        <input
                                            type="radio"
                                            name="paymentMethod"
                                            value="wise"
                                            checked={currentApplication.paymentPreferences?.paymentMethod === 'wise'}
                                            onChange={handlePaymentMethodChange}
                                            className="form-radio h-4 w-4 text-blue-600"
                                        />
                                        <span className="ml-2">Wise</span>
                                    </label>
                                </div>
                            </div>
                            <div>
                                <label htmlFor="paymentAccount" className="block text-sm font-medium text-gray-700 mb-1">Payment Provider Account</label>
                                <input
                                    id="paymentAccount"
                                    name="paymentAccount"
                                    type="text"
                                    required
                                    className="w-full p-2 text-base border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                    placeholder="e.g., PayPal email or Wise account"
                                    value={currentApplication.paymentPreferences?.paymentDetails?.paymentAccount || ''}
                                    onChange={handlePaymentAccountChange}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="bg-white border border-gray-200 rounded-lg shadow-sm">
                        <div className="p-4 border-b border-gray-200">
                            <h3 className="text-lg font-semibold">Next Steps</h3>
                        </div>
                        <div className="p-4">
                            <p className="text-sm text-gray-600">
                                After submitting your application, our team will carefully review it. You can expect a response within 2 weeks. If approved, you&apos;ll receive a welcome email with further instructions on how to make the most of your wearecoral account and start receiving member contributions.
                            </p>
                        </div>
                    </div>

                    <div className="flex justify-center">
                        <CoralButton 
                            className="px-8 py-3 text-lg font-semibold"
                            onClick={handleSubmit}
                        >
                            Submit Application
                        </CoralButton>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ArtistDetailedVerification;