import React, { FC } from 'react';

interface FilterPillProps {
  filterValue: string;
  onClick: () => void;
  selected: boolean;
}

const FilterPill: FC<FilterPillProps> = ({ filterValue, onClick, selected }) => {
  return (
    <div className={`inline-flex p-0.5 rounded-full m-1 bg-gradient-to-r from-coral-pink to-coral-orange transition-all duration-500 ease-in-out transform hover:scale-105`}>
      <button
        onClick={onClick}
        className={`
          inline-flex items-center rounded-full transition duration-300 relative inline-block overflow-hidden
          ${selected ? 'bg-gradient-to-r from-coral-pink to-coral-orange text-white' : 'text-coral-deep-blue bg-white'}
        `}
      >
        <div className={`flex items-center rounded-full px-2 py-1 text-sm`}>
          <span className={`${selected ? 'pl-1' : ''}`}>{filterValue}</span>
          {selected && (
            <span className="bg-transparent rounded-full ml-2 font-extrabold transition-all duration-500 ease-in-out transform hover:scale-105">×</span>
          )}
        </div>
      </button>
    </div>
  );
};

export default FilterPill;