import React from 'react';
import { ClientArtistListItem, SourceType } from 'shared/types/platformTypes';
import SecondaryCoralButton from '../../CoralButtons/SecondaryCoralButton';
import CuratedCoralParent from '../../../components/CuratedCoralModal/CuratedCoralModalParent';

export interface ConfigureConnectionsProps {
  artists: Array<ClientArtistListItem>;
  updateFilter: (filterType: 'genre' | 'location', value: string, action: 'add' | 'remove') => void;
  addArtistFromDynamicArtistPool: (artist: ClientArtistListItem) => void;
  removeArtist: (artistId: string) => void;
  removeArtistsBySourceArtistPoolGuid: (sourceArtistPoolGuid: string) => void;
  dynamicArtistPools: { sourceType: SourceType; sourceArtistPoolGuid: string; sourceArtistPoolCoralName: string }[];
  pageTitle?: string;
  handleAddToCoral: (coralId: string) => void;
  activeArtistPoolGuids: string[];
}

const ConfigureConnections: React.FC<ConfigureConnectionsProps> = ({
  dynamicArtistPools,
  removeArtistsBySourceArtistPoolGuid,
  pageTitle = 'Configure Connections',
  handleAddToCoral,
  activeArtistPoolGuids
}) => {
  return (
    <div className="flex flex-col items-start px-4 py-4">
      <div className="w-full max-w-4xl">
        <h2 className="font-nunito text-2xl md:text-3xl font-extrabold mb-4 pl-4">{pageTitle}</h2>
        <p className="text-sm md:text-base text-gray-500 mb-3 pl-4">
          Connect corals made by others to populate your coral with suggestions.
        </p>

        {dynamicArtistPools.length > 0 && (
          <div className="mb-4 pl-4">
            <h3 className="font-nunito text-xl font-bold mb-4">Connected Corals</h3>
            <div className="flex flex-wrap gap-2">
              {dynamicArtistPools.map((pool, index) => (
                <SecondaryCoralButton
                  key={index}
                  onClick={() => removeArtistsBySourceArtistPoolGuid(pool.sourceArtistPoolGuid)}
                  className="px-4 py-2 rounded-full"
                >
                  {pool.sourceArtistPoolCoralName || pool.sourceArtistPoolGuid}
                </SecondaryCoralButton>
              ))}
            </div>
          </div>
        )}

        <div className="mb-2">
          <CuratedCoralParent
            isModal={false}
            handleAddToCoral={handleAddToCoral}
            activeArtistPoolGuids={activeArtistPoolGuids}
            removeArtistsBySourceArtistPoolGuid={removeArtistsBySourceArtistPoolGuid}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfigureConnections;