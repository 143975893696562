import React, { useState, useEffect } from 'react';
import closeIcon from '../../assets/images/close.png';
import CoralButton from '../CoralButtons/CoralButton';
import Spinner from '../Spinner/Spinner';
import { ClientCoral, Tag } from 'shared/types/platformTypes';
import { addTagToCoral, removeTagFromCoral, retrieveTagsForCoral } from '../../api/api';

type ShareCoralModalProps = {
  isOpen: boolean;
  onClose: () => void;
  coralData: ClientCoral;
  toggleShare: () => Promise<void>;
  toggleCurated: () => Promise<void>;
  updateCoralName: (newName: string) => void;
  saveCoral: () => Promise<string | null>;
  uniqueGenres: { value: string; count: number; }[];
};

const ShareCoralModal: React.FC<ShareCoralModalProps> = ({ 
  isOpen, 
  onClose, 
  coralData, 
  toggleShare, 
  toggleCurated, 
  updateCoralName,
  saveCoral,
  uniqueGenres
}) => {
  const [copied, setCopied] = useState(false);
  const [shared, setShared] = useState(coralData.coralShared);
  const [curated, setCurated] = useState(coralData.coralCurated);
  const [isLoading, setIsLoading] = useState(false);
  const [tags, setTags] = useState<Tag[]>([]);
  const [newTag, setNewTag] = useState('');
  const [isEditingName, setIsEditingName] = useState(false);
  const [editNameValue, setEditNameValue] = useState(coralData.coralName);
  const [isUpdatingName, setIsUpdatingName] = useState(false);
  const suggestedTags: Tag[] = uniqueGenres.map(genre => ({ 
    tagText: genre.value, 
    tagType: 'genreTag' 
  }));


  useEffect(() => {
    const fetchTags = async () => {
      if (coralData.sharedCoralGuid) {
        const retrievedTags = await retrieveTagsForCoral(coralData.sharedCoralGuid);
        setTags(retrievedTags);
        console.log('Retrieved tags:', retrievedTags);
      }
    };

    if (isOpen) {
      fetchTags();
    }
  }, [isOpen, coralData.sharedCoralGuid]);

  useEffect(() => {
    const runSaveCoral = async () => {
      await saveCoral();
      setIsUpdatingName(false);
      setIsEditingName(false);
    }

    if (isOpen)
      runSaveCoral();
  }, [coralData.coralName]);

  const handleSaveName = async () => {
    if (editNameValue.trim() !== coralData.coralName) {
      setIsUpdatingName(true);
      try {
        await updateCoralName(editNameValue.trim());
      } catch (error) {
        console.error('Failed to update coral name:', error);
        // Revert the name change if saving failed
        console.log('Reverting name change');
        setEditNameValue(coralData.coralName);
      }
    }
  };

  const handleAddTag = async () => {
    if (newTag.trim() !== '') {
      const newTagObj: Tag = { tagType: 'default', tagText: newTag.trim() }; // Placeholder 'default' for tagType
      setTags(prevTags => [...prevTags, newTagObj]);
      try {
        await addTagToCoral(coralData.sharedCoralGuid!, newTag.trim());
        setNewTag('');
      } catch (error) {
        console.error('Failed to add tag to coral:', error);
        setTags(prevTags => prevTags.filter(tag => tag.tagText !== newTag.trim()));
      }
    }
  };
  
  const handleRemoveTag = async (tagToRemove: Tag) => {
    setTags(tags.filter(tag => tag.tagText !== tagToRemove.tagText));
    await removeTagFromCoral(coralData.sharedCoralGuid!, tagToRemove);
  };
  
    if (!isOpen) return null;
  
    const handleCopyLink = () => {
      navigator.clipboard.writeText(`${process.env.REACT_APP_HOSTNAME}/c/${coralData.uniqueUrl}`);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    };
    
    const handleEditName = () => {
      setIsEditingName(true);
    };
  
    const handleToggleShare = async () => {
      setIsLoading(true);
      await toggleShare();
      setShared(!shared);
      setIsLoading(false);
    };
  
    const handleToggleCurated = async () => {
      await toggleCurated();
      setCurated(!curated);
    };
  
    if (!isOpen) return null;
  
    return (
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
        <div className="absolute inset-0 bg-transparent" onClick={onClose}></div>
  
        <div className="relative bg-white rounded-lg shadow-2xl w-[400px] mx-auto">
          <button
            onClick={onClose}
            className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2"
          >
            <img src={closeIcon} alt="Close" className="w-10 h-10 object-contain" />
          </button>
  
          <div className="relative mx-auto p-8">
            <h1 className="font-nunito text-lg font-extrabold text-gray-900 mb-4">Sharing Settings</h1>
  
            {/* Coral Name */}
            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-2">Share with name</label>
              {isEditingName ? (
                <div className="flex items-center">
                  <input
                    type="text"
                    value={editNameValue}
                    onChange={(e) => setEditNameValue(e.target.value)}
                    className="flex-grow mr-2 p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                    disabled={isUpdatingName}
                  />
                  <CoralButton onClick={handleSaveName} disabled={isUpdatingName}>
                    {isUpdatingName ? <Spinner /> : 'Save'}
                  </CoralButton>
                </div>
              ) : (
                <div className="flex items-center justify-between">
                  <span className="font-nunito text-gray-500 font-extrabold text-base">{coralData.coralName}</span>
                  <CoralButton onClick={handleEditName}>Edit</CoralButton>
                </div>
              )}
            </div>
  
            {/* Share toggle */}
            <div className="flex items-center mb-4">
              <label htmlFor="shareToggle" className="flex-grow text-sm font-medium text-gray-900">
                Generate Public Link
              </label>
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  name="shareToggle"
                  id="shareToggle"
                  className="sr-only peer"
                  checked={shared}
                  onChange={handleToggleShare}
                  disabled={isUpdatingName || isLoading}
                />
                <div className={`relative w-11 h-6 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 ${isUpdatingName || isLoading ? 'bg-gray-200 cursor-not-allowed' : 'bg-rose-200 peer-checked:bg-gradient-to-r peer-checked:from-coral-pink peer-checked:to-coral-orange'}`}></div>
              </label>
            </div>
  
            {/* Curated toggle */}
            <div className="flex items-center mb-6">
              <label htmlFor="curatedToggle" className="flex-grow text-sm font-medium text-gray-900">
                Add to Curated Corals
              </label>
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  name="curatedToggle"
                  id="curatedToggle"
                  className="sr-only peer"
                  checked={curated}
                  onChange={handleToggleCurated}
                  disabled={!shared || isUpdatingName || isLoading}
                />
                <div className={`relative w-11 h-6 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 ${!shared || isUpdatingName || isLoading ? 'bg-gray-200 cursor-not-allowed' : 'bg-rose-200 peer-checked:bg-gradient-to-r peer-checked:from-coral-pink peer-checked:to-coral-orange'}`}></div>
              </label>
            </div>

          {/* Share link */}
          <div className="mb-6" style={{ height: '4rem' }}>
            {isLoading ? (
              <div className="flex justify-center">
                <Spinner />
              </div>
            ) : shared && (
              <div>
                <p className="text-sm text-gray-500 mb-2">Public Link</p>
                <div className="flex">
                  <input
                    type="text"
                    readOnly
                    value={`${process.env.REACT_APP_HOSTNAME}/c/${coralData.uniqueUrl}`}
                    className="flex-grow px-4 py-2 text-sm text-gray-700 bg-gray-100 rounded-l-md focus:outline-none"
                  />
                  <button
                    onClick={handleCopyLink}
                    className="px-4 text-sm text-white bg-coral-pink rounded-r-md hover:bg-coral-pink-dark focus:outline-none"
                  >
                    {copied ? 'Copied!' : 'Copy'}
                  </button>
                </div>
              </div>
            )}
          </div>

          {coralData.sharedCoralGuid ? (
            /* Tags section */
            <div className="mb-6">
              <p className="text-sm text-gray-500 mb-2">Tags</p>
              <div className="flex flex-wrap gap-2 mb-2">
                {tags.map((tag) => (
                  <div
                    key={`${tag.tagText}-${tag.tagType}`} // Concatenation of tagText and tagType
                    className="px-2 py-1 text-sm text-gray-700 bg-gray-100 rounded-md cursor-pointer"
                    onClick={() => handleRemoveTag(tag)}
                  >
                    {tag.tagText}
                  </div>
                ))}
              </div>
              <div className="flex">
                <input
                  type="text"
                  value={newTag}
                  onChange={(e) => setNewTag(e.target.value)}
                  className="flex-grow px-4 py-2 text-sm text-gray-700 bg-gray-100 rounded-l-md focus:outline-none"
                  placeholder="Add a tag"
                />
                <button
                  onClick={handleAddTag}
                  className="px-4 text-sm text-white bg-coral-pink rounded-r-md hover:bg-coral-pink-dark focus:outline-none"
                >
                  Add
                </button>
              </div>

              {/* Suggested tags section */}
              <div className="mt-6">
                <p className="text-sm text-gray-500 mb-2">Suggested Tags</p>
                <div className="flex flex-wrap gap-2 mb-2">
                  {suggestedTags.filter(tag => !tags.some(t => t.tagText === tag.tagText)).map((tag) => (
                    <button
                      key={`${tag.tagText}-${tag.tagType}`} // Use a combination of tagText and tagType as key
                      className="px-2 py-1 text-sm text-gray-700 bg-gray-100 rounded-md cursor-pointer"
                      onClick={() => {
                        (async () => {
                          setTags([...tags, tag]);
                          await addTagToCoral(coralData.sharedCoralGuid!, tag.tagText.trim());
                        })();
                      }}
                    >
                      {tag.tagText}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          ) : null}       

          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CoralButton onClick={onClose}>Done</CoralButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareCoralModal;