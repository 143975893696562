import React from 'react';
import Image0 from 'images/thumbnails/image_0.png';
import Image1 from 'images/thumbnails/image_1.png';
import Image2 from 'images/thumbnails/image_2.png';
import Image3 from 'images/thumbnails/image_3.png';
import Image4 from 'images/thumbnails/image_4.png';
import Image5 from 'images/thumbnails/image_5.png';
import Image6 from 'images/thumbnails/image_6.png';
import Image7 from 'images/thumbnails/image_7.png';
import Image8 from 'images/thumbnails/image_8.png';
import Image9 from 'images/thumbnails/image_9.png';
import Image10 from 'images/thumbnails/image_10.png';
import Image11 from 'images/thumbnails/image_11.png';

interface CoralThumbnailProps {
  title: string;
  subtitle?: string;
  pageTurner: (page: string, guid?: string) => void;
  guid: string;
  link?: string;
  id?: string;
  standalone?: boolean;
  openCuratedCoralModal?: (coralId: string) => void;
}

const CoralCardWithThumbnail: React.FC<CoralThumbnailProps> = ({
  id,
  title,
  subtitle,
  pageTurner,
  guid,
  link,
  standalone,
  openCuratedCoralModal,
}) => {
  const imageArray = [
    Image0, Image1, Image2, Image3, Image4, Image5,
    Image6, Image7, Image8, Image9, Image10, Image11,
  ];

  const getImageIndex = (name: string) => {
    let total = 0;
    for (let i = 0; i < name.length; i++) {
      total += name.charCodeAt(i);
    }
    return total % imageArray.length;
  };

  const cardContent = (
    <div className="flex flex-col h-full bg-white rounded-lg shadow-md overflow-hidden transition-all duration-300 ease-in-out hover:shadow-lg border border-gray-200 hover:border-coral-red">
      <div className="relative pb-[56.25%]">
        <img
          src={imageArray[getImageIndex(title)]}
          alt={`${title} thumbnail`}
          className="absolute inset-0 w-full h-full object-cover"
        />
      </div>
      <div className="flex flex-col justify-between p-4 flex-grow">
        <h2 className="font-semibold text-lg text-gray-800 mb-2 line-clamp-2 flex items-center">
          {title}
        </h2>
        <p className="text-sm text-gray-600 line-clamp-2">{subtitle}</p>
      </div>
    </div>
  );

  const wrapperClasses = "w-full h-full cursor-pointer";

  if (standalone && openCuratedCoralModal) {
    return (
      <div
        id={id}
        className={wrapperClasses}
        onClick={() => openCuratedCoralModal(guid)}
      >
        {cardContent}
      </div>
    );
  }

  if (!link) {
    return (
      <div
        id={id}
        className={wrapperClasses}
        onClick={() => pageTurner('curated-individual', guid)}
      >
        {cardContent}
      </div>
    );
  }

  return (
    <a className={wrapperClasses} href={link}>
      <div id={id}>
        {cardContent}
      </div>
    </a>
  );
};

export default CoralCardWithThumbnail;