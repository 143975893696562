export const storeReferralUrl = (referralUrl: string) => {
    localStorage.setItem('referralUrl', referralUrl);
  };
  
  export const getReferralUrl = () => {
    return localStorage.getItem('referralUrl');
  };
  
  export const clearReferralUrl = () => {
    localStorage.removeItem('referralUrl');
  };