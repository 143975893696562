import React, { createContext, ReactNode, useContext, useEffect } from 'react';
import { AuthUser } from '@aws-amplify/auth';
import { useAuth } from '../../../hooks/useAuth'; // Adjust the import path as needed
import { User } from 'shared/types/platformTypes';
import { identifyUser, clearUserIdentity } from 'shared/lib/eventTracking/amplitudeTracker';

interface AuthContextType {
  showAuth: { show: boolean; signIn: boolean };
  setShowAuth: (showAuth: { show: boolean; signIn: boolean }) => void;
  currentUser: AuthUser | null;
  userProfile: User | null;
  logout: () => Promise<void>;
  fetchUserData: () => Promise<void>;
  loading: boolean;
}

export const AuthContext = createContext<AuthContextType>({
  showAuth: { show: false, signIn: false },
  setShowAuth: () => {},
  currentUser: null,
  userProfile: null,
  logout: async () => {},
  fetchUserData: async () => {},
  loading: true,
});

export const useAuthContext = () => useContext(AuthContext);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const { currentUser2, userProfile, loading, logout2, showAuth, setShowAuth, fetchUserData } = useAuth();

  useEffect(() => {
    if (currentUser2) {
      identifyUser(currentUser2.userId);
    } else {
      clearUserIdentity();
    }
  }, [currentUser2]);

  const logout = async () => {
    await logout2();
    clearUserIdentity();
  };  

  return (
    <AuthContext.Provider value={{ currentUser: currentUser2, userProfile, loading, logout, showAuth, setShowAuth, fetchUserData }}>
      {children}
    </AuthContext.Provider>
  );
};
