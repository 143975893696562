import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ClientArtistListItem, CoralSubscriptionStatus } from 'shared/types/platformTypes';
import { SimulateCoralResponse } from '../../../api/api';
import MiniCoralSimulationTable from '../../../components/CoralEditor/CoralSimulationTable/MiniCoralSimulationTable';
import CoralButton from '../../../components/CoralButtons/CoralButton';

export interface CoralSettingsProps {
    coralName: string;
    artists: Array<ClientArtistListItem>;
    simulateCoral: () => void;
    simulationResults: SimulateCoralResponse;
    updatePledgeAmount: (amount: number) => void;
    pledgeAmount: number;
    updateCoralName: (coralName: string) => void;
    addPinnedArtist: (artistId: string) => void;
    removePinnedArtist: (artistId: string) => void;
    pinnedArtists: string[];
    pauseCoralSubscription: () => Promise<string>;
    cancelCoralSubscription: () => Promise<string>;
    coralSubscriptionStatus: CoralSubscriptionStatus;
    subscriptionId: string;
    coralId: string;
}

const CoralSettings: React.FC<CoralSettingsProps> = ({
    coralName,
    simulateCoral,
    updatePledgeAmount,
    simulationResults,
    pledgeAmount,
    addPinnedArtist,
    removePinnedArtist,
    pinnedArtists,
    updateCoralName,
    pauseCoralSubscription,
    cancelCoralSubscription,
    coralSubscriptionStatus,
    subscriptionId,
    coralId,
}) => {
    const [isEditingName, setIsEditingName] = useState(false);
    const [editNameValue, setEditNameValue] = useState(coralName);
    const [isEditingPledge, setIsEditingPledge] = useState(false);
    const [editPledgeValue, setEditPledgeValue] = useState(pledgeAmount.toString());
    const [validationMessage, setValidationMessage] = useState('');
    const history = useHistory();

    pledgeAmount = Number(pledgeAmount);

    useEffect(() => {
        if (pledgeAmount > 0) {
            simulateCoral();
        }
    }, [pledgeAmount]);

    const handleUpdateCoralName = () => {
        updateCoralName(editNameValue);
        setIsEditingName(false);
    };

    const handleUpdatePledgeAmount = () => {

        const decimalRegex = /^([1-9]\d*(\.\d{1,2})?)?$/;

        if (decimalRegex.test(editPledgeValue)) {

            if (Number(editPledgeValue) <= 0 || Number(editPledgeValue) > 70) {
                setValidationMessage('Pledge amount must be between $0 and $70 AUD');
                setEditPledgeValue(pledgeAmount.toString());
            }
            else {
                console.log('Pledge amount updated', Number(editPledgeValue));
                updatePledgeAmount(Number(editPledgeValue));
                setIsEditingPledge(false);
            }
        } else {
            console.log('Invalid pledge amount');
            setEditPledgeValue(pledgeAmount.toString());
        }
    };

    const handlePauseCoralSubscription = async () => {
        if (window.confirm("Are you sure you want to pause the pledge?")) {
            await pauseCoralSubscription();
            history.push(`/coral/${coralId}`);
        }
    };

    const handleCancelCoralSubscription = async () => {
        if (window.confirm("Are you sure you want to cancel the pledge?")) {
            await cancelCoralSubscription();
            history.push(`/dashboard`);
        }
    };

    return (
        <div className="px-4 py-6 max-w-4xl">
            <h2 className="font-nunito text-xl md:text-2xl font-extrabold mb-6">Coral Settings</h2>

            <div className="space-y-8">
                <div className="bg-white rounded-lg p-6">
                    <div className="mb-6">
                        <label className="block text-sm font-medium text-gray-700 mb-2">Coral Name</label>
                        {isEditingName ? (
                            <div className="flex items-center">
                                <input
                                    type="text"
                                    value={editNameValue}
                                    onChange={(e) => setEditNameValue(e.target.value)}
                                    className="flex-grow mr-2 p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                                />
                                <CoralButton className="font-semibold text-xs sm:text-sm" onClick={handleUpdateCoralName}>
                                    Confirm
                                </CoralButton>
                            </div>
                        ) : (
                            <div className="flex items-center justify-between">
                                <span className="text-gray-500 font-medium text-sm sm:text-base">{coralName}</span>
                                <CoralButton className="font-semibold text-xs sm:text-sm" onClick={() => setIsEditingName(true)}>
                                    Edit
                                </CoralButton>
                            </div>
                        )}
                    </div>

                    <div>
                        <label htmlFor="pledge-amount" className="block text-sm font-medium text-gray-700 mb-2">
                            Monthly Contribution
                        </label>
                        {isEditingPledge ? (
                            <div>
                                <div className="flex items-center">
                                    <div className="relative flex-grow mr-4">
                                        <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500">
                                            $
                                        </span>
                                        <input
                                            type="text"
                                            inputMode="numeric"
                                            id="pledge-amount"
                                            value={editPledgeValue}
                                            onChange={(e) => setEditPledgeValue(e.target.value)}
                                            className="block w-full pl-7 pr-12 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                                            placeholder="Enter amount"
                                        />
                                        <span className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-500">
                                            AUD
                                        </span>
                                    </div>
                                    <CoralButton className="font-semibold text-xs sm:text-sm whitespace-nowrap" onClick={handleUpdatePledgeAmount}>
                                        Confirm
                                    </CoralButton>
                                </div>
                                <div>
                                    {validationMessage && (
                                        <p className="text-red-500 mt-2">{validationMessage}</p>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div className="flex items-center justify-between">
                                <span className="text-gray-500 font-medium text-sm sm:text-base">
                                    ${pledgeAmount.toFixed(2)} AUD
                                </span>
                                <CoralButton className="font-semibold text-xs sm:text-sm" onClick={() => setIsEditingPledge(true)}>
                                    Edit
                                </CoralButton>
                            </div>
                        )}
                    </div>
                </div>

                <div className="bg-white rounded-lg p-6">
                    <h3 className="font-nunito text-lg font-extrabold mb-4">Impact Preview</h3>
                    <MiniCoralSimulationTable
                        artistAllocations={simulationResults.artistAllocations}
                        addPinnedArtist={addPinnedArtist}
                        removePinnedArtist={removePinnedArtist}
                        pinnedArtists={pinnedArtists}
                        simulateCoral={simulateCoral}
                    />
                </div>

                <div className="bg-white rounded-lg p-6">
                    <h3 className="font-nunito text-lg font-extrabold mb-4">Subscription Information</h3>
                    <div className="space-y-4">
                        <div>
                            <span className="font-medium">Subscription ID:</span> {subscriptionId}
                        </div>
                        <div>
                            <span className="font-medium">Status:</span> {CoralSubscriptionStatus[coralSubscriptionStatus]}
                        </div>
                        <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4">
                            {coralSubscriptionStatus === CoralSubscriptionStatus.ACTIVE && (
                                <button
                                    className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 border border-gray-300 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                    onClick={handlePauseCoralSubscription}
                                >
                                    Pause Subscription
                                </button>
                            )}
                            {(coralSubscriptionStatus === CoralSubscriptionStatus.ACTIVE || coralSubscriptionStatus === CoralSubscriptionStatus.PAUSED) && (
                                <button
                                    className="px-4 py-2 text-sm font-medium text-red-700 bg-red-100 border border-red-300 rounded-md hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                    onClick={handleCancelCoralSubscription}
                                >
                                    Cancel Subscription
                                </button>
                            )}
                        </div>
                        {process.env.REACT_APP_ENVIRONMENT !== 'prod' && (
                            <div>
                                <a
                                    href={`https://www.sandbox.paypal.com/billing/subscriptions/${subscriptionId}`}
                                    rel="noreferrer"
                                    target="_blank"
                                    className="text-blue-500 hover:text-blue-700"
                                >
                                    View in PayPal Sandbox Console
                                </a>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CoralSettings;