import React, { useState, useEffect } from 'react';
import { getCoralPayments } from '../../../api/api';
import { ProcessedCoralPayments } from 'shared/types/platformTypes';
import Spinner from '../../../components/Spinner/Spinner';
import { ChevronDownIcon, ChevronUpIcon } from 'lucide-react';

interface TransactionsProps {
  coralId: string;
}

const CoralTransactions: React.FC<TransactionsProps> = ({ coralId }) => {
  const [payments, setPayments] = useState<ProcessedCoralPayments[]>([]);
  const [loading, setLoading] = useState(true);
  const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const fetchedPayments = await getCoralPayments(coralId);
        setPayments(fetchedPayments);
      } catch (error) {
        console.error('Failed to fetch payments:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPayments();
  }, [coralId]);

  const toggleRowExpansion = (transactionId: string) => {
    setExpandedRows(prevExpandedRows => {
      const newExpandedRows = new Set(prevExpandedRows);
      if (newExpandedRows.has(transactionId)) {
        newExpandedRows.delete(transactionId);
      } else {
        newExpandedRows.add(transactionId);
      }
      return newExpandedRows;
    });
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg p-4">
      <div className="py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-nunito font-extrabold text-coral-black">Payment History</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">Your recent transactions are listed below</p>
      </div>
      <div className="border-t border-gray-200">
        <div className="mt-3 flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Date
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Amount
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Status
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Details
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {payments.map((payment) => (
                      <React.Fragment key={payment.transactionId}>
                        <tr>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{payment.processedDate}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">${payment.pledgeAmount.toFixed(2)}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{payment.paymentStatus}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            <button
                              onClick={() => toggleRowExpansion(payment.transactionId)}
                              className="text-coral-pink hover:text-coral-orange focus:outline-none"
                            >
                              {expandedRows.has(payment.transactionId) ? (
                                <ChevronUpIcon className="h-5 w-5" />
                              ) : (
                                <ChevronDownIcon className="h-5 w-5" />
                              )}
                            </button>
                          </td>
                        </tr>
                        {expandedRows.has(payment.transactionId) && (
                        <tr>
                            <td colSpan={4} className="px-6 py-4">
                            <div className="text-sm text-gray-700 space-y-4">
                                <div className="grid grid-cols-2 gap-4">
                                <div>
                                    <h4 className="font-semibold text-coral-pink">Payment Breakdown</h4>
                                    <div className="mt-2 space-y-1">
                                    <p className="flex justify-between">
                                        <span>Artist Contribution:</span>
                                        <span className="font-medium">${payment.artistContribution.toFixed(2)}</span>
                                    </p>
                                    <p className="flex justify-between">
                                        <span>Payment Processing:</span>
                                        <span className="font-medium">${payment.transactionFee.toFixed(2)}</span>
                                    </p>
                                    <p className="flex justify-between">
                                        <span>Platform Fee (includes 10% GST):</span>
                                        <span className="font-medium">${payment.platformFee.toFixed(2)}</span>
                                    </p>
                                    <div className="border-t border-gray-200 pt-1 mt-1">
                                        <p className="flex justify-between font-semibold">
                                        <span>Total:</span>
                                        <span>${payment.pledgeAmount.toFixed(2)}</span>
                                        </p>
                                    </div>
                                    </div>
                                </div>
                                <div>
                                    <h4 className="font-semibold text-coral-pink">Artist Allocations</h4>
                                    <div className="mt-2 space-y-1 max-h-40 overflow-y-auto">
                                    {payment.artistAllocations.map((allocation) => (
                                        <p key={allocation.artistId} className="flex justify-between">
                                        <span className="truncate" title={allocation.artistName}>
                                            {allocation.artistName}:
                                        </span>
                                        <span className="font-medium ml-2">
                                            ${allocation.amount.toFixed(2)}
                                        </span>
                                        </p>
                                    ))}
                                    </div>
                                </div>
                                </div>
                            </div>
                            </td>
                        </tr>
                        )}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoralTransactions;