export const faqData = [
    {
        "question": "How is wearecoral different?",
        "answer": "wearecoral enables a new social contract between artists and communities. No content creation requirements, no merchandise management, no tiered rewards to configure. Communities provide direct income creating space for you to practice your craft."
    },
    {
        "question": "How do communities back artists?",
        "answer": "People create 'corals' - personal collections of artists they want to invest in monthly. When you're included in a coral, you receive a share of that person's monthly contribution. It's your community backing you directly, without the friction of traditional platforms."
    },
    {
        "question": "How do I get started?",
        "answer": "It's frictionless - just enter your artist name to generate your profile instantly. No signup required. You'll get a simple link to share anywhere - socials, Linktree, website, or newsletters. Your community can even start backing you before you create a profile. When contributions come in, we'll reach out through your official channels with claiming instructions."
    },
    {
        "question": "Sounds interesting. What's the catch?",
        "answer": `There's no catch. wearecoral is a social enterprise started by <a href="https://www.linkedin.com/in/dan-gooden-40191316/" style="color: #FC5373;" target='_blank' rel='noopener noreferrer'>Dan</a>, an Australian music fan working at the intersection of technology, data, and community organization. Our mission is to help create space for artists to practice their craft, fostering a vibrant, diverse ecosystem of music and culture. Feel free to reach out via <a href="mailto:dan@wearecoral.org" style="color: #FC5373;">email</a> or <a href="https://www.linkedin.com/in/dan-gooden-40191316/" style="color: #FC5373;" target='_blank' rel='noopener noreferrer'>LinkedIn</a>.`
    },
    {
        "question": "How do payments work?",
        "answer": "When you have funds, we'll contact you with a personalised link and verification code to verify your artist profile and start receiving monthly payments via PayPal."
    },
    {
        "question": "Who is eligible?",
        "answer": "Active artists who have published music are eligible. We focus on creating space for artists who can benefit most, so we filter out those who haven't released in several years, have passed away, or have reached high commercial success."
    },
    {
        "question": "How do you ensure meaningful income?",
        "answer": "We limit the number of artists in each coral to prevent dilution. As we grow, we'll continue optimizing these parameters based on artist feedback to maintain impactful contribution levels."
    },
    {
        "question": "Can I connect with my community?",
        "answer": "Yes - if they opt in, you'll be able to communicate with them about new releases, tours, and updates. This feature is in development."
    },
    {
        "question": "What happens if I don't claim my funds?",
        "answer": "You have 9 months from our first contact to claim your funds. After that, the money returns to the contributors and your profile becomes Not Eligible. You can reactivate by signing up later, but previous unclaimed funds can't be retrieved."
    }
]