import React, { useEffect } from 'react';
import aboutHeroImage from '../../assets/images/coral-wide-2.png';
// import danImage from '../../assets/images/about/dan-image.png';
import lightBlueWaveImage from '../../assets/images/light-blue-wave.png'; // Assuming you have this image
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { trackEvent, EventName } from 'shared/lib/eventTracking';


const About: React.FC = () => {
    useEffect(() => {
        trackEvent(EventName.ABOUT_PAGE_VIEW, {});
    }, []);

    return (
        <div className="fade-in-top">
            <div className="max-w-screen-xl mx-auto">
                {/* Title text "Our dream" */}
                <div className="p-16 bg-white w-auto md:w-2/3 text-left">
                    <h1 className="font-nunito text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-coral-black font-extrabold">
                        Our dream is a vibrant, healthy, and diverse ecosystem of music, artists and communities.
                    </h1>
                </div>
            </div>
            
            {/* Hero image section */}
            <div className="2xl:hidden"> {/* Hide on 2xl screens and above */}
                <img src={aboutHeroImage} alt="About Hero" className="aspect-content aspect-center w-full h-auto object-cover" />
            </div>

            <div className="2xl:h-[500px] relative"> {/* Show on 2xl screens and above */}
                <img src={aboutHeroImage} alt="About Hero" className="absolute inset-0 w-full h-full object-cover"/>
            </div>

            {/* About Coral section */}
            <div className="p-16">
                <div className="max-w-screen-xl mx-auto flex flex-col md:flex-row">
                    {/* Left column for the heading */}
                    <div className="md:w-1/2 mb-6 md:mb-0 md:pr-8">
                        <h2 className="text-sm md:text-base font-extrabold">
                            <div className="inline bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
                                ABOUT WEARECORAL
                            </div>
                        </h2>
                        <h2 className="font-nunito text-2xl md:text-3xl text-coral-black font-extrabold">
                                What can we make together?
                        </h2>
                    </div>
                    
                    {/* Right column for the text */}
                    <div className="md:w-1/2">
                        <p className="text-sm sm:text-base md:text-lg text-coral-black">
                        wearecoral reflects the inquiry, how can we cooperate at scale to make culture?<br /><br />

                        Our answer is a platform for supporting the musicians and scenes we love. Members&apos; monthly pledges are distributed among multiple artists, through a dynamic, user-configured system. This additional income improves financial stability for artists so they can focus on their craft, enriching our lives with their music.<br /><br />

                        wearecoral is designed to harness our collective capacity to care for the artists and scenes we love, instilling a sense of <em>power together</em>, and profound agency to shape the future we dream of.<br /><br />

                        wearecoral is committed to creating an organisation that prioritises collective benefit over individual gain. Through open financial practices, engaging our community in key decisions, and dedicating half of our profits to charitable causes within the music industry, we seek to reflect the principle that our collective action is more powerful than individual efforts.<br /><br />

                        With wearecoral we&apos;re active participants in the future being made today.<br /><br />
                        </p>
                        <div className="font-semibold text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-purple-500 text-glow animate-breathe-fast">
                            Let&apos;s play together.
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative w-full h-full">
                <img src={lightBlueWaveImage} alt="Light Blue Wave" className="w-full h-full object-cover" />
                <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gradient-to-t from-coral-light-blue to-transparent"></div>
            </div>

            {/* Contributors section */}
            <div className="p-16 pt-0 bg-coral-light-blue">
                <div className="max-w-screen-xl mx-auto md:grid md:grid-cols-2">
                    {/* Left column for the heading */}
                    <div className="mb-6 md:mb-0 md:pr-8">
                        <h2 className="text-sm md:text-base font-extrabold">
                            <div className="inline bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
                                OUR CONTRIBUTORS
                            </div>
                        </h2>
                        <h2 className="font-nunito text-2xl md:text-3xl text-coral-black font-extrabold">
                            Behind the scenes
                        </h2>
                    </div>
                    
                    {/* Right column for the contributors */}
                    {/* Adjust the grid setup for the right column to stack the contributors */}
                    <div className="md:col-span-1">
                        {/* Repeat this block for each contributor */}
                        <div className="flex flex-col mb-8 last:mb-0">
                            <div className="grid grid-cols-3 items-center gap-4">
                                
                                <div className="col-span-2">
                                    <p className="text-coral-black font-nunito font-extrabold">Lachlan McEwen</p>
                                    <p className="text-coral-pink text-sm font-bold">Developer</p>
                                </div>
                            </div>

                        </div>
                        {/* End of block for one contributor */}
                        <div className="grid grid-cols-1 md:grid-cols-2 mb-8 last:mb-0">
                            <div className="md:flex-grow">
                                <p className="text-coral-black font-nunito font-extrabold">Dan Gooden</p>
                                <p className="text-coral-pink text-sm font-bold">Creator</p>
                                <div className="flex md:hidden mt-2">
                                    <a href="https://www.linkedin.com/in/dan-gooden-40191316/" target="_blank" rel="noopener noreferrer" className="mr-2">
                                        <FontAwesomeIcon icon={faLinkedin} className="h-5 w-5 text-neutral-500" />
                                    </a>
                                    <a href="mailto:dan@example.com">
                                        <FontAwesomeIcon icon={faEnvelope} className="h-5 w-5 text-neutral-500" />
                                    </a>
                                </div>
                                <p className="text-neutral-500 text-sm mt-4">
                                    Dan is passionate about how humans communicate, decide and act in concert, at scale.
                                </p>
                            </div>
                            <div className="hidden md:flex justify-end items-start">
                                <a href="https://www.linkedin.com/in/dan-gooden-40191316/" target="_blank" rel="noopener noreferrer" className="mr-2">
                                    <FontAwesomeIcon icon={faLinkedin} className="h-5 w-5 text-neutral-500" />
                                </a>
                                <a href="mailto:dan@wearecoral.org">
                                    <FontAwesomeIcon icon={faEnvelope} className="h-5 w-5 text-neutral-500" />
                                </a>
                            </div>
                        </div>
                        {/* End of block for one contributor */}
                    </div>
                </div>
            </div>


        </div>



    );
};

export default About;
