import React from 'react';

interface IntegrationsProps {
    // Add any props that you need for this component
}

const Integrations: React.FC<IntegrationsProps> = () => {

    return (
        <div className="bg-white overflow-hidden sm:rounded-lg p-4 flex flex-col items-start">
            <div className="py-5 sm:px-4 w-full">
                <h3 className="text-lg leading-6 font-nunito font-extrabold text-coral-black">Integrations</h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">Manage your integrations</p>
            </div>
            <div className="border-t border-gray-200 w-full px-4 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200">
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex items-center">
                        </dd>
                    </div>
                </dl>
            </div>
        </div>
    );
};

export default Integrations;