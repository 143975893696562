import React, { useState, useEffect } from 'react';
import { ClientArtistListItem } from 'shared/types/platformTypes';
import { SimulateCoralResponse } from '../../../api/api';
import MiniCoralSimulationTable from '../../../components/CoralEditor/CoralSimulationTable/MiniCoralSimulationTable';
import CoralButton from '../../../components/CoralButtons/CoralButton';
import selectedStar from '../../../assets/images/selected-star.png';
import { trackEvent, EventName } from 'shared/lib/eventTracking';
import { useAuthContext } from '../../Auth/AuthContext/AuthContext';

export interface PreviewImpactProps {
    artists: Array<ClientArtistListItem>;
    simulateCoral: () => void;
    simulationResults: SimulateCoralResponse;
    updatePledgeAmount: (amount: number) => void;
    pledgeAmount: number;
    addPinnedArtist: (artistId: string) => void;
    removePinnedArtist: (artistId: string) => void;
    pinnedArtists: string[];
}

const PreviewImpact: React.FC<PreviewImpactProps> = ({
    pledgeAmount,
    simulateCoral,
    updatePledgeAmount,
    simulationResults,
    pinnedArtists,
    addPinnedArtist,
    removePinnedArtist
}) => {
    const { userProfile } = useAuthContext();
    const [inputValue, setInputValue] = useState('');
    const [validationMessage, setValidationMessage] = useState('');

    useEffect(() => {
        updatePledgeAmount(0);
        simulateCoral();
    }, []);

    useEffect(() => {
        setInputValue(pledgeAmount === 0 ? '' : pledgeAmount.toString());
    }, [pledgeAmount]);

    useEffect(() => {
        if (pledgeAmount > 0) {
            simulateCoral();
        }
    }, [pledgeAmount]);

    useEffect(() => {
        console.log('simulationResults updated', simulationResults);
        if (pledgeAmount > 0)
            trackEvent(EventName.SIMULATE_CORAL, {
                userId: userProfile!.userId,
                amount: pledgeAmount,
                artistCount: simulationResults.artistAllocations.length
            });
    }, [simulationResults]);

    const handleUpdatePledgeAmount = () => {
        const decimalRegex = /^([1-9]\d*(\.\d{1,2})?)?$/;

        if (inputValue === '') {
            console.log('Textbox is empty');
        } else if (decimalRegex.test(inputValue)) {

            if (Number(inputValue) <= 0 || Number(inputValue) > 70) {
                setValidationMessage('Pledge amount must be between $0 and $70 AUD');
            }
            else {
                updatePledgeAmount(Number(inputValue));
                console.log('Pledge amount updated');
            }
        } else {
            console.log('Invalid pledge amount');
            setValidationMessage('Invalid pledge amount');
        }
    };

    return (
        <div className="max-w-4xl mx-auto px-4 py-6">
            <h2 className="font-nunito text-xl md:text-2xl font-extrabold mb-4">Preview Your Impact</h2>
            <div className="bg-white rounded-lg shadow-md p-6 mb-8">
                <label htmlFor="pledge-amount" className="block text-sm font-medium text-gray-700 mb-2">
                    How much would you like to contribute per month?
                </label>
                <form onSubmit={(event) => {
                    event.preventDefault();
                    handleUpdatePledgeAmount();
                }} className="flex items-center">
                    <div className="relative flex-grow mr-4">
                        <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500">
                            $
                        </span>
                        <input
                            type="text"
                            inputMode="numeric"
                            name="pledge-amount"
                            id="pledge-amount"
                            value={inputValue}
                            onChange={(event) => setInputValue(event.target.value)}
                            className="block w-full pl-7 pr-12 py-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                            placeholder="Enter amount"
                        />
                        <span className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-500">
                            AUD
                        </span>
                    </div>
                    <CoralButton className="font-semibold text-xs sm:text-sm whitespace-nowrap" onClick={handleUpdatePledgeAmount}>
                        Preview Impact
                    </CoralButton>
                </form>
                <div>
                    {validationMessage && (
                        <p className="text-red-500 mt-2">{validationMessage}</p>
                    )}
                </div>
            </div>

            <div className="bg-white rounded-lg shadow-md p-6">
                <div className="flex items-center mb-2">
                    <img src={selectedStar} alt="Star" className="inline-block mr-2" width="36" height="36" />
                    <h3 className="font-nunito text-lg font-extrabold">Boost Artists</h3>
                </div>
                <p className="text-sm mb-4">
                    Give more to specific artists. You can choose up to two artists to receive more.
                </p>

                <MiniCoralSimulationTable
                    artistAllocations={simulationResults.artistAllocations}
                    addPinnedArtist={addPinnedArtist}
                    removePinnedArtist={removePinnedArtist}
                    pinnedArtists={pinnedArtists}
                    simulateCoral={simulateCoral}
                />
            </div>
        </div>
    );
};

export default PreviewImpact;