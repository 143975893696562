import React from 'react';
import { User } from 'shared/types/platformTypes';


interface AccountProps {
   user: Partial<User> | null;
   onUserChange: (user: Partial<User>) => void;
}

const Account: React.FC<AccountProps> = ({ user, onUserChange }) => {

   const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
       onUserChange({ ...user, firstName: event.target.value });
   };

   const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
       onUserChange({ ...user, lastName: event.target.value });
   };

   return (
       <div className="bg-white overflow-hidden sm:rounded-lg p-4 flex flex-col items-start">
           <div className="py-5 sm:px-4 w-full">
               <h3 className="text-lg leading-6 font-nunito font-extrabold text-coral-black">Account</h3>
               <p className="mt-1 max-w-2xl text-sm text-gray-500">Basic Information</p>
           </div>
           <div className="border-t border-gray-200 w-full">
               <dl>
                   <div className="bg-white py-5 w-full">
                       <label className="block text-sm font-medium text-gray-500">Email</label>
                       <input 
                           type="email" 
                           className="border border-gray-100 rounded w-full p-2" 
                           defaultValue={user?.email || ''}
                           disabled
                       />
                   </div>

                   <div className="bg-white flex flex-wrap w-full justify-between">
                       <div className="w-full sm:w-1/2 sm:pr-2 mb-4">
                           <label className="block text-sm font-medium text-gray-500 mb-2">First name</label>
                           <input 
                               type="text" 
                               className="border border-gray-300 rounded w-full p-2" 
                               defaultValue={user?.firstName || ''}
                               onChange={handleFirstNameChange}
                           />
                       </div>
                       <div className="w-full sm:w-1/2 sm:pl-2">
                           <label className="block text-sm font-medium text-gray-500 mb-2">Last name</label>
                           <input 
                               type="text" 
                               className="border border-gray-300 rounded w-full p-2" 
                               defaultValue={user?.lastName || ''}
                               onChange={handleLastNameChange}
                           />
                       </div>
                   </div>

                   <div className="border-t border-gray-200 w-full">
                       <div className="py-5 sm:px-4 w-full">
                           <h3 className="text-lg leading-6 font-nunito font-extrabold text-coral-black pb-3">Artist Connections</h3>
                           <a href="/artist-connections" className="block text-base font-bold font-nunito text-coral-pink mb-2">
                               Manage Artist Connections
                           </a>
                           <a href="/verify-identity" className="block text-base font-bold font-nunito text-coral-pink mb-2">
                               Verify Identity
                           </a>
                           <a href="/artist-applications" className="block text-base font-bold font-nunito text-coral-pink">
                               Verify Artist Connections
                           </a>
                       </div>
                   </div>                    

                   {/* <div className="bg-orange-100 px-4 py-5 w-full rounded shadow-md mt-20">
                     <label className="block text-base font-bold text-orange-700 mb-2">CAUTION</label>
                     <div className="mt-1 text-sm text-gray-900">
                       <button 
                         type="button" 
                         className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 mb-2"
                       >
                         Delete Your Account
                       </button>
                       <p className="mt-2 text-sm text-gray-700">
                       Deleting your account is permanent and irreversible. You&apos;ll lose all data, content, and access with no option to recover or restore. Future payments will be stopped. Please confirm if you wish to proceed with deleting your account.
                       </p>
                     </div>
                   </div> */}
               </dl>
           </div>
       </div>
   );
};

export default Account;