import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { verifyArtist, getArtistDetailsPublic } from '../../api/api';
import coralReef from '../../assets/images/coral-reef.svg';
import darkBlueWaveImage from '../../assets/images/dark-blue-wave.png';
import { trackEvent, EventName } from 'shared/lib/eventTracking';
import { BlendedArtistListItem } from 'shared/types/platformTypes';
import ArtistPayoutChecker from '../../components/ArtistPayoutChecker/ArtistPayoutChecker';
import Spinner from '../../components/Spinner/Spinner';
import { faqData } from '../Home/faqData';
import FAQItem from '../../components/FAQItem/faqItem';
import CoralEcosystem from '../../components/CoralEcosystem/CoralEcosystem';

interface VerificationFormData {
  verificationCode: string;
  email: string;
  paypalEmail: string;
}

const ArtistPayoutCollection: React.FC = () => {
  const { artistUrl } = useParams<{ artistUrl: string }>();
  const location = useLocation();
  const [formData, setFormData] = useState<VerificationFormData>({
    verificationCode: '',
    email: '',
    paypalEmail: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [result, setResult] = useState<{ success: boolean; message: string } | null>(null);
  const [artist, setArtist] = useState<BlendedArtistListItem | null>(null);
  const [loading, setLoading] = useState(true);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [validationError, setValidationError] = useState<string | null>(null);

  useEffect(() => {
    const fetchArtistData = async () => {
      try {
        const data = await getArtistDetailsPublic(artistUrl);
        if (data && data.length > 0) {
          setArtist(data[0]);
          // Track view after successful artist fetch
          trackEvent(EventName.ARTIST_VERIFICATION_VIEW, {
            artistUrl
          });
        } else {
          console.warn('No artist data returned');
        }
      } catch (error) {
        console.error('Error fetching artist:', error);
      } finally {
        setLoading(false);
      }
    };

    if (artistUrl) {
      setLoading(true);
      fetchArtistData();
    }
  }, [artistUrl]);

  const handleTermsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTermsAgreed(e.target.checked);
    if (e.target.checked) {
      setValidationError(null);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!termsAgreed) {
      setValidationError('Please agree to the Artist Terms and Conditions before proceeding.');
      return;
    }

    setIsSubmitting(true);
    
    const params = new URLSearchParams(location.search);
    const verificationUrl = params.get('verifyUrl');

    if (!verificationUrl) {
      setResult({
        success: false,
        message: 'Missing verification URL. Please check your link and try again.'
      });
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await verifyArtist({
        verificationUrl,
        ...formData
      });
      
      setResult(response);
      trackEvent(EventName.ARTIST_VERIFICATION_COMPLETE, {
        artistUrl,
        success: response.success
      });
    } catch (error) {
      const result = {
        success: false,
        message: 'An error occurred during verification. Please try again.'
      };
      setResult(result);
      trackEvent(EventName.ARTIST_VERIFICATION_COMPLETE, {
        artistUrl,
        success: false
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Spinner />
      </div>
    );
  }

  if (!artist) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <h1 className="text-2xl font-bold mb-4">Artist Not Found</h1>
          <p>We couldn&apos;t find the artist you&apos;re looking for.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col">
      <section className="relative pt-8 sm:pt-16 md:pt-32 bg-no-repeat bg-contain bg-bottom xl:bg-cover" 
               style={{ backgroundImage: `url(${coralReef})` }}>
        <div className="max-w-screen-xl mx-auto px-8">
          <div className="flex flex-col md:flex-row md:items-start md:justify-between gap-8 pb-32">
            <div className="md:w-1/2">
              <h1 className="font-syne font-medium text-4xl sm:text-5xl md:text-6xl text-coral-black font-extrabold mb-4 leading-tight">
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
                  {artist?.artistName}
                </span>
              </h1>
              <p className="text-lg sm:text-xl md:text-2xl font-syne text-coral-black mb-6">
                Ready to collect your contributions? Complete the verification process below.
              </p>
            </div>

            <div className="md:w-1/2">
              <div className="mb-8">
                <ArtistPayoutChecker presetArtist={artist?.artistName} />
              </div>
              
              {!result ? (
                <form onSubmit={handleSubmit} className="bg-white bg-opacity-95 backdrop-blur-md rounded-lg p-6 shadow-lg">
                  <h2 className="font-nunito text-lg sm:text-xl font-bold mb-6 text-coral-black">Verify Your Identity</h2>
                  <div className="space-y-4">
                    <div>
                      <label className="block text-coral-black text-sm font-medium mb-2">
                        Verification Code
                      </label>
                      <input
                        type="text"
                        value={formData.verificationCode}
                        onChange={(e) => setFormData({...formData, verificationCode: e.target.value})}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-coral-pink"
                        required
                      />
                    </div>
                    <div>
                      <label className="block text-coral-black text-sm font-medium mb-2">
                        Your Email
                      </label>
                      <input
                        type="email"
                        value={formData.email}
                        onChange={(e) => setFormData({...formData, email: e.target.value})}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-coral-pink"
                        required
                      />
                    </div>
                    <div>
                      <label className="block text-coral-black text-sm font-medium mb-2">
                        PayPal Email
                      </label>
                      <input
                        type="email"
                        value={formData.paypalEmail}
                        onChange={(e) => setFormData({...formData, paypalEmail: e.target.value})}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-coral-pink"
                        required
                      />
                      <p className="mt-1 text-sm text-gray-500">
                        This is where we&apos;ll send your contributions
                      </p>
                    </div>
                  </div>
                  
                  {/* Terms and Conditions section */}
                  <div className="space-y-4 mt-6 mb-6">
                    <h3 className="font-nunito text-lg sm:text-xl font-bold text-coral-black">Artist Terms and Conditions</h3>
                    <p className="text-base text-gray-700">
                      By verifying your artist profile and proceeding with the collection process, you agree to the <a href="/artist-terms" target="_blank" rel="noopener noreferrer" className="text-coral-pink hover:text-coral-600">Artist Terms and Conditions</a>. These terms govern the use of the platform, the distribution of funds, and the responsibilities of both the artist and wearecoral.
                    </p>
                    <div className="flex items-start mt-4">
                      <input
                        type="checkbox"
                        id="termsAgreed"
                        checked={termsAgreed}
                        onChange={handleTermsChange}
                        className="mt-1 mr-2"
                      />
                      <label htmlFor="termsAgreed" className="text-sm text-gray-700">
                        I have read and agree to the <a href="/artist-terms" target="_blank" rel="noopener noreferrer" className="text-coral-pink hover:text-coral-600">Artist Terms and Conditions</a>
                      </label>
                    </div>
                    {validationError && (
                      <p className="text-red-500 text-sm mt-2">{validationError}</p>
                    )}
                  </div>

                  <button
                    type="submit"
                    disabled={isSubmitting || !termsAgreed}
                    className="w-full mt-6 bg-gradient-to-r from-coral-pink to-coral-orange text-white font-bold py-3 px-4 rounded-md hover:opacity-90 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    {isSubmitting ? 'Verifying...' : 'Complete Verification'}
                  </button>
                </form>
              ) : (
                <div className={`p-6 rounded-lg ${result.success ? 'bg-green-100' : 'bg-red-100'}`}>
                  <p className="text-lg font-medium mb-4">{result.message}</p>
                  {result.success && (
                    <p>You will receive your first payment at the end of this month.</p>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      {/* Vision Section */}
      <section className="bg-coral-blue text-white">
        <div className="p-8 md:p-16">
          <div className="max-w-screen-xl mx-auto">
            <div className="flex flex-col lg:flex-row lg:items-start lg:justify-between">
              <div className="lg:w-1/2 mb-8 lg:mb-0">
                <h2 className="text-xl md:text-2xl font-extrabold mb-2 sm:mb-4">
                  <span className="bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
                    OUR VISION
                  </span>
                </h2>
                <h3 className="font-syne text-2xl md:text-4xl mb-0 sm:mb-8 leading-tight mr-0 sm:mr-12">
                  A thriving ecosystem where music is valued and diverse styles and scenes can prosper.
                </h3>
              </div>
              <div className="lg:w-1/2 mb-6 sm:mb-14">
                <CoralEcosystem />
              </div>
            </div>
          </div>
        </div>
        <img src={darkBlueWaveImage} alt="Blue Wave" className="w-full" />
      </section>

      {/* FAQ Section */}
      <section className="bg-coral-dark-blue text-white p-8 md:p-16">
        <div className="max-w-screen-xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-16">
            <div>
              <h2 className="text-xl md:text-2xl font-extrabold mb-4">
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
                  SUPPORT
                </span>
              </h2>
              <h3 className="font-syne text-3xl md:text-4xl mb-6 leading-tight">
                FAQs
              </h3>
              <p className="text-xl mb-8">
                Everything you need to know about collecting your contributions through wearecoral.
              </p>
            </div>
            <div className="space-y-4">
              {faqData.map((faq, index) => (
                <FAQItem key={index} question={faq.question} answer={faq.answer} />
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ArtistPayoutCollection;
