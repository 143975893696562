import React, { useState, useEffect, useRef } from 'react';
import { ClientArtistListItem } from 'shared/types/platformTypes';
import { AlertCircle, Search } from 'lucide-react';

interface ArtistSearchStatusProps {
  artists: ClientArtistListItem[];
}

const ArtistSearchStatus: React.FC<ArtistSearchStatusProps> = ({ artists }) => {
  const [showMessage, setShowMessage] = useState(false);
  const [messageType, setMessageType] = useState<'pending' | 'notFound' | ''>('');
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    // Clear the previous timer if it exists
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // Set a new timer
    timerRef.current = setTimeout(() => {
      const hasPending = artists.some(artist => artist.artistListItemStatus === 'pending');
      const hasNotFound = artists.some(artist => artist.artistListItemStatus === 'not found');

      if (hasPending) {
        setMessageType('pending');
        setShowMessage(true);
      } else if (hasNotFound) {
        setMessageType('notFound');
        setShowMessage(true);
      } else {
        setShowMessage(false);
      }
    }, 2000);

    // Cleanup function to clear the timer if the component unmounts or artists change again
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [artists]);

  const messages = {
    pending: {
      icon: <Search className="h-5 w-5 text-blue-400" />,
      title: "Searching for Artists",
      content: (
        <>
          <p>Searching third party databases for artists. This may take some time. Add more artists in the meantime.</p>
        </>
      )
    },
    notFound: {
      icon: <AlertCircle className="h-5 w-5 text-yellow-400" />,
      title: "Some Artists Not Found",
      content: (
        <>
          <p>We&apos;ve been unable to find some artists. If these are valid artists, leave them in your coral, and proceed. Our team will add them and update your coral to ensure future processed payments are distributed correctly.</p>
        </>
      )
    }
  };

  return (
    <div className={`transition-all duration-300 ease-in-out ${showMessage ? 'opacity-100 max-h-96' : 'opacity-0 max-h-0 overflow-hidden'}`}>
      {messageType && (
        <div className="rounded-md bg-blue-50 p-4 my-4">
          <div className="flex">
            <div className="flex-shrink-0">
              {messages[messageType].icon}
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-blue-800">{messages[messageType].title}</h3>
              <div className="mt-2 text-sm text-blue-700">
                {messages[messageType].content}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ArtistSearchStatus;