import React, { useState, useEffect, lazy, Suspense } from 'react';
import { useHistory } from 'react-router-dom';
import { getCurrentUser } from '@aws-amplify/auth';
import { User } from 'shared/types/platformTypes';
import { updateUser, getUserFromAPI } from '../../api/api';
import CoralButton from '../../components/CoralButtons/CoralButton';
import Spinner from '../../components/Spinner/Spinner';
import { getReferralUrl, clearReferralUrl } from '../../utils/referralUtils';
import { AuthContext, useAuthContext } from '../../components/Auth/AuthContext/AuthContext';
import { trackEvent, EventName } from 'shared/lib/eventTracking';

// Lazy load the emoji-flags data
const LazyCountrySelector = lazy(() => import('../../components/LazyCountrySelector/LazyCountrySelector'));

const ProfileSetup: React.FC = () => {
    const [firstName, setFirstName] = useState('');
    const [country, setCountry] = useState<{ value: string; label: string; flag: string; } | null>(null);
    const [currentUser, setCurrentUser] = useState<User | null>(null);
    const [isArtist, setIsArtist] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [referralUrl, setReferralUrl] = useState<string | null>(null);
    const [verifiedArtist, setVerifiedArtist] = useState<boolean>(false);
    const history = useHistory();
    const { fetchUserData } = React.useContext(AuthContext);
    const { userProfile } = useAuthContext();

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const user = await getCurrentUser();
                const fetchedUserProfile = await getUserFromAPI(user.userId) as User;
                setCurrentUser(fetchedUserProfile);
                if (fetchedUserProfile.settings?.profileSetupComplete) {
                    history.push('/dashboard');
                }
                if (fetchedUserProfile.verifiedArtist) {
                    setVerifiedArtist(true);
                    setIsArtist(true);
                }
                const storedReferralUrl = getReferralUrl();
                if (storedReferralUrl) {
                    setReferralUrl(storedReferralUrl);
                }
            } catch (error) {
                console.error('Error fetching user:', error);
            }
        };

        fetchUser();
    }, [history]);

    React.useEffect(() => {
        trackEvent(EventName.USER_ACCOUNT_CREATED, {
            userId: userProfile!.userId,
        });
    }, []);

    if (currentUser === null) {
        return (
            <div className="flex justify-center items-center h-screen">
                <Spinner />
            </div>
        );
    }

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
    
        if (!country) {
            console.error('Country not set');
            return;
        }
    
        try {
            const updatedUser: Partial<User> = {
                userId: currentUser.userId,
                firstName: firstName,
                country: country.label,
                countryAtSignUp: country.label,
                settings: {
                    ...currentUser.settings,
                    profileSetupComplete: true,
                },
            };
    
            if (referralUrl) {
                updatedUser.referralUrl = referralUrl;
            }
    
            await updateUser(updatedUser);
            if (referralUrl) {
                clearReferralUrl();
            }
            window.scrollTo(0, 0);

            await fetchUserData();
            
            trackEvent(EventName.USER_PROFILE_SETUP, {
                userId: userProfile!.userId,
                country: country.label,
                isArtist: isArtist || false,
            });

            if (isArtist && !verifiedArtist) {
                history.push('/artist-connections');
            } else {
                history.push('/welcome');
            }
        } catch (error) {
            console.error('Error updating user', error);
        }
    };

    const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFirstName(event.target.value);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleCountryChange = (selectedOption: any) => {
        if (selectedOption === null) {
            setCountry(null);
        } else {
            setCountry(selectedOption);
        }
    };


    return (
        <div className="flex flex-col justify-center items-center h-screen px-4">
            <div className="w-full max-w-md bg-white p-8">
                <h2 className="font-nunito text-xl md:text-2xl font-extrabold mb-4">
                    Hello.
                </h2>
                <p className="text-base mb-6 font-nunito font-semibold">
                    Before we get started, we&apos;d like to get to know you a little better.
                </p>
                <form onSubmit={handleSubmit} className="space-y-6">
                    <div>
                        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 mb-2">First Name</label>
                        <input 
                            id="first-name" 
                            name="first-name" 
                            type="text" 
                            required 
                            className="w-full p-2 text-base border border-gray-300 rounded-md shadow-sm"
                            placeholder=""
                            value={firstName}
                            onChange={handleFirstNameChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="country" className="block text-sm font-medium text-gray-700 mb-2">Where do you live?</label>
                        <Suspense fallback={<Spinner />}>
                            <LazyCountrySelector
                                id="country"
                                name="country"
                                value={country}
                                onChange={handleCountryChange}
                                placeholder="Select a country..."
                                required
                                className="w-full"
                            />
                        </Suspense>
                    </div>
                    {!verifiedArtist && (
                        <div>
                            <div className="flex items-center justify-between">
                                <span className="text-base font-nunito font-semibold">Do you want to register as an artist?</span>
                                <input
                                    id="artist-checkbox"
                                    type="checkbox"
                                    className="form-checkbox h-5 w-5 text-coral-600 ml-2"
                                    checked={isArtist}
                                    onChange={() => setIsArtist(!isArtist)}
                                />
                            </div>
                        </div>
                    )}
                    {isArtist && !verifiedArtist && (
                        <div className="mt-2">
                            <p className="text-sm text-gray-500">
                                To be eligible as an artist on wearecoral, you must be a professional published musical artist, either an individual or group.
                            </p>
                            <p className="text-sm text-gray-500 mt-2">
                                If you are an amateur musician, content creator, generative AI music company, or create non-music audio content such as sleep sounds or sound packs wearecoral is not for you. We encourage you to explore more general creator platforms like Patreon.
                            </p>
                        </div>
                    )}
                    <div>
                        <div className="flex items-center justify-between">
                            <span className="text-base font-nunito font-semibold">
                                I have read, understood, and accept the 
                                <a 
                                    href="https://www.wearecoral.org/terms" 
                                    target="_blank" 
                                    rel="noopener noreferrer" 
                                    className="text-blue-500 hover:underline"
                                >
                                    {" "}Terms and Conditions
                                </a>.
                            </span>
                            <input
                                id="terms-checkbox"
                                type="checkbox"
                                className="form-checkbox h-5 w-5 text-coral-600 ml-2"
                                checked={termsAccepted}
                                onChange={() => setTermsAccepted(!termsAccepted)}
                            />
                        </div>
                    </div>
                    <div>
                        <CoralButton
                            className="font-semibold text-xs sm:text-sm w-full"
                            onClick={handleSubmit}
                            disabled={!termsAccepted}
                        >
                            Complete Setup
                        </CoralButton>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ProfileSetup;