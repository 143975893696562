import React from 'react';
import { IoTrendingUp } from 'react-icons/io5';

export interface ImpactData {
  artistName: string;
  totalAmount: number;
  currentMonthAmount: number;
  priorMonthAmount: number;
  currentMonthCoralCount: number;
  priorMonthCoralCount: number;
}

interface ImpactCardProps {
  impact: ImpactData;
  id?: string;
}

const ImpactCard: React.FC<ImpactCardProps> = ({ id, impact }) => {
  const monthlyChange = impact.currentMonthAmount;
  const isPositiveChange = monthlyChange >= 0;

  return (
    <div id={id}
      className="w-full max-w-sm bg-white rounded-lg shadow-md overflow-hidden transition-all duration-300 ease-in-out hover:shadow-lg cursor-pointer border border-gray-200 hover:border-coral-purple"
    >
      <div className="p-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="font-bold text-lg text-gray-800 truncate flex items-center max-w-[70%]">
            {impact.artistName}
          </h2>
          <span className={`text-xs font-semibold px-2 py-1 rounded-full ${isPositiveChange ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
            {isPositiveChange ? '+' : '-'}${Math.abs(monthlyChange).toLocaleString()}
          </span>
        </div>
        <div className="flex items-baseline justify-between">
          <div>
            <span className="text-2xl font-semibold text-coral-purple">${impact.totalAmount.toLocaleString()}</span>
            <span className="text-gray-500 text-sm ml-1">total</span>
          </div>
          <div className="text-right">
            <span className="text-lg font-semibold text-gray-800">{impact.currentMonthCoralCount}</span>
            <span className="text-gray-500 text-sm ml-1">corals</span>
          </div>
        </div>
        <div className="mt-4 flex items-center text-sm text-gray-600">
          <IoTrendingUp className={`mr-1 ${isPositiveChange ? 'text-green-500' : 'text-red-500'}`} />
          {isPositiveChange ? 'Up' : 'Down'} from {impact.priorMonthCoralCount} corals last month
        </div>
      </div>
    </div>
  );
};

export default ImpactCard;