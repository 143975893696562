import { useState, useEffect, useCallback, useRef } from 'react';
import { getCategoriesAndGenresForCoralBrowser, getCoralsForTag, getRecentSharedCorals } from '../api/api';
import { Tag } from 'shared/types/platformTypes';

interface CategoryGenre {
  categorization: Tag;
  genres: Tag[];
}

interface UseCuratedCoralsResult {
  tags: Tag[];
  corals: { coralName: string; coralGuid: string; artists: string[] }[];
  selectedTags: Tag[];
  setSelectedTags: React.Dispatch<React.SetStateAction<Tag[]>>;
  fetchCorals: (tag: Tag) => Promise<void>;
  navigateToCategory: (categoryTag: Tag) => Promise<void>;
  navigateToTop: () => Promise<void>;
  activeCategory: Tag | null;
  isLoading: boolean;
}

export const useCuratedCorals = (initialState: 'recent' | 'tag' = 'tag'): UseCuratedCoralsResult => {
  const [categoriesGenres, setCategoriesGenres] = useState<CategoryGenre[]>([]);
  const [tags, setTags] = useState<Tag[]>([]);
  const [corals, setCorals] = useState<{ coralName: string; coralGuid: string; artists: string[] }[]>([]);
  const [recentCorals, setRecentCorals] = useState<{ coralName: string; coralGuid: string; artists: string[] }[]>([]);
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
  const [activeCategory, setActiveCategory] = useState<Tag | null>(null);
  const [coralsCache, setCoralsCache] = useState<Record<string, { coralName: string; coralGuid: string; artists: string[] }[]>>({});
  const [isLoading, setIsLoading] = useState(false);
  const dataFetchedRef = useRef(false);

  useEffect(() => {
    const fetchInitialData = async () => {
      if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;

      setIsLoading(true);
      try {
        const fetchedRecentCorals = await getRecentSharedCorals();
        setRecentCorals(fetchedRecentCorals);
        
        if (initialState === 'recent') {
          setCorals(fetchedRecentCorals);
        } else {
          const data = await getCategoriesAndGenresForCoralBrowser();
          setCategoriesGenres(data);
          setTags(data.map((item) => item.categorization));
          
          // Set initial corals to recent corals if empty
          if (corals.length === 0) {
            setCorals(fetchedRecentCorals);
          }
        }
      } catch (error) {
        console.error('Error fetching initial data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInitialData();
  }, [initialState, corals.length]);

  const fetchCorals = useCallback(async (tag: Tag) => {
    setIsLoading(true);
    try {
      const tagKey = `${tag.tagType}:${tag.tagText}`;
      if (coralsCache[tagKey]) {
        setCorals(coralsCache[tagKey]);
      } else {
        const data = await getCoralsForTag(tag);
        setCorals(data);
        setCoralsCache((prevCache) => ({ ...prevCache, [tagKey]: data }));
      }
    } catch (error) {
      console.error('Error fetching corals:', error);
    } finally {
      setIsLoading(false);
    }
  }, [coralsCache]);

  const navigateToCategory = useCallback(async (categoryTag: Tag) => {
    setIsLoading(true);
    try {
      const selectedCategory = categoriesGenres.find((item) => 
        item.categorization.tagText === categoryTag.tagText && 
        item.categorization.tagType === categoryTag.tagType
      );
      if (selectedCategory) {
        setTags(selectedCategory.genres);
        setActiveCategory(categoryTag);
        await fetchCorals(categoryTag);
      }
    } catch (error) {
      console.error('Error navigating to category:', error);
    } finally {
      setIsLoading(false);
    }
  }, [categoriesGenres, fetchCorals]);

  const navigateToTop = useCallback(async () => {
    setIsLoading(true);
    try {
      setTags(categoriesGenres.map((item) => item.categorization));
      setActiveCategory(null);
      setCorals(recentCorals);  // Use recent corals when navigating to top
    } catch (error) {
      console.error('Error navigating to top:', error);
    } finally {
      setIsLoading(false);
    }
  }, [categoriesGenres, recentCorals]);

  return {
    tags,
    corals,
    selectedTags,
    setSelectedTags,
    fetchCorals,
    navigateToCategory,
    navigateToTop,
    activeCategory,
    isLoading,
  };
};