import React, { useState, ReactNode, FC } from 'react';
import SecondaryCoralButton from '../CoralButtons/SecondaryCoralButton';
import CoralButton from '../CoralButtons/CoralButton';

interface Tab {
  key: string;
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.ComponentType<any>;
  props: Record<string, unknown>;
}

interface TabbedLayoutProps {
  breadcrumbs: ReactNode[];
  tabs: Tab[];
  onSave: () => void;
  onCancel: () => void;
}

const TabbedLayout: FC<TabbedLayoutProps> = ({ breadcrumbs, tabs, onSave, onCancel }) => {
  const [activeTab, setActiveTab] = useState<string>(tabs[0].key);

  return (
    <div className="flex flex-col h-full">
      {/* Top section including breadcrumbs */ }
      <div className="bg-white p-6 rounded-lg shadow-md flex-grow overflow-auto">
        {/* Breadcrumbs */ }
        <div className="mb-6 font-nunito font-semibold">
          { breadcrumbs.map((crumb, index) => (
            <React.Fragment key={ index }>
              { crumb }
              { index < breadcrumbs.length - 1 && (
                <span className="mx-2 text-gray-400">&gt;</span>
              ) }
            </React.Fragment>
          )) }
        </div>
        <hr className="my-4 border-gray-300"/>

        {/* Tabs */ }
        <div className="border-b border-gray-200">
          <div className="flex justify-start -mb-px">
            { tabs.map((tab) => (
              <div key={ tab.key } className="mr-8">
                <button
                  onClick={ () => setActiveTab(tab.key) }
                  className={ `pb-4 px-1 text-sm font-semibold ${
                    activeTab === tab.key
                      ? 'border-b-2 border-coral-pink text-coral-black'
                      : 'border-b-2 border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                  }` }
                >
                  { tab.name }
                </button>
              </div>
            )) }
          </div>
        </div>

        {/* Tab Content */ }
        <div className="py-4 overflow-auto">
          { tabs.map((tab) => {
            const isActive = activeTab === tab.key;
            return (
              <div key={ tab.key }>
                { isActive && (
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  /* @ts-ignore */
                  <tab.component { ...tab.props } />
                ) }
              </div>
            );
          }) }
        </div>
      </div>

      {/* Sticky Bottom Save and Cancel buttons */ }
      <div className="sticky bottom-0 px-6 py-4 bg-white border-t">
        <div className="flex justify-end space-x-4">
          <SecondaryCoralButton onClick={ onCancel }>
            Cancel
          </SecondaryCoralButton>
          <CoralButton onClick={ onSave }>
            Save
          </CoralButton>
        </div>
      </div>
    </div>
  );
};

export default TabbedLayout;
