import React, { useState } from 'react';
import MiniArtistTable from '../../MiniArtistTable/MiniArtistTable';
import { ClientArtistListItem, ArtistSearchResult } from 'shared/types/platformTypes';
import SecondaryCoralButton from '../../../components/CoralButtons/SecondaryCoralButton';
import { Tooltip } from 'react-tooltip';
import ArtistSearch from '../../ArtistSearch/ArtistSearch';

export interface AddArtistProps {
  artists: Array<ClientArtistListItem>;
  addArtistDirectly: (artistName: string, artistGuid?: string) => void;
  removeArtist: (artistId: string) => void;
  addArtistFromDynamicArtistPool: (artist: ClientArtistListItem) => void; 
  updateFilter: (filterType: 'genre' | 'location', value: string, action: 'add' | 'remove') => void;
  activeFilters: Array<{ filterType: 'genre' | 'location'; value: string }>;
  uniqueGenres: { value: string; count: number; }[];
  uniqueLocations: { value: string; count: number; }[];
  pageTitle?: string;
  openCuratedCoralModal: () => void;
  isDynamicArtistPools: boolean;
}

const SelectArtist: React.FC<AddArtistProps> = ({
  artists,
  addArtistDirectly,
  addArtistFromDynamicArtistPool,
  removeArtist,
  pageTitle = 'Select Your Artists',
  openCuratedCoralModal,
}) => {
  const [isAddingArtist, setIsAddingArtist] = useState(false);

  const handleAddArtist = async (artistName: string, artistGuid?: string) => {
    if (artistName.trim() && !isAddingArtist) {
      setIsAddingArtist(true);
      try {
        await addArtistDirectly(artistName.trim(), artistGuid);
      } catch (error) {
        console.error('Error adding artist:', error);
        // Optionally, you could show an error message to the user here
      } finally {
        setIsAddingArtist(false);
      }
    }
  };

  const handleSelectArtist = (artist: ArtistSearchResult) => {
    handleAddArtist(artist.name, artist.guid);
  };

  return (
    <div className="flex flex-col items-center px-4 py-4">
      <div className="w-full max-w-4xl">
        <h1 className="font-nunito text-2xl md:text-3xl font-extrabold mb-2">{pageTitle}</h1>
        <p className="text-md mb-4">
          Add artists to your coral. Search for artists or add from suggestions.
        </p>
        <p className="text-md">
          Add <em>any</em> artist you appreciate. <span className="text-coral-pink font-medium sm:font-normal" data-tooltip-id='any artists' data-tooltip-html="Nominate artists, even if they're not on our platform.<br />We'll notify them of waiting funds.<br /><br />This community-driven approach reflects recognition of <br />the value we've already received from their music, and<br />is how we can step forward to value them.<br /><br />Artists have 9 months from the first notification to<br />sign up before funds are redistributed to other<br />artists you nominate, or refunded.">Learn how it works.</span>
        </p>
        <Tooltip id="any artists" className="z-50" />

        <div className="text-sm my-2 mt-8">Search for an artist or add them manually:</div>
        <div className="mb-4">
          <ArtistSearch
            onSelect={handleSelectArtist}
            onSubmit={handleAddArtist}
            placeholder="Enter artist name"
          />
        </div>

        <MiniArtistTable 
          artists={artists}
          onRemoveArtist={removeArtist}
          addArtistFromDynamicArtistPool={addArtistFromDynamicArtistPool}
          viewType='suggested'
          emptyTableMessage='Add connections to generate artist suggestions'
        />
      </div>
      <div className="mt-8 text-center">
        <SecondaryCoralButton onClick={openCuratedCoralModal}>
          NEED MORE SUGGESTIONS? BROWSE CURATED CORALS
        </SecondaryCoralButton>
      </div>
    </div>
  );
};

export default SelectArtist;