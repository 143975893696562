import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import SelectArtist from '../../components/CreateCoralFlow/SelectArtists/SelectArtists';
import ConfigureConnections from '../../components/CreateCoralFlow/ConfigureConnections/ConfigureConnections';
import CoralSettings from '../../components/CoralEditor/CoralSettings/CoralSettings';
import CoralTransactions from '../../components/CoralEditor/CoralTransactions/CoralTransactions';
import useCoralEditor from '../../hooks/useCoralEditor';
import TabbedLayout from '../../components/TabbedLayout/TabbedLayout';
import Spinner from '../../components/Spinner/Spinner';
import { CoralSubscriptionStatus } from 'shared/types/platformTypes';

const EditCoral: React.FC = () => {
    const { id } = useParams<{ id?: string }>();
    const history = useHistory();

    const { 
        coralData, 
        updatedPledgeAmount,
        addArtistDirectly, 
        removeArtist, 
        addArtistFromDynamicArtistPool, 
        addPinnedArtist, 
        removePinnedArtist, 
        removeArtistsBySourceArtistPoolGuid, 
        uniqueGenres, 
        uniqueLocations, 
        dynamicArtistPools, 
        activeFilters, 
        updatePledgeAmount, 
        updateCoralName, 
        saveCoral, 
        simulateCoral, 
        pauseCoralSubscription, 
        cancelCoralSubscription,
        simulationResults, 
        updateFilter 
    } = useCoralEditor(id || '');

    const handleSaveCoral = async () => {
        const newGuid = await saveCoral();
        if (newGuid){
            if(coralData?.subscriptionStatus === CoralSubscriptionStatus.ACTIVE && updatedPledgeAmount){
                history.push(`/payment/${newGuid}?amt=${updatedPledgeAmount}&sid=${coralData.subscriptionId}`);
            }
            else{
                history.push(`/coral/${newGuid}`);
            }
        }

        console.log('Coral saved');
    };

    const handleView = () => {
        history.push(`/coral/${id}`);
    };    

    if (!coralData || !coralData.artistPool || !coralData.artistPool.artistList) {
        return (
            <div className="flex items-center justify-center h-screen">
                <Spinner />
            </div>
        );
    }

    const breadcrumbs = [
        <button key="view" className="text-gray-400 text-sm" onClick={handleView}>View Coral</button>,
        <span className='text-sm' key="edit">Edit</span>
    ];

    const sharedProps = {
        artists: coralData.artistPool.artistList.map(({ artistId, artistName, artistListItemStatus, artistData, source, sourceType, sourceArtistPoolGuid, selected, payoutStatus, totalUserPayout, lastMonthPlatformPayout, artistListItemActive }) => ({
            artistId: artistId || '',
            artistName,
            artistListItemStatus,
            artistData,
            source,
            sourceArtistPoolGuid,
            sourceType,
            selected,
            payoutStatus,
            totalUserPayout,
            lastMonthPlatformPayout,
            artistListItemActive
        })),
        simulationResults: simulationResults,
        pledgeAmount: coralData.pledgeAmount,
        activeFilters: activeFilters,
        uniqueGenres: uniqueGenres,
        uniqueLocations: uniqueLocations,
        dynamicArtistPools: dynamicArtistPools,
        pinnedArtists: coralData.pinnedArtists,
        coralName: coralData.coralName,
    };    

    const defaultStepProps = {
        ...sharedProps,
        addArtistDirectly,
        removeArtist,
        removeArtistsBySourceArtistPoolGuid: () => {},
        updateFilter,
        simulateCoral,
        updatePledgeAmount,
        addArtistFromDynamicArtistPool,
        retrieveTopArtists: () => {},
        addPinnedArtist: () => {},
        removePinnedArtist: () => {},
        updateCoralName: () => {},
    };

    const tabs = [
        {
            name: 'Settings',
            component: CoralSettings,
            key: 'settings',
            props: {
                ...defaultStepProps,
                simulateCoral,
                simulationResults,
                updatePledgeAmount,
                addPinnedArtist,
                removePinnedArtist,
                updateCoralName,
                pauseCoralSubscription,
                cancelCoralSubscription,
                coralSubscriptionStatus: coralData.subscriptionStatus,
                subscriptionId: coralData.subscriptionId,
                coralId: coralData.guid,
            },
        },
        { 
            name: 'Connections', 
            component: ConfigureConnections,
            key: 'connections',
            props: {
                ...defaultStepProps,
                addArtistDirectly,
                removeArtist,
                addArtistFromDynamicArtistPool,
                updateFilter,
                removeArtistsBySourceArtistPoolGuid,
                pageTitle: '',
            },
        },
        { 
            name: 'Artists', 
            component: SelectArtist,
            key: 'artists',
            props: {
                ...defaultStepProps,
                addArtistDirectly,
                removeArtist,
                addArtistFromDynamicArtistPool,
                updateFilter,
                pageTitle: '',
            },
        },
        {
            name: 'Transactions',
            component: CoralTransactions,
            key: 'transactions',
            props: {
                coralId: coralData.guid,
            },
        },
    ];

    return (
        <>
            <TabbedLayout
                breadcrumbs={breadcrumbs}
                tabs={tabs}
                onSave={handleSaveCoral}
                onCancel={handleView}
            />
        </>
    );
};

export default EditCoral;