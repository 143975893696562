import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import coralReefWithFishies from '../../assets/images/coral-with-fishies.svg';
import JoinCoralButton from '../../components/CoralButtons/JoinCoralButton';
import PublicCoralArtistTable from '../../components/PublicCoralArtistTable/PublicCoralArtistTable';
import useCoralEditor from '../../hooks/useCoralEditor';
import Spinner from '../../components/Spinner/Spinner';
import { storeReferralUrl } from '../../utils/referralUtils';
import { trackEvent, EventName } from 'shared/lib/eventTracking';

const PublicSharedCoral: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  const location = useLocation();

  useEffect(() => {
    if (id) {
      const referralUrl = `${window.location.origin}${location.pathname}`;
      storeReferralUrl(referralUrl);
    }
  }, [id, location.pathname]);

  const {
    coralData,
    addPinnedArtist,
    removePinnedArtist,
    updatePledgeAmount,
    simulateCoral,
    simulationResults,
  } = useCoralEditor(id || '', true);

  useEffect(() => {
    if (!coralData) {
      return;
    }  
    trackEvent(EventName.PUBLIC_SHARED_CORAL_VIEW, { coralId: id || '', coralName: coralData.coralName});
  }, [coralData]);

  if (!coralData) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Spinner/>
      </div>
    );
  }

  return (
    <div className="fade-in-top overflow-clip">
      <div className="grid place-content-center pt-12 md:pt-20 py-6 lg:py-12 px-8 sm:px-12 lg:px-16 max-w-screen-xl mx-auto">
        <div className="mb-4">
          <h1 className="mb-4 text-4xl md:text-5xl lg:text-6xl font-black font-nunito text-center leading-tight">
            <span className="block lg:inline mr-2">Support</span>
            <span className="bg-gradient-to-r from-accent-400 via-accent-200 to-accent-800 block lg:inline text-transparent bg-clip-text mr-2">{ coralData.coralName }</span>
            <span className="block lg:inline">on wearecoral</span>
          </h1>
        </div>
        <div>
          <p className="mb-4 text-2xl md:text-3xl lg:text-4xl font-nunito font-bold text-center">
          <span className="bg-gradient-to-r from-[#1B8ECA] via-[#1EE8FF] to-[#1B8ECA] inline-block text-transparent bg-clip-text pr-2"> 
              The simplest way to back music. Patronage that&apos;s transparent, collective, and easy.
            </span>
          </p>
        </div>
      </div>

      <div className="relative">
        <div className="flex flex-col-reverse lg:flex-row px-3 sm:px-12 lg:px-16 max-w-screen-xl mx-auto gap-8 sm:mb-12 items-center relative z-10">
          <div className="w-full lg:w-1/2">
            <div className='justify-center flex'>
              <PublicCoralArtistTable
                coralName={ coralData ? coralData.coralName : '' }
                coralSimulationResults={ simulationResults.artistAllocations }
                simulateCoral={ simulateCoral }
                updatePledgeAmount={ updatePledgeAmount }
                pledgeAmount={ coralData ? coralData.pledgeAmount : 0 }
                addPinnedArtist={ addPinnedArtist }
                removePinnedArtist={ removePinnedArtist }
                pinnedArtists={ coralData ? coralData.pinnedArtists : [] }
              />
            </div>
            <div className="mt-12 sm:mt-8 mb-8 lg:hidden">
              <JoinCoralButton isAnimated={ false } className="w-fit mx-auto">
                Join & Customize Your Coral
              </JoinCoralButton>
            </div>
          </div>
          <section className="flex flex-col justify-center items-center gap-10 max-w-2xl w-full lg:w-1/2">
            <div>
              <p className="text-[#B0C4DE] text-center font-nunito text-xl lg:text-2xl mb-6">Discover a new way to back music with &lsquo;corals&rsquo;. A coral is your personal collection of artists you back with a recurring contribution. </p>
              <p className="text-[#B0C4DE] font-nunito font-bold text-center text-xl lg:text-2xl ">
              Explore how corals work, then sign up to create and share your own.
              </p>
            </div>
            <JoinCoralButton isAnimated={ false } className="w-fit mx-auto hidden lg:block">
              Join & Customize Your Coral
              </JoinCoralButton>
          </section>
        </div>

        <div className="relative w-full h-fit sm:mt-8">
          <div className="w-full grid place-items-center relative z-10">
            <div className="max-w-screen-xl mx-auto px-8 sm:px-12 lg:px-16 pb-12 sm:py-16 lg:py-20 mb-32 md:mb-72 lg:mb-96 flex flex-col items-center gap-7">
              <div className="max-w-4xl">
                <h2 className="font-nunito text-3xl md:text-4xl lg:text-5xl font-extrabold pb-6 text-center leading-tight">
                  A vibrant, healthy, and diverse ecosystem of music, artists and communities
                </h2>
                <p className="font-nunito text-xl md:text-2xl text-center text-primary-200 hidden lg:block mt-6">
                  wearecoral is a platform dedicated to enriching the music ecosystem, focusing on fostering diverse,
                  non-mainstream genres. Watch as your actions, combined with others&apos;, enables artists and sounds to thrive. 
                </p>
                <p className="font-nunito text-xl md:text-2xl text-center text-primary-200 hidden lg:block mt-6">
                  More music. More fun. More connection. Our way.
                </p>
              </div>
              <JoinCoralButton size="large" isAnimated={false} className='hidden lg:block mt-8'/>
            </div>
          </div>
        </div>

        <img
          src={coralReefWithFishies}
          alt="Fishes"
          className="w-full h-auto absolute bottom-0 left-0 right-0 z-0 opacity-75"
          aria-hidden="true"
        />
      </div>
    </div>
  );
};

export default PublicSharedCoral;