import React, { useRef, useEffect } from 'react';
import closeIcon from '../../assets/images/close.png';
import { useOutsideAlerter } from '../../hooks/useOutsideAlerter';

interface GenericCoralModalProps {
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode | string;
  className?: string; 
  size?: 'default' | 'large' | 'full';
}

const GenericCoralModal: React.FC<GenericCoralModalProps> = ({ isOpen, onClose, children, className, size = 'default' }) => {
  const ref = useRef(null);
  const sizeClasses = {
    default: 'max-w-md sm:max-w-lg md:max-w-xl lg:max-w-2xl',
    large: 'max-w-3xl sm:max-w-4xl md:max-w-5xl lg:max-w-6xl',
    full: 'max-w-full'
  };
  useOutsideAlerter(ref, onClose);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4">
      <div
        ref={ref}
        className={`relative w-full ${sizeClasses[size]} bg-white rounded-lg shadow-2xl flex flex-col max-h-[90vh] ${className}`}
      >
        <button
          onClick={onClose}
          className="absolute -top-3 -right-3 z-10 bg-white rounded-full p-1"
          aria-label="Close modal"
        >
          <img src={closeIcon} alt="Close" className="w-6 h-6 object-contain" />
        </button>
        <div className="overflow-y-auto flex-grow p-2 sm:p-4">
          {children}
        </div>
      </div>
    </div>
  );
};

export default GenericCoralModal;