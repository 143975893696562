import React, { useEffect } from 'react';
import aboutHeroImage from '../../assets/images/coral-wide-1.png';
import { trackEvent, EventName } from 'shared/lib/eventTracking';

const Contact: React.FC = () => {
    useEffect(() => {
        trackEvent(EventName.CONTACT_PAGE_VIEW, {});
    }, []);

    return (
        <div className="fade-in-top">
            <div className="max-w-screen-xl mx-auto">
                {/* Title text "Let's talk" */}
                <div className="p-16 bg-white w-auto md:w-2/3 text-left">
                    <h1 className="font-nunito text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-coral-black font-extrabold">
                        Let&apos;s talk.
                    </h1>
                </div>
            </div>
            
            {/* Hero image section */}
            {/* (Assuming you want a hero image similar to the About page) */}
            <div className="2xl:hidden">
                <img src={aboutHeroImage} alt="Contact Hero" className="aspect-content aspect-center w-full h-auto object-cover" />
            </div>

            <div className="2xl:h-[500px] relative">
                <img src={aboutHeroImage} alt="Contact Hero" className="absolute inset-0 w-full h-full object-cover"/>
            </div>

            {/* Contact section */}
            <div className="p-16">
                <div className="max-w-screen-xl mx-auto flex flex-col md:flex-row">
                    {/* Left column for the heading */}
                    <div className="md:w-1/2 mb-6 md:mb-0 md:pr-8">
                        <h2 className="text-sm md:text-base font-extrabold">
                            <div className="inline bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
                                CONNECT
                            </div>
                        </h2>
                        <h2 className="font-nunito text-2xl md:text-3xl text-coral-black font-extrabold">
                            Let&apos;s build this together.
                        </h2>
                    </div>
                    
                    {/* Right column for the text */}
                    <div className="md:w-1/2">
                        <p className="text-sm sm:text-base md:text-lg text-coral-black">
                            wearecoral is a different type of organisation. Transparent and committed to involving our community in dialogue and decision-making.<br /><br />
                            Media enquiries and partnerships are welcome.<br /><br />

                            You can reach out to us via <a href="mailto:hello@wearecoral.org" className="text-coral-pink underline">hello@wearecoral.org</a>.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;
