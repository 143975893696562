import React, { useState, useEffect, Suspense, lazy } from 'react';
import GenericCoralModal from '../GenericCoralModal/GenericCoralModal';
import CuratedCoralModal from './Pages/CuratedCoralModal';

const LazyBrowser = lazy(() => import('./LazyCuratedCoralBrowser'));

type CuratedCoralParentProps = {
  isModal?: boolean;
  isOpen?: boolean;
  onClose?: () => void;
  handleAddToCoral?: (coralId: string) => void;
  activeArtistPoolGuids: string[];
  removeArtistsBySourceArtistPoolGuid?: (sourceArtistPoolGuid: string) => void;
  selectedCuratedCoralId?: string;
  standalone?: boolean;
};

const CuratedCoralParent: React.FC<CuratedCoralParentProps> = ({
  isModal = false,
  isOpen = false,
  onClose,
  handleAddToCoral,
  activeArtistPoolGuids,
  removeArtistsBySourceArtistPoolGuid,
  selectedCuratedCoralId,
  standalone = false
}) => {
  const [page, setPage] = useState<'curated' | 'curated-individual'>('curated');
  const [coralGuid, setCoralGuid] = useState<string | null>(selectedCuratedCoralId || null);

  useEffect(() => {
    if (standalone && selectedCuratedCoralId) {
      setPage('curated-individual');
      setCoralGuid(selectedCuratedCoralId);
    } else {
      setPage('curated');
    }
  }, [standalone, selectedCuratedCoralId]);

  const pageTurner = (newPage: 'curated' | 'curated-individual', guid?: string) => {
    setPage(newPage);
    if (guid) {
      setCoralGuid(guid);
    }
  };

  const addToSuggestionsText = handleAddToCoral ? "Add To Coral Suggestions" : "Add To My Suggestions";

  const handleClose = () => {
    if (onClose) onClose();
    if (!standalone) {
      setPage('curated');
      setCoralGuid(null);
    }
  };

  const content = (
    <>
      {page === 'curated' && !standalone && (
        <Suspense fallback={<div>Loading...</div>}>
          <LazyBrowser pageTurner={pageTurner} />
        </Suspense>
      )}
      {page === 'curated-individual' && coralGuid && (
        <CuratedCoralModal
          pageTurner={standalone ? undefined : pageTurner}
          coralId={coralGuid}
          standalone={standalone}
          connectCoralMethod={handleAddToCoral}
          connectCoralButtonCopy={addToSuggestionsText}
          activeArtistPoolGuids={activeArtistPoolGuids}
          removeArtistsBySourceArtistPoolGuid={removeArtistsBySourceArtistPoolGuid}
        />
      )}
    </>
  );

  if (isModal) {
    return (
      <GenericCoralModal isOpen={isOpen} onClose={handleClose} size="large">
        {isOpen && content}
      </GenericCoralModal>
    );
  }

  return content;
};

export default CuratedCoralParent;