import { useState, useEffect, useCallback } from 'react';
import { getCurrentUser, AuthUser, signOut, signIn, confirmSignIn, SignInInput } from '@aws-amplify/auth';
import { getUserFromAPI } from '../api/api';
import { User } from 'shared/types/platformTypes';

export const useAuth = () => {
  const [currentUser2, setCurrentUser] = useState<AuthUser | null>(null);
  const [userProfile, setUserProfile] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [showAuth, setShowAuth] = useState({ show: false, signIn: false });

  const fetchUserData = useCallback(async () => {
    console.log('fetchUserData started');
    try {
      setLoading(true);
      console.log('Setting loading to true');
      
      // First, check if there's a current user
      const user = await getCurrentUser().catch(() => null);
      
      if (user) {
        console.log(`Current user fetched: ${user.userId}`);
        setCurrentUser(user);
        const profile = await getUserFromAPI(user.userId) as User;
        console.log(`User profile fetched for userId: ${JSON.stringify(user, null, 2)}`);
        setUserProfile(profile);
      } else {
        console.log('No authenticated user found');
        clearAuthState();
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      clearAuthState();
    } finally {
      setLoading(false);
      console.log('Setting loading to false, fetchUserData completed');
    }
  }, []);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  const clearAuthState = useCallback(() => {
    setCurrentUser(null);
    setUserProfile(null);
    setShowAuth({ show: false, signIn: false });
  }, []);

  const logout2 = useCallback(async () => {
    try {
      await signOut();
      setCurrentUser(null);
      setUserProfile(null);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  }, []);


  const loginWithMagicLink = useCallback(async (email: string, token: string) => {
    console.log('loginWithMagicLink started');
    console.log('Received parameters:', { email, token });

    try {
      const signInInput: SignInInput = {
        username: email,
        options: {
          authFlowType: 'CUSTOM_WITHOUT_SRP'
        }
      };
      console.log('SignInInput:', signInInput);

      const signInOutput = await signIn(signInInput);
      console.log('SignIn output:', signInOutput);

      if (signInOutput.nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE') {
        console.log('Confirming sign-in with custom challenge');
        const confirmSignInOutput = await confirmSignIn({ challengeResponse: token });
        console.log('Challenge response output:', confirmSignInOutput);

        if (confirmSignInOutput.isSignedIn) {
          await fetchUserData();
          console.log('User data fetched successfully');
          return true;
        }
      }

      console.log('Sign-in step not confirmed or invalid');
      return false;
    } catch (error) {
      console.error('Error logging in with magic link:', error);
      return false;
    }
  }, [fetchUserData]);

  return {
    currentUser2,
    userProfile,
    loading,
    fetchUserData,
    logout2,
    showAuth,
    setShowAuth,
    loginWithMagicLink
  };
};