import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import Spinner from '../../components/Spinner/Spinner';
import coralReef from '../../assets/images/coral-reef.svg';
import { AuthContext } from '../../components/Auth/AuthContext/AuthContext';


const VerifyMagicLink: React.FC = () => {
  const [verifying, setVerifying] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const location = useLocation();
  const history = useHistory();
  const { loginWithMagicLink } = useAuth();
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    const verifyLink = async () => {
      const params = new URLSearchParams(location.search);
      const email = params.get('email');
      const token = params.get('token');

      if (!email || !token) {
        setError('Invalid magic link');
        setVerifying(false);
        return;
      }

      try {
        const success = await loginWithMagicLink(email, token);
        console.log('loginWithMagicLink success:', success);
        console.log('currentUser:', currentUser);
        if (success) {
          // Add a delay before redirecting to allow auth state to update
          setTimeout(() => {
            window.location.href = '/dashboard';
          }, 1000);
        } else {
          setError('Verification failed');
          setVerifying(false);
        }
      } catch (error) {
        console.error('Error during magic link verification:', error);
        setError('Verification failed');
        setVerifying(false);
      }
    };

    verifyLink();
  }, [location.search, history, loginWithMagicLink]);

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-no-repeat bg-contain bg-bottom 3xl:bg-cover" style={{ backgroundImage: `url(${coralReef})` }}>
      <div className="text-center pb-32 sm:pb-32 md:pb-48 lg:pb-96 xl:pb-96">
        {verifying ? (
          <>
            <h1 className="text-3xl font-syne font-bold mb-4 text-coral-black">Logging You In</h1>
            <p className="text-xl mb-6 text-coral-black">Hold tight, we&apos;re verifying your magic link...</p>
            <Spinner />
          </>
        ) : error ? (
          <>
            <h1 className="text-3xl font-syne font-bold mb-4 text-coral-black">Verification Failed</h1>
            <p className="text-xl text-coral-black">{error}</p>
          </>
        ) : (
          <>
            <h1 className="text-3xl font-syne font-bold mb-4 text-coral-black">Success!</h1>
            <p className="text-xl text-coral-black">You&apos;re logged in. Redirecting to dashboard...</p>
          </>
        )}
      </div>
    </div>
  );
};

export default VerifyMagicLink;