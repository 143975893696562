import React from 'react';
import { Link } from 'react-router-dom';

const AdminLandingPage: React.FC = () => {
  const adminPages = [
    { title: 'Missing Artists', path: '/admin/artist/missing', description: 'Manage and retry processing for missing artists.' },
    { title: 'Identity Verification', path: '/admin/artist/identity-verification', description: 'Review and process user identity verifications.' },
    { title: 'Artist Applications', path: '/admin/artist/applications', description: 'Manage artist applications and approvals.' },
    { title: 'Artist Payments', path: '/admin/artist/payments', description: 'View artists with pending payments and generate verification codes.' },
  ];

  return (
    <div className="container mx-auto px-4 py-8">
    <div className="mb-6">
        <a 
          href="/dashboard" 
          className="text-indigo-600 hover:text-indigo-800 font-medium"
        >
          ← Back to Dashboard
        </a>
      </div>
      <h1 className="text-3xl font-bold mb-6">Admin Dashboard</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {adminPages.map((page) => (
          <Link
            key={page.path}
            to={page.path}
            className="bg-white shadow-lg rounded-lg overflow-hidden hover:shadow-xl transition-shadow duration-300"
          >
            <div className="p-6">
              <h2 className="text-xl font-semibold mb-2">{page.title}</h2>
              <p className="text-gray-600 mb-4">{page.description}</p>
              <div className="flex justify-end">
                <span className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700">
                  Manage
                </span>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default AdminLandingPage;