import React, { useEffect, useState } from 'react';
import { BiArrowBack, BiPlus, BiMinus } from 'react-icons/bi';
import { ClientCoral } from 'shared/types/platformTypes';
import { getCoralFromAPI, connectCuratedCoral, disconnectCuratedCoral, getConnectedCuratedCorals } from '../../../api/api';
import Image0 from 'images/thumbnails/image_0.png';
import Image1 from 'images/thumbnails/image_1.png';
import Image2 from 'images/thumbnails/image_2.png';
import Image3 from 'images/thumbnails/image_3.png';
import Image4 from 'images/thumbnails/image_4.png';
import Image5 from 'images/thumbnails/image_5.png';
import Image6 from 'images/thumbnails/image_6.png';
import Image7 from 'images/thumbnails/image_7.png';
import Image8 from 'images/thumbnails/image_8.png';
import Image9 from 'images/thumbnails/image_9.png';
import Image10 from 'images/thumbnails/image_10.png';
import Image11 from 'images/thumbnails/image_11.png';

interface CuratedCoralProps {
  pageTurner?: (page: 'curated' | 'curated-individual', guid?: string) => void;
  coralId: string;
  standalone?: boolean;
  connectCoralMethod?: (coralId: string) => void;
  connectCoralButtonCopy?: string;
  activeArtistPoolGuids?: string[];
  removeArtistsBySourceArtistPoolGuid?: (sourceArtistPoolGuid: string) => void;
}

const CuratedCoralModal: React.FC<CuratedCoralProps> = ({
  pageTurner,
  coralId,
  standalone = false,
  connectCoralMethod,
  connectCoralButtonCopy = "Add To Coral Suggestions",
}) => {
  const [coral, setCoral] = useState<ClientCoral | null>(null);
  const [isCoralActive, setIsCoralActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const imageArray = [
    Image0, Image1, Image2, Image3, Image4, Image5,
    Image6, Image7, Image8, Image9, Image10, Image11,
  ];

  const getImageIndex = (name: string) => {
    let total = 0;
    for (let i = 0; i < name.length; i++) {
      total += name.charCodeAt(i);
    }
    return total % imageArray.length;
  };

  useEffect(() => {
    const fetchCoral = async () => {
      setIsLoading(true);
      try {
        const data = await getCoralFromAPI(coralId);
        setCoral(data);
        const connectedCorals = await getConnectedCuratedCorals();
        setIsCoralActive(connectedCorals.some(coral => coral.curatedCoralId === coralId));
      } catch (error) {
        console.error('Failed to fetch coral data:', error);
        setError('Failed to load coral data. Please try again.');
      }
      setIsLoading(false);
    };

    fetchCoral();
  }, [coralId]);

  const handleButtonClick = async () => {
    setIsUpdating(true);
    setError(null);
    const newActiveState = !isCoralActive;
    setIsCoralActive(newActiveState); // Optimistic update

    try {
      if (newActiveState) {
        await (connectCoralMethod || connectCuratedCoral)(coralId);
      } else {
        await disconnectCuratedCoral(coralId);
      }
    } catch (error) {
      console.error('Failed to update coral status:', error);
      setIsCoralActive(!newActiveState); // Revert optimistic update
      setError('Failed to update. Please try again.');
    }

    setIsUpdating(false);
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="w-12 h-12 border-4 border-blue-500 border-solid rounded-full animate-spin border-t-transparent"></div>
      </div>
    );
  }

  return (
    <div className="bg-white p-4 sm:p-6">
      {!standalone && pageTurner && (
        <button
          onClick={() => pageTurner('curated')}
          className="mb-4 text-gray-600 hover:text-gray-800 transition-colors"
        >
          <BiArrowBack className="w-6 h-6" />
        </button>
      )}
      <div className="flex flex-col gap-4">
        <div className="flex flex-col sm:flex-row gap-4 sm:gap-6">
          <div className="w-full sm:w-1/2 lg:w-2/5">
            <div className="relative pb-[56.25%] rounded-lg overflow-hidden shadow-sm">
              <img
                src={imageArray[getImageIndex(coral?.coralName || '')]}
                alt={`${coral?.coralName} thumbnail`}
                className="absolute inset-0 w-full h-full object-cover"
              />
            </div>
          </div>
          
          <div className="w-full sm:w-1/2 lg:w-3/5 flex flex-col">
            <h2 className="text-xl sm:text-2xl font-bold text-gray-800 mb-2">{coral?.coralName}</h2>
            <p className="text-sm text-gray-600 mb-4">
              {isCoralActive
                ? "These artists are now in your suggestions. They'll be visible whenever you create or edit a coral."
                : "Add these artists to your suggestions to simplify coral creation. They'll be visible whenever you create or edit a coral."}
            </p>
          </div>
        </div>

        <button
          onClick={handleButtonClick}
          disabled={isUpdating}
          className={`w-full p-4 rounded-lg shadow-sm transition-all duration-300 border ${
            isCoralActive
              ? 'bg-gray-100 hover:bg-gray-200 text-gray-800 border-gray-300'
              : 'bg-blue-100 hover:bg-blue-200 text-blue-800 border-blue-300'
          }`}
        >
          <div className="flex items-center justify-center">
            {isUpdating ? (
              <div className="w-5 h-5 border-2 border-current border-solid rounded-full animate-spin border-t-transparent mr-2"></div>
            ) : (
              isCoralActive ? <BiMinus className="w-5 h-5 mr-2" /> : <BiPlus className="w-5 h-5 mr-2" />
            )}
            <span className="text-lg font-semibold">
              {isCoralActive ? "Remove from Suggestions" : connectCoralButtonCopy}
            </span>
          </div>
        </button>
        
        {error && <p className="mt-2 text-red-600 text-sm">{error}</p>}
      </div>
      
      <div className="mt-6">
        <h3 className="text-lg font-semibold text-gray-800 mb-4">Artists</h3>
        <div className="max-h-64 overflow-y-auto pr-4">
          {coral?.artistPool.artistList.map((artist) => (
            <div
              key={artist.artistId}
              className="py-3 border-b border-gray-200 last:border-b-0"
            >
              <p className="text-gray-700">{artist.artistName}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CuratedCoralModal;