import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect, useLocation, useHistory } from 'react-router-dom';
import App from '../App';
import Home from '../pages/Home/Home';
import About from '../pages/About/About';
import Contact from '../pages/Contact/Contact';
import Privacy from '../pages/Privacy/Privacy';
import Terms from '../pages/Terms/Terms';
import ArtistTerms from '../pages/Terms/ArtistTerms';
import Principles from '../pages/Principles/Principles';
import Trust from '../pages/Trust/Trust';
import Dashboard from '../pages/Dashboard/Dashboard';
import OurImpact from '../pages/OurImpact/OurImpact';
import Settings from '../pages/Settings/Settings';
import Welcome from '../pages/Welcome/Welcome';
import ProfileSetup from '../pages/ProfileSetup/ProfileSetup';
import ArtistDetailedVerification from '../pages/ArtistSetup/ArtistDetailedVerification';
import UserIdentityVerificationForm from '../pages/ArtistSetup/UserIdentityVerificationForm';
import Coral from '../pages/Coral/Coral';
import Thankyou from '../pages/PublicArtist/Thankyou';
import EditCoral from '../pages/Coral/EditCoral';
import PublicSharedCoral from '../pages/PublicSharedCoral/PublicSharedCoral';
import PublicArtist from '../pages/PublicArtist/PublicArtist';
import CreateCoralFunnel from '../pages/CreateCoralFunnel/CreateCoralFunnel';
import ForArtists from '../pages/ForArtists/ForArtists';
import Payment from '../pages/Payment/Payment';
import SignOut from '../pages/SignOut/SignOut';
import RouteChangeTracker from '../components/RouteChangeTracker/RouteChangeTracker';
import ArtistConnections from '../pages/ArtistSetup/ArtistConnections';
import MissingArtistsAdmin from '../pages/Admin/Artist/MissingArtists/MissingArtists';
import { AuthContext, AuthProvider } from '../components/Auth/AuthContext/AuthContext';
import UserIdentityVerificationAdmin from '../pages/Admin/Artist/Verification/IdentifyVerification';
import ArtistApplicationAdmin from '../pages/Admin/Artist/Verification/ArtistApplicationAdmin';
import AdminLandingPage from '../pages/Admin/AdminLandingPage';
import VerifyMagicLink from '../pages/VerifyMagicLink/VerifyMagicLink';
import RequestLogin from '../components/RequestLogin/RequestLogin';
import ArtistPayments from '../pages/Admin/Artist/ArtistPayments/ArtistPayments';
import ArtistPayoutCollection from '../pages/PublicArtist/ArtistPayoutCollection';
import NotFound from '../pages/NotFound/NotFound';

// ReactGA.initialize('G-2RBVGM4D7Y');

interface ProtectedRouteProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.ComponentType<any>;
  path: string;
  exact?: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component: Component, ...rest }) => {
  const { currentUser, userProfile } = useContext(AuthContext);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (!currentUser && !isPublicRoute(location.pathname)) {
      history.push('/');
    } else if (currentUser && !isPublicRoute(location.pathname) && !userProfile?.settings?.profileSetupComplete && location.pathname !== '/setup') {
      console.log('Redirecting to setup', userProfile?.settings?.profileSetupComplete, location.pathname)
      history.push('/setup');
    }
  }, [currentUser, userProfile, location.pathname]);

  // Log the value of currentUser
useEffect(() => {
  console.log('Current User:', currentUser);
}, [currentUser]);

// Log the value of userProfile
useEffect(() => {
  console.log('User Profile:', userProfile);
}, [userProfile]);

// Log the value of location.pathname
useEffect(() => {
  console.log('Location Pathname:', location.pathname);
}, [location.pathname]);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!currentUser) {
          return <Redirect to="/" />;
        }
        if (!userProfile?.settings?.profileSetupComplete && rest.path !== '/setup') {
          return <Redirect to="/setup" />;
        }
        return <Component {...props} />;
      }}
    />
  );
};

const Routes = () => {
  return (
    <AuthProvider>
      <Router>
        <RouteChangeTracker />
        <App>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/about" component={About} />
            <Route path="/contact-us" component={Contact} />
            <Route path="/for-artists" component={ForArtists} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/terms" component={Terms} />
            <Route path="/artist-terms" component={ArtistTerms} />
            <Route path="/principles" component={Principles} />
            <Route path="/trust" component={Trust} />
            <Route path="/welcome" component={Welcome} />
            <Route path="/sign-out" component={SignOut} />
            <Route path="/c/:id" component={PublicSharedCoral} />
            <Route path="/payment/:id" component={Payment} />
            <Route path="/payment/:id" component={Payment} />
            <Route path="/thankyou" component={Thankyou} />
            <Route path="/auth/verify" component={VerifyMagicLink} />
            <Route path="/requestlogin" component={RequestLogin} />
            <Route path="/a/:artistUrl/collect" component={ArtistPayoutCollection} />            
            <Route path="/a/:id" component={PublicArtist} />
            
            <ProtectedRoute path="/dashboard" component={Dashboard} />
            <ProtectedRoute path="/impact" component={OurImpact} />
            <ProtectedRoute path="/setup" component={ProfileSetup} />
            <ProtectedRoute path="/artist-applications" component={ArtistDetailedVerification} />
            <ProtectedRoute path="/artist-connections" component={ArtistConnections} />
            <ProtectedRoute path="/verify-identity" component={UserIdentityVerificationForm} />
            <ProtectedRoute path="/settings" component={Settings} />
            <ProtectedRoute exact path="/coral" component={Coral} />
            <ProtectedRoute path="/create-coral" component={CreateCoralFunnel} />
            <ProtectedRoute path="/coral/:id/edit" component={EditCoral} />
            <ProtectedRoute path="/coral/:id" component={Coral} />
            <ProtectedRoute path="/admin/artist/missing" component={MissingArtistsAdmin} />
            <ProtectedRoute path="/admin/artist/identity-verification" component={UserIdentityVerificationAdmin} />
            <ProtectedRoute path="/admin/artist/applications" component={ArtistApplicationAdmin} />
            <ProtectedRoute path="/admin/home" component={AdminLandingPage} />
            <ProtectedRoute path="/admin/artist/payments" component={ArtistPayments} />
            
            <Route path="*" component={NotFound} />
          </Switch>
        </App>
      </Router>
    </AuthProvider>
  );
};

export default Routes;

function isPublicRoute(route: string): boolean {
  const exactPublicRoutes = ['/', '/about', '/contact-us', '/privacy', '/terms', '/for-artists', '/welcome', '/sign-out', '/trust', '/principles'];
  if (exactPublicRoutes.includes(route)) {
    return true;
  }
  if (route.startsWith('/c/') || route.startsWith('/a/') || route.startsWith('/verify/')) {
      return true;
  }
  return false;

}