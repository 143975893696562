import React, { useEffect } from 'react';
import aboutHeroImage from '../../assets/images/coral-wide-1.png';
import lightBlueWaveImage from '../../assets/images/light-blue-wave.png';
import JoinCoralButton from '../../components/CoralButtons/JoinCoralButton';
import { trackEvent, EventName } from 'shared/lib/eventTracking';

const ArtistTerms: React.FC = () => {
    useEffect(() => {
        trackEvent(EventName.ARTIST_TERMS_PAGE_VIEW, {});
    }, []);
    
    return (
        <div className="fade-in-top">
            <div className="max-w-screen-xl mx-auto">
                <div className="p-16 bg-white w-auto md:w-2/3 text-left">
                    <h1 className="font-nunito text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-coral-black font-extrabold">
                        Artist Terms and Conditions.
                    </h1>
                </div>
            </div>
            
            <div className="2xl:hidden">
                <img src={aboutHeroImage} alt="Artist Terms Hero" className="aspect-content aspect-center w-full h-auto object-cover" />
            </div>

            <div className="2xl:h-[500px] relative">
                <img src={aboutHeroImage} alt="Artist Terms Hero" className="absolute inset-0 w-full h-full object-cover"/>
            </div>

            <div className="p-16">
                <div className="max-w-screen-xl mx-auto flex flex-col md:flex-row">
                    <div className="md:w-1/2 mb-6 md:mb-0 md:pr-8">
                        <h2 className="text-sm md:text-base font-extrabold">
                            <div className="inline bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange uppercase">
                                Artist Agreement
                            </div>
                        </h2>
                        <h2 className="font-nunito text-2xl md:text-3xl text-coral-black font-extrabold">
                            Key Points
                        </h2>
                    </div>
                    
                    <div className="md:w-1/2">
                        <p className="text-xs sm:text-sm md:text-base text-coral-black">
                        At wearecoral, our mission is to support a thriving music ecosystem. Our platform makes it easy for the community to cooperate at scale to support and multiply the music they love through direct financial backing.
                        </p>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                            Here are some key points about our platform that you should know:
                        </p>
                        <ul className="list-disc pl-5 text-xs sm:text-sm md:text-base text-coral-black mt-2 space-y-2">
                            <li>
                                <strong>Trust-Based Cooperation:</strong> wearecoral enables your community to support you directly, allowing you to focus on creating music rather than working outside your craft to make ends meet.
                            </li>
                            <li>
                                <strong>Voluntary Contributions:</strong> Members make contributions freely, without expectation of specific goods or services in return. Contributions are not royalty payments.
                            </li>
                            <li>
                                <strong>No Strings Attached:</strong> Although the contributions are general support for your ongoing creative work, receiving funds through wearecoral doesn&apos;t create any obligations for you as an artist.
                            </li>
                            <li>
                                <strong>Regular Payments:</strong> We currently process all transactions in Australian dollars and distribute funds to you on a monthly basis.
                            </li>
                            <li>
                                <strong>Time-Limited Collection:</strong> Members have the option to back artists who haven&apos;t yet joined the platform. In such cases, wearecoral will notify the artists that there are funds awaiting them. These artists are given a 9-month window to register, verify their identity, and claim their funds. Should this period elapse without action, the unclaimed funds will either be redistributed to active artists on the platform or returned to the contributing members.
                            </li>
                            <li>
                                <strong>Reinvesting in Music:</strong> As a social enterprise, we pledge to return 50% of our platform fee profits back to the music industry through grants and charitable contributions.
                            </li>
                            <li>
                                <strong>Data Protection:</strong> We prioritize your privacy and handle your personal information with the utmost care and responsibility.
                            </li>
                        </ul>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                            Please read the full terms and conditions below to understand the details of our agreement. If you have any questions, don&apos;t hesitate to reach out to us at hello@wearecoral.org.
                        </p>
                    </div>
                </div>
            </div>

            <div className="p-16">
                <div className="max-w-screen-xl mx-auto flex flex-col md:flex-row">
                    <div className="md:w-1/2 mb-6 md:mb-0 md:pr-8">
                        <h2 className="text-sm md:text-base font-extrabold">
                            <div className="inline bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
                                TERMS & CONDITIONS
                            </div>
                        </h2>
                        <h2 className="font-nunito text-2xl md:text-3xl text-coral-black font-extrabold">
                            The Details
                        </h2>
                    </div>
                    
                    <div className="md:w-1/2">
                        <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6">
                            1. Contribution to nominated artists
                        </h3>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                            a. wearecoral Pty Ltd and the artist will both act with good faith in all of their dealings with each other.<br />
                            b. All contributions to artists must be made by wearecoral.<br />
                            c. To receive contributions from wearecoral the artist must complete the account form provided by wearecoral Pty Ltd and provide all mandatory information on the account form to allow wearecoral Pty Ltd to verify the artist and transfer funds to the artist&apos;s nominated account. The artist expressly confirms the accuracy of all information provided on the account form and holds wearecoral Pty Ltd harmless for any errors or inability to transfer funds due to errors made by the artist on the account form.<br />
                            d. wearecoral Pty Ltd may at its absolute discretion refuse to provide an artist with an account on wearecoral and in doing so has no obligation to provide the artist with the reasons for wearecoral Pty Ltd&apos;s exercise of discretion.<br />
                            e.	If the artist is a corporate entity:<br />
                            I.	the corporate officer or officers executing this agreement are responsible to ensure that contributions received via wearecoral are allocated in accordance with the corporate constitution and any related shareholder agreement.<br />
                            II.	wearecoral Pty Ltd will not be liable and will be indemnified by the corporate entity for any claim to contribution by former shareholders of the corporate entity where contributions are paid by users and the shareholding of the corporate entity changes prior to wearecoral transferring the contribution to the corporate entity or in any other circumstance where there is a claim by a former shareholder of the artist against wearecoral Pty Ltd.<br />
                            III. wearecoral Pty Ltd will not transfer any contributions to a related or alternate entity under instruction if the corporate entity ceases to be registered. Where a corporate entity ceases to be registered any contributions that are held by wearecoral Pty Ltd but have not as yet been transferred will be treated as though the terms of clause 1(j) apply and the artist has not elected to receive contributions via wearecoral.<br />
                            f. The artist will provide wearecoral Pty Ltd with updated information at any time the details on the account form as they relate to the artist change. The artist expressly holds wearecoral Pty Ltd harmless for any failure of the artist to provide timely updates to wearecoral Pty Ltd when details on the account form as they relate to the artist change.<br />
                            g. All contributions received on wearecoral and transferred to artists will be processed in Australian dollars.<br />
                            h. All contributions are subject to wearecoral Pty Ltd&apos;s discretion to accept the contribution and wearecoral Pty Ltd may refuse to accept the contribution from a user at its absolute discretion if the contribution:<br />
                            I. is likely to result in a higher cost of acceptance and transfer than the value of the contribution.<br />
                            II. there is reason to believe the contribution may breach the warranties and agreements the user accepts in the user terms and conditions.<br />
                            III. there is reason to believe the contribution may breach the warranties and agreements the artist accepts in these terms and conditions.<br />
                            IV. in the assessment of wearecoral Pty Ltd is not in the interests or objectives wearecoral Pty Ltd are seeking by enacting the wearecoral.<br />
                            i. Subject to this clause 1 and clause 2 of these terms and conditions wearecoral Pty Ltd will use its best endeavours to provide the contribution to the artists nominated by the user in the proportion elected by the user.<br />
                            j. To receive a contribution from wearecoral the artist must elect in writing to receive contributions via wearecoral and in doing so must accept these terms and conditions. Wearecoral Pty Ltd will use its best endeavours to locate and contact artists nominated by the users of wearecoral to allow the artists the choice to either elect to receive contributions from wearecoral or elect not to receive contributions from wearecoral. If the artist does not elect to receive contributions via wearecoral:<br />
                            I. wearecoral Pty Ltd will use its best endeavours to refund the contribution to the user and failing this will allocate the payment to the other artists the user has nominated that do have an agreement with wearecoral, such allocation in the same proportion as nominated by the user or otherwise wearecoral Pty Ltd will allocate the payment to artists most approximating those nominated by the user as determined by wearecoral Pty Ltd in its absolute discretion.<br />
                            II. The determination by wearecoral Pty Ltd in accordance with this clause 1(h) is final and not subject to change or requested amendment by the user.<br />
                            III. wearecoral Pty Ltd has no obligation to advise an artist of the quantum of funds the artist has failed to receive by choosing not to elect to receive contributions via wearecoral.<br />
                            k.	The artist may direct wearecoral Pty Ltd to transfer contributions to a nominated agent of the artist or a related entity of the artist.<br />
                            l.	When completing the account form and at any other relevant time wearecoral Pty Ltd may use all methods considered reasonable by wearecoral Pty Ltd to verify the identity of the artist whether the artist is a natural personal or a corporate legal entity. To undertake the verification wearecoral Pty Ltd may be required to seek further or better details from the artist. The provision of further details will be at the discretion of the artist however the artist acknowledges that if wearecoral Pty Ltd cannot verify the identity of the artist wearecoral Pty Ltd will be unable to accept the artist on the wearecoral platform.<br />
                            m.	wearecoral Pty Ltd may at any time seek from the artist:<br />
                            I.	updated information in the nature of the information on the account form.<br />
                            II.	updated information to verify the identity of the artist.<br />
                            n. If wearecoral Pty Ltd is unable to locate or contact artists nominated by the users of wearecoral despite Wearecoral Pty Ltd best endeavours, or is unable to verify the identification of the artist to the satisfaction of wearecoral Pty Ltd:<br />
                            I. wearecoral Pty Ltd is not liable to the artist for any funds the artist fails to receive.<br />
                            II. The terms of clause 1(h) apply as though the artist had elected to not receive contributions from wearecoral.<br />
                            o.	To the maximum extent permitted at law the artist indemnifies wearecoral Pty Ltd and holds wearecoral Pty Ltd harmless for:<br />
                            I.	any error made by wearecoral Pty Ltd in the processing of a transfer that results in the transfer being made to an incorrect account including where the account is not an account owned by the artist.<br />
                            II.	any error made by wearecoral Pty Ltd in identifying the artist resulting in contributions intended to be received by the artist being received by another person or legal entity.<br />
                            p. For the avoidance of doubt, the artist has no claim in law or equity against wearecoral Pty Ltd:<br />
                            I. if a user nominates the artist on the wearecoral and for any reason the artist does not ultimately receive the funds the user intends the artist to receive.<br />
                            II. if the artist does not appear on wearecoral or for any other reason a user is unable to contribute funds to the artist on the wearecoral platform despite the user intending to do so.<br />
                            III. if the artist provides incorrect account information resulting in the contribution intended for the artist to not be received by the artist.<br />
                            IV.	If wearecoral Pty Ltd exercises its discretion pursuant to clauses 1(d) or 14(b) of these terms.<br />
                            V.	If wearecoral Pty Ltd makes any error in processing a contribution to an artist or makes an error in verifying the identity of the artist.
                        </p>

                        <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6">
                            2. Costs and fees of wearecoral
                        </h3>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                            a. The artist acknowledges that wearecoral Pty Ltd incurs costs to operate wearecoral, facilitate payments to artists and undertake the general governance and administration functions required of the wearecoral Pty Ltd corporate entity.<br />
                            b. wearecoral Pty Ltd charges a platform fee for all contributions to the wearecoral platform. This platform fee is disclosed to the user on the wearecoral platform prior to the user confirming a contribution.<br />
                            c. The platform fee charged by wearecoral Pty Ltd is dependent upon to the actual costs incurred by wearecoral Pty Ltd and wearecoral Pty Ltd reserves the right to change the platform fee at any time without prior notice.<br />
                            d.	The artist expressly disclaims any right to the Platform Fee paid by users to wearecoral Pty Ltd associated with contributions by users to the artist on the wearecoral platform. <br />
                            e.	The artist agrees that all costs associated with transferring funds from wearecoral Pty Ltd to the artist will be paid from the gross funds wearecoral Pty Ltd are transferring to the artist such that the artist will receive net funds in the quantum of the gross funds less the transfer costs. Transfer costs include but are not limited to bank related fees and charges.
                        </p>

                        <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6">
                            3. Delays
                        </h3>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                            a. wearecoral Pty Ltd will use its best endeavours to make all payments to artists within 9 months of receipt from the user.<br />
                            b. The artist acknowledges and agrees that despite wearecoral Pty Ltd&apos;s best endeavours some payments may be made to artists greater than 9 months after receipt of the funds from the user and in such circumstances the delay does not provide the artist with any right to make any claim in law or equity against wearecoral Pty Ltd or its officers.<br />
                            c. The artist agrees that the artist is not entitled to any interest that may accrue or could accrue if the funds were held in an interest bearing or investment account for the period of time from when the user contributed funds intended for the artist until the funds are paid to the artist.<br />
                            d. The artist agrees that due to the elapsed time from when the user contributed funds intended for the artist until the funds are paid to the artist the funds may be provided to the artist in a different tax period to when the user contributed the funds. The artist further agrees that if such a delay results in a higher tax burden for the artist the artist has no claim against wearecoral Pty Ltd for the increased tax burden.
                        </p>

                        <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6">
                            4. Relationship of the parties
                        </h3>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                            a. In these Terms and Conditions the Parties refer to the artist and wearecoral Pty Ltd collectively and Party refers to one of the Parties.<br />
                            b. The Parties acknowledge that their relationship is such that wearecoral Pty Ltd are a service provider facilitating contributions from users to artists nominated by the users. Transactions facilitated by wearecoral do not establish any other relationship and, in particular, not the relationship of principal and agent, partnership or joint venture, or employer and employee.
                        </p>

                        <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6">
                            5. Wearecoral relationship with artists
                        </h3>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                            a. wearecoral Pty Ltd provides users with the wearecoral platform to make contributions to nominated artists. Contributions to artists on the wearecoral platform are expressly voluntary contributions and are not due to or the result of goods or services provided by the artist to the user or to wearecoral Pty Ltd.<br />
                            b. The artist agrees that wearecoral and wearecoral Pty Ltd has no obligation to the artist to promote the artist or to seek contributions from users on behalf of the artist. All contributions the artist receives from wearecoral is due to the sole decision of the users.<br />
                            c. The artist agrees that the artist has voluntarily agreed to participate in the wearecoral platform and has not chosen to participate in the wearecoral platform due to any promise or representation from wearecoral Pty Ltd. For clarity, the artist agrees that participation in the wearecoral platform does not guarantee the receipt of contributions from users of the wearecoral platform and the artist may receive no contributions from users of the wearecoral platform despite the artists participation.<br />
                            d. Unless documented in writing between the artist and wearecoral Pty Ltd, the artist expressly agrees that wearecoral Pty Ltd has not made any payment to the artist nor promised any payment to the artist for their participation on the wearecoral platform.<br />
                            e. Any failure to disclose any relationship wearecoral Pty Ltd or a related entity of wearecoral Pty Ltd has with an artist receiving contributions on wearecoral does not provide the user with any right to cancel the contribution, require a refund or make any claim in law or equity against wearecoral Pty Ltd or its officers unless such failure is the result of deliberate action by wearecoral Pty Ltd or its officers or is due to gross negligence by wearecoral Pty Ltd or its officers.
                        </p>

                        <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6">
                            6. Statutory obligations and keeping of accounts
                        </h3>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                            a. wearecoral Pty Ltd will comply with their statutory obligations in respect of the operation of wearecoral and the administration of contributions to artists.<br />
                            b. wearecoral Pty Ltd will comply with their obligations to maintain accounting records such that accounts of all contributions receipted and all payments to artists can be provided as required for legal or accounting purposes. In maintaining accurate accounting records wearecoral Pty Ltd will comply with all requirements for accounts pursuant to the Corporation Act 2001 (Cth).
                        </p>

                        <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6">
                            7. Receipts for contributions
                        </h3>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                            a. All contributions by users to artists are voluntary contributions and take the form of a donation to the nominated artists.<br />
                            b. wearecoral Pty Ltd will provide a transaction receipt to the artist, in a form determined by wearecoral Pty Ltd, for all contributions received from the artist via wearecoral. wearecoral Pty Ltd will not accept any requests for alteration to the transaction receipt provided by wearecoral Pty Ltd.<br />
                            c. The form of the transaction receipt is subject to change at the sole discretion of wearecoral Pty Ltd.
                        </p>

                        <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6">
                            8. Refunds and cancellations
                        </h3>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                            a. Receipts that are accepted by artists via their participation in the wearecoral platform and acceptance of these terms and conditions are final and binding.<br />
                            b. Any request to refund or cancellation by the artist after the contribution has been transferred to the artist by wearecoral may be considered and determined by wearecoral Pty Ltd at the sole discretion of wearecoral Pty Ltd.
                        </p>

                        <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6">
                            9. GST and Tax
                        </h3>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                            a. For the purposes of these terms and conditions GST means goods and services tax payable in accordance with the A New Tax System (Goods and Services Tax) Act 1999 (Cth) and all incidental and ancillary legislation and regulations.<br />
                            b. wearecoral Pty Ltd considers that a transfer of funds to an artist is not made due to a taxable supply by the artist for the purposes of GST and therefore GST is not applicable to transfer.<br />
                            c. wearecoral Pty Ltd discloses that it is not a deductible gift recipient and any contribution made to an artist via wearecoral is not subject to claim for tax deduction by the user.<br />
                            d. it is the obligation of the artist to obtain their own advice regarding the taxable status of any payment the artist receives from wearecoral. The artist acknowledges that it has made its own enquiries regarding the application of GST specifically and tax generally to the funds the artist receives from wearecoral and relies upon its own enquires or otherwise has chosen not to make enquiry and relies upon the artist&apos;s own knowledge.
                        </p>

                        <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6">
                            10. Confidential information and privacy
                        </h3>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                            a. For the purposes of these terms and conditions Confidential Information means all information that a reasonable person would consider confidential whether provided in oral, written or electronic form from one Party to the other Party or otherwise learned by a Party due to the transfer of contribution funds via wearecoral to an artist.<br />
                            b. Subject to subclause c. of this clause 10, a Party shall not at any time or for any reason divulge any Confidential Information to any third party and each Party hereby indemnifies the other Party and any related entity against any loss or damage they may suffer as a result of breach of the Confidential Information obligations in these terms and conditions.<br />
                            c. A Party may disclose Confidential Information:<br />
                            I. as may be required by law; or<br />
                            II. to their legal or accounting advisors.<br />
                            but only to the extent that is necessary for compliance with legal obligations or to obtain proper legal or accounting advice.
                        </p>

                        <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6">
                            11. Intellectual property
                        </h3>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                            a. Intellectual Property means all intellectual property rights conferred by law or equity in relation to patents, copyright, trademark, designs, logos, formulas, inventions and any other component of wearecoral Pty Ltd or the operations of wearecoral that are unique to wearecoral or wearecoral Pty Ltd. Intellectual property includes all marketing collateral owned by wearecoral Pty Ltd.<br />
                            b. There is no assignment of the Intellectual Property rights to the artist by virtue of the artist receiving one or more contributions via the wearecoral platform or as a result of any other interaction between the artist and wearecoral Pty Ltd.<br />
                            c. The artist does not obtain the right to download, copy, reproduce or use the Intellectual Property of wearecoral Pty Ltd and any use of wearecoral Pty Ltd&apos;s Intellectual Property without the express written consent of wearecoral Pty Ltd will be a breach of wearecoral Pty Ltd&apos;s Intellectual Property rights and may result in wearecoral Pty Ltd initiating a legal claim to enforce its rights and seek damages for any loss suffered.<br />
                            d. The artist agrees that the use of their name on the wearecoral platform is not a breach of the artists Intellectual Property and that the artists name is known and used in the general public and is not confidential or proprietary in nature.<br />
                            e. The artist provides wearecoral Pty Ltd with a global fee free licence to use the artist&apos;s name and image in the promotion of the wearecoral platform. Wearecoral Pty Ltd cannot assign this licence and must only use the licence for general promotional purposes. wearecoral Pty Ltd cannot represent any relationship with the artist other than the relationship established pursuant to these terms and conditions.
                        </p>

                        <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6">
                            12. Indemnity
                        </h3>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                            a. To the maximum extent permitted at law the artist unconditionally and irrevocably indemnifies wearecoral Pty Ltd against any and all demands, claims, actions, suits, costs and expenses that are incurred by wearecoral Pty Ltd in relation to wearecoral providing a contribution from a user to the artist or as otherwise determined by these terms and conditions.<br />
                            b. To the maximum extent permitted at law the artist unconditionally and irrevocably indemnifies wearecoral Pty Ltd against any and all demands, claims, actions, suits, costs and expenses that are sought by the artist from wearecoral Pty Ltd in relation to wearecoral accepting a contribution to the artist or due to wearecoral Pty Ltd providing a contribution to the artist or otherwise determined by these terms and conditions.<br />
                            c. These Terms and Conditions as accepted by the artist acknowledge the reasonableness of the indemnities in this clause 12 and the artist agrees that wearecoral Pty Ltd may plead these indemnities as a bar to any claim for damages by the artist.<br />
                            d. The artist&apos;s obligation to indemnify wearecoral Pty Ltd is a primary obligation and wearecoral Pty Ltd is not obliged to proceed against any other person before making a demand for indemnification.<br />
                            e. The information on wearecoral is general in nature and while wearecoral Pty Ltd has made reasonable endeavours to ensure the information is accurate wearecoral Pty Ltd does not warrant the accuracy, adequacy, reliability or completeness of the information and:<br />
                            I. wearecoral Pty Ltd expressly disclaims liability for errors or omissions in such information.<br />
                            II. the artist should rely upon their own enquiries to verify any information on wearecoral; and<br />
                            III. to the maximum extent permitted by law wearecoral Pty Ltd will not be liable for any loss or damage suffered by the artist due to the information of wearecoral or reliance on the information on wearecoral.<br />
                            f.	If a person or entity receives contributions from the wearecoral platform by falsely representing their identity or their associations or by any other deception such person or entity indemnifies wearecoral Pty Ltd against any and all costs and expenses that are incurred by wearecoral Pty Ltd as a result of any demands, claims, actions or suits instigated by the artist to seek from wearecoral Pty Ltd the contributions that were intended for the artist.
                        </p>

                        <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6">
                            13. Artist warranties and agreements
                        </h3>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                            a) The artist warrants:<br />
                            I. If the artist is a natural person the artist is over the age of 18 and has capacity and is not in vulnerable circumstances or otherwise the legal guardian of the artist has approved the artists participation on the wearecoral platform.<br />
                            II. If the user is a corporate entity, the officer agreeing to participate on the wearecoral platform and accept these terms and conditions has the authority to do so.<br />
                            III. The artist is who they represent themselves to be and they are not obtaining funds contributed by users of the wearecoral platform by deception including but not limited to misrepresenting who the artist is or misrepresenting their relationship to the artist.<br />
                            IV. If the funds are being transferred to an agent or manager of the artist that the agent or manager has the express authority of the artist to represent the artist on the wearecoral platform and receive funds from the wearecoral platform on the artist&apos;s behalf.<br />
                            V. The artist has no knowledge or reason to believe that the contributions the artist receives from wearecoral is illegally or fraudulently obtained and in receiving the contributions the artist does not have the intent to undertake an illegal or fraudulent transaction and will not be undertaking an illegal or fraudulent transaction.<br />
                            VI. The artist is receiving the contributions as a genuine donation and the funds are not being provided:<br />
                            (i) in return for services rendered to the user or a related party of the user by the artist.<br />
                            (ii) to illegally circumvent tax laws of the Commonwealth of Australia, any state of the Commonwealth of Australia or any other sovereign nation or state.<br />
                            (iii) to illegally circumvent any other laws or regulations of the Commonwealth of Australia, any state of the Commonwealth of Australia or any other sovereign nation or state.<br />
                            (iv) from a source or in manner that may expose wearecoral Pty Ltd to prosecution or civil legal action.<br />
                            VII. The artist is not related to the user that has nominated the artist to receive a contribution and the user has not previously at any time engaged the professional services of the artist.<br />
                            b) It has had the opportunity to read these terms and conditions and accepts them.<br />
                            c) For the purposes of the provision of the wearecoral platform and the services provided by wearecoral Pty Ltd to facilitate contributions the artist agrees that the artist is not a Consumer as defined under Australian Consumer Law and the provisions of the Australian Consumer Law do not apply to the rights and obligations between the Parties.<br />
                            d) The artist agrees wearecoral Pty Ltd has the express authority to advise the relevant authorities of any information or knowledge wearecoral Pty Ltd obtains through the payment of contributions to the artist that indicates a criminal offence has or may occurred or a that fraud, illegal circumvention of tax laws or illegal circumvention of any other laws or regulations has or may occur.
                        </p>

                        <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6">
                            14. Suspension and cessation of services
                        </h3>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                            a. wearecoral Pty Ltd may suspend or indefinitely cease the operation of wearecoral without notice and at wearecoral Pty Ltd&apos;s absolute discretion.<br />
                            b. wearecoral Pty Ltd may suspend or indefinitely ban an artist from receiving contributions from users via wearecoral without notice and at wearecoral Pty Ltd&apos;s absolute discretion.<br />
                            c. If an artist is suspended or indefinitely banned from receiving contributions from users via wearecoral the artist has no claim for lost revenue or damages in any form from wearecoral Pty Ltd and thereafter should make their own arrangements should they so wish to receive equivalent contributions from members of the general public or users of the wearecoral platform.
                        </p>

                        <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6">
                            15. General Terms
                        </h3>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                            a. These terms and conditions are not to be construed against wearecoral Pty Ltd on the basis that wearecoral Pty Ltd&apos;s legal representatives were responsible for drafting the terms and conditions.<br />
                            b. These terms and conditions constitute the entire terms for the receipt of contributions by an artist on wearecoral.<br />
                            c. If any part of these terms and conditions is, or becomes void or unenforceable, that part is or will be severed from these terms so that all parts of the terms and conditions that are not or do not become void or unenforceable remain in full force and effect and are unaffected by that severance.<br />
                            d. The artist agrees that at the request of wearecoral Pty Ltd they will do everything reasonably necessary to give effect to these terms and conditions.<br />
                            e. These terms shall be governed by and construed in accordance with the law of the State of New South Wales and the Parties agree to submit to the jurisdiction of the Courts of that state.<br />
                            f. These terms and conditions can be changed or modified by wearecoral Pty Ltd at any time and the artist agrees to be bound by these terms and conditions as changed or modified. Notice of any change or modification will be provided by publishing the revised terms and conditions on this website and separate notification will not be given to artists individually.
                        </p>
                    </div>
                </div>
            </div>

            <div className="relative w-full h-full">
                <img src={lightBlueWaveImage} alt="Light Blue Wave" className="w-full h-full object-cover" />
                <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gradient-to-t from-coral-light-blue to-transparent"></div>
            </div>

            <div className="p-16 pt-0 bg-coral-light-blue">
                <div className="max-w-screen-xl mx-auto flex flex-col items-center">
                    <div className="w-full mb-4">
                        <h2 className="font-nunito text-2xl md:text-3xl font-extrabold pb-6 text-center">
                            A vibrant, healthy, and diverse ecosystem of music, artists and communities.
                        </h2>
                    </div>
                    <JoinCoralButton />
                </div>
            </div>
        </div>
    );
};

export default ArtistTerms;